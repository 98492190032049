<template>
  <Master>
    <div class="configuration-details" v-if="configuration && isLoaded">
      <div class="container">
        <div class="flex flex-col items-baseline mb-4">
          <div
            v-for="(item, componentIdx) in configuration.displayComponents"
            :key="Date.now() + item.partNumber + componentIdx"
            class="w-full mb-6"
          >
            <PageHeading
              :id="`mpc-config-part-${componentIdx + 1}`"
              :title="item.partNumber"
            >
              <a
                :href="getPartNumberLink(item)"
                class="
                  icon-swg-info
                  inline-block
                  text-brandLightBlue
                  hover:text-blue-700
                  relative
                  left-1
                  text-xl
                "
                target="_blank"
                :id="`mpc-view-cut-sheet-${componentIdx + 1}`"
              />
            </PageHeading>
            <p>{{ item.description }}</p>
            <hr class="-mx-2 mt-3" />
            <div
              v-for="(attribute, idx) in item.attributes"
              :key="Date.now() + idx"
            >
              <div class="flex justify-between items-center py-2">
                <div class="w-[50%]">
                  <div class="flex">
                    <div class="text-headerColor truncate font-bold mr-1">
                      {{ attribute[0] }}
                    </div>
                  </div>
                </div>
                <div
                  :id="
                    attribute[0] === 'Shape'
                      ? `mpc-config-shape-${componentIdx + 1}`
                      : null
                  "
                  class="text-headerColor w-[50%] text-right"
                >
                  {{
                    attribute[0] === 'Quantity Available'
                      ? '' + attribute[1].toLocaleString('default')
                      : attribute[1]
                  }}
                </div>
              </div>
              <hr class="-mx-2" />
            </div>
            <div class="mt-6">
              <div
                class="link-a font-semibold"
                @click="onSearchMoreSscClick(componentIdx)"
                v-html="spcSearchMore"
                id="mpc-search-more-sscs"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'ConfigurationDetails',
  mixins: [PageMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  computed: {
    ...mapState('configurator', ['configuratorData']),
    ...mapGetters('user', ['isWsCorp']),
    ...mapGetters('inventory', ['homeSscDummy']),
  },
  methods: {
    ...mapActions('inventory', ['setSearchQueryParams']),
    ...mapActions('settings', ['getSSCSettings']),

    onSearchMoreSscClick(componentIdx) {
      this.setSearchQueryParams({
        partNumber:
          this.configuration.displayComponents[componentIdx].partNumber,
        minQty: 1,
        includeBelowMin: false,
        operator: 'Equals',
      })
      this.setPath({
        name: 'Inventory',
        params: { onPageMounted: (page) => page.doSearchClick() },
        routeMap: { goBackState: { path: this.getCurrentPath() } },
      })
    },
    getPartNumberLink(item) {
      return `https://products.swagelok.com/p/${item.partNumber}`
    },
    removePriceList() {
      let listPriceIndex = ''
      this.configuration.displayComponents.forEach((el) => {
        const attrs = el.attributes
        attrs.forEach((element, idx) => {
          if (element[0] === 'List Price') {
            listPriceIndex = idx
          }
        })
        if (listPriceIndex !== '') {
          attrs.splice(listPriceIndex, 1)
        }
      })
    },
    handleListPrice() {
      this.getSSCSettings(this.homeSscDummy.sscId)
        .then((resp) => {
          const { resource } = resp

          if (resource && 'ShowHomeSSCListPrice' in resource) {
            const { ShowHomeSSCListPrice } = resource
            this.isSSCVisible = ShowHomeSSCListPrice

            const allowedGroups = this.userData.groups.some((group) =>
              ['wsSsc', 'wsSwcon', 'wsAdmin', 'admin', 'support'].includes(
                group,
              ),
            )

            if (
              !this.isSSCVisible ||
              (this.isWsCorp && !allowedGroups) ||
              !allowedGroups
            ) {
              this.removePriceList()
            }
          } else {
            this.removePriceList()
          }

          this.isLoaded = true
        })
        .catch((error) => {
          this.isLoaded = true
          console.error(error)
        })
    },
  },
  created() {
    try {
      const variant = this.$route.params.variant
      this.configuration = this.configuratorData.find(
        (el) => el.variant === variant,
      )
      if (!this.configuration)
        throw new Error('Configuration entry is not found')

      this.handleListPrice()
    } catch (e) {
      console.log(e)
      this.setPath({ name: 'Configurator' })
    }
  },
  data() {
    return {
      showHeaderMenu: false,
      configuration: null,
      spcSearchMore:
        'Search More Locations <span class="icon-swg-arrow-right"></span>',
      isSSCVisible: false,
      isLoaded: false,
    }
  },
}
</script>
