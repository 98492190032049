<template>
  <Master>
    <div class="announcements" v-if="title">
      <div class="container">
        <div class="flex justify-between items-baseline mb-4">
          <PageHeading title="Edit Announcement" />
        </div>
        <form @submit.prevent="submitEditAnnouncement">
          <input
            class="form-input-2"
            placeholder="Title"
            maxlength="100"
            name="title"
            @keyup="handleTitleChange"
            :value="title"
          />

          <editor
            :init="{
              height: 312,
              menubar: false,
              plugins: ['lists', 'link', 'paste'],
              toolbar: 'bold numlist bullist link undo redo',
              placeholder: 'Body',
              content_style: 'body {font-size: 14px;}',
            }"
            v-model="body"
            @input="handleBodyChange"
          />

          <div class="announcement-date grid gap-4 grid-cols-2 my-4">
            <div class="flex items-center justify-between">
              Start
              <input
                type="date"
                placeholder="yyyy-mm-dd"
                :value="startDate"
                @change="handleDateTimeChange($event, 'startDate')"
                class="
                  w-[124px]
                  h-[40px]
                  border border-solid border-secondaryGray
                  px-2
                "
              />
            </div>
            <div class="flex items-center justify-between">
              <div>
                <input
                  type="time"
                  :value="startTime"
                  @change="handleDateTimeChange($event, 'startTime')"
                  class="
                    w-[124px]
                    h-[40px]
                    border border-solid border-secondaryGray
                    px-2
                  "
                />
                <span class="pl-4">ET</span>
              </div>
            </div>
            <div class="flex items-center justify-between">
              End
              <input
                type="date"
                placeholder="yyyy-mm-dd"
                :value="endDate"
                @change="handleDateTimeChange($event, 'endDate')"
                class="
                  w-[124px]
                  h-[40px]
                  border border-solid border-secondaryGray
                  px-2
                "
              />
            </div>
            <div class="flex items-center justify-between">
              <div>
                <input
                  type="time"
                  :value="endTime"
                  @change="handleDateTimeChange($event, 'endTime')"
                  class="
                    w-[124px]
                    h-[40px]
                    border border-solid border-secondaryGray
                    px-2
                  "
                />
                <span class="pl-4">ET</span>
              </div>
            </div>
          </div>

          <div class="text-center text-secondaryRed" v-if="isDateInvalid">
            The start date must be before the end date
          </div>

          <div class="announcement-form-actions flex gap-2.5 mt-10">
            <div class="flex-1">
              <button
                class="button-a border-solid border-2 border-brandGreen"
                :disabled="isFormInvalid"
              >
                SAVE
              </button>
            </div>
            <div class="flex-1">
              <button class="button-b" @click="cancelEditing">CANCEL</button>
            </div>
          </div>
        </form>
        <div class="delete-announcement-area mt-8 mb-8" v-if="isAuthorized">
          <div
            v-show="modalDisplay"
            class="
              w-screen
              h-screen
              fixed
              top-0
              left-0
              z-40
              backdrop-brightness-50
              flex
              justify-center
              items-center
            "
          >
            <div class="bg-white rounded p-4 text-black flex flex-col">
              <p class="my-4">
                Are you sure you want to delete this announcement?
              </p>
              <div class="announcement-form-actions flex gap-2.5 mt-4">
                <div class="flex-1">
                  <button
                    @click="handleDeleteAnnouncement"
                    class="button-a border-solid border-2 border-brandGreen"
                  >
                    <svg
                      v-show="loading == true"
                      class="animate-spin h-5 w-5 text-white m-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="white"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <span v-show="loading == false">Confirm</span>
                  </button>
                </div>
                <div class="flex-1">
                  <button @click="modalDisplay = false" class="button-b">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            class="text-brandGreen font-bold cursor-pointer"
            @click="modalDisplay = true"
          >
            Delete Announcement >
          </button>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import AuthenticatedGuardMixin from '@/mixins/AuthenticatedGuardMixin'
import { dateTimeConfig as options } from '@/helpers/utils.js'
import { mapState, mapGetters, mapActions } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
import { DateTime } from 'luxon'

export default {
  name: 'AnnouncementsEdit',
  mixins: [PageMixin, AuthenticatedGuardMixin],
  components: {
    editor: Editor,
  },
  props: ['id'],
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('user', ['isGuest', 'isSsc', 'isAuthorized']),
  },
  methods: {
    ...mapActions('announcements', [
      'getAnnouncements',
      'getAnnouncementById',
      'deleteAnnouncement',
      'updateAnnouncement',
    ]),
    handleTitleChange(e) {
      this.title = e.target.value
    },

    handleBodyChange(editorContent) {
      this.body = editorContent
    },
    validateForm() {
      if (
        this.title.length < 8 ||
        this.body.length < 10 ||
        this.isDateInvalid === true
      ) {
        this.isFormInvalid = true
      } else {
        this.isFormInvalid = false
      }
    },
    handleDeleteAnnouncement() {
      this.loading = true

      this.deleteAnnouncement(this.id).then((response) => {
        const { status } = response

        if (status == '204') {
          this.setPath({
            name: 'Announcements',
            params: {
              action: 'delete',
            },
          })
        }
        this.loading = false
      })
    },
    handleDateTimeChange(e, params) {
      this[params] = e.target.value

      const startDate = this.handleSubmittedDate(this.startDate, this.startTime)
      const endDate = this.handleSubmittedDate(this.endDate, this.endTime)

      if (startDate > endDate) {
        this.isDateInvalid = true
        this.isFormInvalid = true
      } else {
        this.isDateInvalid = false
        this.isFormInvalid = false
      }
    },
    handleSubmittedDate(date, time) {
      const dateParts = date.split('-')
      const timeParts = time.split(':')

      return DateTime.fromObject(
        {
          year: dateParts[0],
          month: dateParts[1],
          day: dateParts[2],
          hour: timeParts[0],
          minute: timeParts[1],
        },
        { zone: 'America/New_York' },
      )
        .toUTC()
        .toISO()
    },
    submitEditAnnouncement() {
      const announcementData = {
        id: this.id,
        title: this.title,
        body: JSON.stringify(this.body),
        startDate: this.handleSubmittedDate(this.startDate, this.startTime),
        endDate: this.handleSubmittedDate(this.endDate, this.endTime),
      }

      if (announcementData.startDate >= announcementData.endDate) {
        this.isDateInvalid = true
        return
      }

      this.updateAnnouncement(announcementData).then((response) => {
        if (!response) {
          return
        }

        if ('resource' in response === false) {
          this.setPath({
            name: 'ServiceUnavailable',
            params: {
              retryAction: () => {
                this.setPath({
                  name: 'AnnouncementsEdit',
                  params: { id: this.id },
                })
              },
            },
          })
        }

        const { status, resource } = response

        if (resource.id && status === 200) {
          this.setPath({
            name: 'AnnouncementsDetails',
            params: {
              id: resource.id,
              editedAnnouncement: true,
            },
          })
        }
      })
    },
    cancelEditing() {
      this.setPath({
        name: 'AnnouncementsDetails',
        params: {
          id: this.id,
        },
      })
    },
  },
  watch: {
    title() {
      this.validateForm()
    },
    body() {
      this.validateForm()
    },
  },
  async created() {
    if (
      !this.userData.groups.some((group) =>
        ['wsAdmin', 'admin'].includes(group),
      )
    ) {
      this.setPath({
        name: 'AnnouncementsDetails',
        params: {
          id: this.id,
        },
      })
    }

    await this.getAnnouncementById(this.id).then((annoumcement) => {
      if (
        !annoumcement ||
        !this.userData.groups.some((group) =>
          ['wsAdmin', 'admin'].includes(group),
        )
      ) {
        this.setPath({
          name: 'AnnouncementsDetails',
          params: {
            id: this.id,
          },
        })
      }

      const { title, body, startDate, endDate } = annoumcement

      if (annoumcement) {
        const startDateFromISO = DateTime.fromISO(startDate)
        const endDateFromISO = DateTime.fromISO(endDate)

        const newYorkStartDate = startDateFromISO
          .setZone(options.timezone)
          .toFormat(options.date)

        const newYorkStartTime = startDateFromISO
          .setZone(options.timezone)
          .toFormat(options.time)

        const newYorkEndDate = endDateFromISO
          .setZone(options.timezone)
          .toFormat(options.date)

        const newYorkEndTime = endDateFromISO
          .setZone(options.timezone)
          .toFormat(options.time)

        this.title = title
        this.body = JSON.parse(body)
        this.startDate = newYorkStartDate
        this.startTime = newYorkStartTime
        this.endDate = newYorkEndDate
        this.endTime = newYorkEndTime
      }
    })
  },
  data() {
    return {
      title: '',
      body: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      message: 'Do you confirm deleting this announcement?',
      modalDisplay: false,
      loading: false,
      isFormInvalid: false,
      isDateInvalid: false,
      showHeaderMenu: true,
    }
  },
}
</script>
