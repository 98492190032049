<template>
  <Master>
    <div class="inventory-part-details">
      <div class="container">
        <div class="" v-if="partMeta">
          <PageHeading :title="partMeta.partNumber" />

          <div class="mt-1">{{ partMeta.partDescription }}</div>
          <div class="flex justify-between mt-2 mb-4">
            <div class="text-strategy font-bold">{{ partMeta.strategy }}</div>
            <SvgQuestionCircle
              class="
                text-brandLightBlue
                hover:text-blue-700
                w-5
                h-5
                fill-current
                cursor-pointer
              "
              @click="showHelpReserve"
            />
          </div>
        </div>

        <div
          v-if="isIVAOrderingFunctionalityEnabled"
          class="italic text-brandLightBlue text-sm mb-2"
        >
          Select a location to order product
        </div>

        <div class="italic text-brandLightBlue text-sm">
          Reserved qty indicated by a plus (+) sign
        </div>

        <hr class="-mx-2 mt-2" />
        <div class="">
          <table2
            :header="{
              cellA: 'Location',
              cellB: 'Available',
            }"
            :body="{
              cellA: {
                title: 'sscId',
                subtitle: 'sscName',
                hasIcon: 'isHome',
              },
              cellB: 'totalAvailable',
            }"
            :options="{
              showReadyNow: this.isIVAOrderingFunctionalityEnabled,
            }"
            :items="items"
            :itemHasCursor="true"
            itemKey="sscId"
            @itemClicked="(item) => showDetails(item)"
          >
            <template v-slot:cellAIcon>
              <div
                class="
                  icon-swg-home
                  text-brandYellow text-xl
                  leading-none
                  top-0.5
                  relative
                "
              />
            </template>
          </table2>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import SvgQuestionCircle from '@/assets/icons/question-circle-solid.svg'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import InventoryMixin from '@/mixins/InventoryMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import { mapActions, mapGetters } from 'vuex'
import { transformInventoryEntry } from '../transformers'

export default {
  name: 'InventoryPartDetails',
  mixins: [
    PageMixin,
    SscSelectorGuardMixin,
    InventoryMixin,
    AuthorizedGuardMixin,
  ],
  components: { SvgQuestionCircle },
  computed: {
    ...mapGetters('settings', ['isIVAOrderingFunctionalityEnabled']),
    items() {
      return this.sscData
        .filter(
          (item) =>
            item.isHome ||
            item.ivaOptOutFlag === 'N' ||
            item.ivaOptOutFlag === undefined ||
            item.ivaOptOutFlag === '',
        )
        .map((item) => {
          const readyNow = item.inventory.some((inv) => inv.readyNow)

          return {
            ...item,
            readyNow,
            totalAvailable: `${item.totalAvailable}${
              !readyNow &&
              item.sscId !== 'SWGLK' &&
              parseInt(item.totalReserved) > 0
                ? `+`
                : ''
            }`,
          }
        })
    },
  },
  methods: {
    ...mapActions('inventory', [
      'corporateInventoryForPart',
      'updateInventoryData',
    ]),
    showHelpReserve() {
      this.setPath({
        name: 'InventoryHelpReserve',
        routeMap: {
          goBackState: {
            path: this.getCurrentPath(),
          },
        },
      })
    },
    showDetails(ssc) {
      this.setPath({
        name: 'InventorySscDetails',
        params: {
          partNumber: this.partMeta.partNumber,
          sscId: ssc.sscId,
        },
        routeMap: {
          goBackState: {
            path: this.getCurrentPath(),
          },
        },
      })
    },
  },
  async created() {
    try {
      const partNumber = this.$route.params.partNumber
      this.partMeta = this.metaForPart(partNumber)
      this.sscData = this.sscForPart(partNumber)

      if (!this.sscData.find((ssc) => ssc.isHome)) {
        this.sscData.unshift(this.homeSscDummy)
      }

      if (this.sscData.findIndex((el) => el.sscId === 'SWGLK') < 0) {
        const corporateInventoryData = await this.corporateInventoryForPart(
          partNumber,
        )
        const batchId = Date.now()
        const transformedInventoryData = transformInventoryEntry(
          corporateInventoryData,
          false,
          batchId,
        )
        this.updateInventoryData(transformedInventoryData).finally(() => {
          this.sscData = this.sscForPart(partNumber)

          if (!this.sscData.find((ssc) => ssc.isHome)) {
            this.sscData.unshift(this.homeSscDummy)
          }
        })
      }
      if (!this.partMeta || !this.sscData)
        throw new Error('Inventory part entry is not found')
    } catch (e) {
      console.log(e)
      this.setPath({ name: 'Inventory' })
    }
  },
  data() {
    return {
      partMeta: null,
      sscData: null,
      showHeaderMenu: false,
    }
  },
}
</script>
