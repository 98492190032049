<template>
  <Master>
    <loader v-if="loading" />
    <div class="inventory-pooling">
      <div class="container" v-if="!loading">
        <div class="flex justify-between items-baseline mb-8">
          <PageHeading title="Request summary" />

          <router-link class="link-b font-semibold" to="/inventory-pooling"
            >Edit request
          </router-link>
        </div>

        <div class="mb-8">
          <div>
            <div
              class="mb-6"
              v-for="(item, index) in list"
              :key="`${item.id}-${index}`"
            >
              <div class="mb-2">
                <div
                  class="font-bold text-headerColor text-[20px]"
                  v-if="item.SellingSSC !== 'SWGLK'"
                >
                  {{ item.SellingSSC }}-{{ item.SellingSSC }}
                </div>
                <div
                  class="font-bold text-headerColor text-[20px]"
                  v-if="item.SellingSSC === 'SWGLK'"
                >
                  {{ item.SellingSSC }}
                </div>
              </div>

              <div class="flex gap-4 items-center mb-4">
                <div v-if="item.SellingSSC !== 'SWGLK'" class="flex-none">
                  PO Number
                </div>
                <div v-if="item.SellingSSC === 'SWGLK'" class="flex-none">
                  Notes
                </div>

                <input
                  type="text"
                  class="form-input"
                  :model="item.PONumber"
                  @change="(event) => (item.PONumber = event.target.value)"
                />
              </div>

              <hr />

              <div class="flex justify-between text-headerColor my-2">
                <div>Part number</div>
                <div>Qty</div>
              </div>

              <hr />

              <div>
                <div
                  v-for="request in item.requests"
                  :key="request.partNumber"
                  class="
                    flex
                    items-center
                    justify-between
                    text-headerColor
                    border-b border-gray-500 border-opacity-30
                    py-2
                    last:border-0
                  "
                >
                  <div>{{ request.partNumber }}</div>
                  <div class="text-[22px]">{{ request.approvedQuantity }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-12">
          <button :disabled="submitting" @click="submit" class="button-a">
            SUBMIT REQUEST(s)
          </button>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import RadioBox from '@/components/common/RadioBox'
import InventoryPoolingBuyTable from '@/components/inventory/InventoryPoolingBuyTable'
import InventoryPoolingSellTable from '@/components/inventory/InventoryPoolingSellTable'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import InventoryMixin from '@/mixins/InventoryMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'RequestSummary',
  mixins: [
    PageMixin,
    LoaderMixin,
    SscSelectorGuardMixin,
    InventoryMixin,
    AuthorizedGuardMixin,
  ],
  components: {
    RadioBox,
    InventoryPoolingSellTable,
    InventoryPoolingBuyTable,
  },
  methods: {
    ...mapActions('settings', ['sendEmail', 'openServiceNowCase']),
    ...mapActions('inventory', [
      'updateRequests',
      'removePartNumberFromRequest',
      'submitRequests',
    ]),
    ...mapActions('router', ['goPageBack']),
    updateRequestStatus({
      request,
      event: {
        target: { checked },
      },
    }) {
      request.Status = checked ? 'Order' : 'Confirmed'
    },
    async submit() {
      this.submitting = true
      const { uid: loggedUserEmail, fullName } = this.userData
      const date = new Date().toLocaleDateString('en-us', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })

      await Promise.all(
        this.list.map(async (request) => {
          if (request.SellingSSC !== 'SWGLK') {
            const html = `
            <div>
              <p>${request.RequestingSSC} PO#: ${request.PONumber}</p>

              <table>
                <thead>
                  <tr>
                    <th>Part Number</th>
                    <th>QTY</th>
                  </tr>
                </thead>
                <tbody>
                  ${request.requests
                    .map(
                      (i) => `
                      <tr>
                        <td>${i.partNumber}</td>
                        <td>${i.approvedQuantity}</td>
                      </tr>
                    `,
                    )
                    .join('')}
                </tbody>
              </table>

              <p style="margin-bottom: 0;">
                <b>Requested By:</b> ${fullName}
              </p>
              <p style="margin-bottom: 0;">
                <b>Replies to this email message will go directly to</b> ${loggedUserEmail}
              </p>
            </div>
          `

            await this.sendEmail({
              fromName: fullName,
              fromEmail: 'webmaster@swagelok.com',
              to: request.ApprovedUserEmail,
              replyTo: loggedUserEmail,
              cc: loggedUserEmail,
              subject: `Inventory Pooling Request: ${date}, ${request.RequestingSSC} to ${request.SellingSSC}`,
              html,
              text: 'test',
            })
          }

          return await this.openServiceNowCase({
            u_requested_for: fullName,
            u_type_of_report: 'Transfer/Pooling Report',
            u_collaboration_action: 'Not transferring; order items as noted',
            description: `CR Collaboration - Requested by ${fullName} \n${
              request.RequestingSSC
            } ${request.SellingSSC !== 'SWGLK' ? 'PO#: ' : ''}${
              request.PONumber
            }\nPart Number, QTY, WhoTo\n${request.requests
              .map(
                (i) =>
                  `${i.partNumber}, ${i.approvedQuantity}, ${request.RequestingSSC}-${request.RequestingSSCBranch}`,
              )
              .join('\n')}`,
            company: request.RequestingSSC,
            location: request.RequestingSSC,
            'Short Description': `CR Collaboration - Requested by ${fullName}`,
            product: 'Other',
            priority: '3 - Moderate',
          })
        }),
      )

      await this.submitRequests()
      this.submitting = false

      this.setPath({
        name: 'RequestsSubmitted',
      })
    },
  },
  computed: {
    ...mapState('inventory', ['newInventoryPoolingData']),
  },
  async created() {
    if (!this.newInventoryPoolingData?.buyingRequests?.length) {
      this.goPageBack()
    }

    this.newInventoryPoolingData?.buyingRequests.forEach((partNumber) => {
      partNumber.requests
        .filter((request) => request.Status === 'Order')
        .forEach((request) => {
          const item = {
            partNumber: request.PartNumber,
            approvedQuantity: request.ApprovedQuantity,
            PONumber: '',
          }

          const index = this.list.findIndex(
            (item) => item.SellingSSC === request.SellingSSC,
          )

          if (index === -1) {
            this.list.push({
              ...request,
              requests: [item],
            })
            return
          }

          this.list[index].requests.push(item)
        })
    })
  },
  data() {
    return {
      list: [],
      loading: false,
      selectedBranch: null,
      selectedFullNameBranch: null,
      item: null,
      partNumber: null,
      submitting: false,
    }
  },
}
</script>
