<template>
  <Master>
    <div class="configurator-help">
      <div class="container">
        <PageHeading title="Capability" />
        <p class="my-2">{{ CapName }}</p>
        <ValidationObserver
          tag="form"
          ref="observer"
          class="relative mt-4"
          @submit.prevent="submitForm"
          v-slot="{ failed }"
          id="set-submit-multiple-capabilities"
        >
          <div class="toggle-area flex items-center mt-5">
            <PageHeading title="Contact" />
          </div>
          <validation-provider
            tag="div"
            :rules="{ required: true }"
            name="receipent"
            v-slot="{ errors }"
          >
            <div class="w-full relative mt-4">
              <div class="w-full relative flex">
                <input
                  class="form-input placeholder-secondaryGrayer mr-4"
                  type="text"
                  name="email"
                  ref="email"
                  id="email"
                  v-model="formData.Contact"
                />
                <span
                  class="
                    icon-swg-circle-close
                    flex
                    items-center
                    cursor-pointer
                    text-xl text-brandBlue
                  "
                  @click.prevent="resetContact"
                ></span>
              </div>
            </div>
            <transition name="transition-fade">
              <small v-if="emailInvalid" class="text-secondaryRed">
                Please, add a valid email address
              </small>
            </transition>
          </validation-provider>
          <div class="toggle-area flex items-center mt-6 justify-between w-5/6">
            <PageHeading title="Capacity" />

            <button
              type="button"
              @click="
                () => {
                  setPath({
                    name: 'SscToSscSourcingHelp',
                    routeMap: {
                      goBackState: {
                        name: 'SscToSscSourcingAdminDetails',
                        params: {
                          idx: idx,
                          Capacity: Capacity,
                          Contact: Contact,
                          CapName: CapName,
                          FunctionCode: FunctionCode,
                          CapCode: CapCode,
                          Note: Note,
                        },
                      },
                    },
                  })
                }
              "
              class="w-[20px]"
            >
              <QuestionCircleSolid class="text-brandLightBlue" />
            </button>
          </div>
          <transition name="transition-fade">
            <div class="flex justify-between mt-4 w-5/6">
              <RadioBox
                :modelValue="formData.Capacity"
                v-model="formData.Capacity"
                label="Willing to accept inquiries"
                value="Has Capacity"
                inputName="capacity-operator"
              />
              <TrafficLight color="green" />
            </div>
          </transition>
          <transition name="transition-fade">
            <div class="flex justify-between mt-4 w-5/6">
              <RadioBox
                :modelValue="formData.Capacity"
                v-model="formData.Capacity"
                label="Unable to accept inquries at this time"
                value="Does Not Have Capacity"
                inputName="capacity-operator"
              />
              <TrafficLight color="red" />
            </div>
          </transition>
          <transition name="transition-fade">
            <div class="flex justify-between mt-4 w-5/6">
              <RadioBox
                :modelValue="formData.Capacity"
                v-model="formData.Capacity"
                label="Capability not offered"
                value="Capability not offered"
                inputName="capacity-operator"
              />
              <i
                class="
                  block
                  icon-swg-circle-close
                  text-headerColor
                  w-[16px]
                  h-[16px]
                "
              ></i>
            </div>
          </transition>
          <div class="justify-between items-center">
            <div class="toggle-area flex items-center mt-6">
              <PageHeading title="Notes" />
            </div>
            <p class="mt-6">
              Optional notes that will appear on the search detail screen.
            </p>

            <textarea
              class="form-textarea placeholder-italic h-[190px] mt-6"
              v-model="formData.Note"
              :disabled="isLoading"
              type="text"
              id="set-request-notes"
            />
          </div>
          <button
            class="button-a mt-8"
            type="submit"
            :disabled="isLoading || failed"
          >
            SUBMIT
          </button>
          <!-- TODO: Submit form -->
        </ValidationObserver>
      </div>
    </div>
  </Master>
</template>

<script>
import { mapActions } from 'vuex'
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import AdminSSCGuardMixin from '@/mixins/AdminSSCGuardMixin'
import RadioBox from '@/components/common/RadioBox'
import QuestionCircleSolid from '@/assets/icons/question-circle-solid.svg'

export default {
  name: 'SscToSscSourcingAdminDetails',
  mixins: [
    PageMixin,
    LoaderMixin,
    SscSelectorGuardMixin,
    AuthorizedGuardMixin,
    AdminSSCGuardMixin,
  ],
  components: { RadioBox, QuestionCircleSolid },
  props: [
    'idx',
    'Capacity',
    'Contact',
    'CapName',
    'FunctionCode',
    'CapCode',
    'Note',
  ],
  methods: {
    ...mapActions('sourcing', ['updateCapability']),
    isValidEmail(email) {
      return !!email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      )
    },
    async submitForm() {
      this.emailInvalid = false
      const isValid = await this.$refs.observer.validate()

      if (!this.isValidEmail(this.formData.Contact)) {
        this.emailInvalid = true
        return
      }

      if (!isValid) return

      this.withLoader(() => {
        return this.updateCapability({
          ...this.formData,
          FunctionCode: this.FunctionCode,
          CapCode: this.CapCode,
        })
          .then(() => {
            this.setPath({
              name: 'SscToSscSourcingAdmin',
            })
          })
          .catch((e) => {
            console.log(e)
            this.setPath({
              name: 'ServiceUnavailable',
              params: {
                retryAction: () => {
                  this.setPath({
                    name: 'SscToSscSourcingAdminDetails',
                    params: {
                      Capacity: this.Capacity,
                      Contact: this.Contact,
                      capabilityName: this.capabilityName,
                    },
                  })
                },
              },
            })
          })
      })
    },
    resetContact() {
      this.formData.Contact = ''
    },
  },
  created() {
    this.formData.idx = this.idx
    if (!this.Contact || !this.Capacity) {
      this.setPath({
        name: 'SscToSscSourcingAdmin',
      })
    }

    this.formData.Contact = this.Contact
    this.formData.Capacity = this.Capacity
    this.formData.Note = this.Note
  },
  data() {
    return {
      emailInvalid: false,
      showHeaderMenu: true,
      formData: {
        idx: this.idx,
        Contact: '',
        Capacity: '',
        Note: '',
      },
    }
  },
}
</script>
