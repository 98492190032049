import SwagelokApiService from '@/services/swagelok-api'
import settings from '@/settings'
import { transformInventoryEntry } from '@/transformers'

const initialState = {
  inventoryPoolingBranch: null,
  inventoryData: null,
  inventoryPoolingData: null,
  newInventoryPoolingData: null,
  inventoryStockLoading: false,
  searchQuery: null,
  corporateStock: {},
  inventoryStock: {},
}

const mutations = {
  SET_INVENTORY_POOLING_BRANCH(state, data) {
    state.inventoryPoolingBranch = data
  },
  SET_INVENTORY_DATA(state, data) {
    state.inventoryData = data
  },
  SET_INVENTORY_POOLING_DATA(state, data) {
    state.inventoryPoolingData = data
  },
  SET_NEW_INVENTORY_POOLING_DATA(state, data) {
    state.newInventoryPoolingData = data
  },
  SET_SEARCH_QUERY(state, data) {
    state.searchQuery = data
  },
  SET_CORPORATE_STOCK(state, { partNumber, stock }) {
    state.corporateStock = {
      ...state.corporateStock,
      [partNumber]: stock,
    }
  },
  SET_INVENTORY_STOCK(state, { partNumber, stock }) {
    state.inventoryStock = {
      ...state.inventoryStock,
      [partNumber]: {
        ...stock,
        inventory: stock?.inventory
          ? stock.inventory
          : state.inventoryStock[partNumber]?.inventory,
      },
    }
  },
  SET_INVENTORY_STOCK_LOADING(state, { loading }) {
    state.inventoryStockLoading = loading
  },
}

const actions = {
  async setSearchQueryParams({ commit }, searchQueryParams) {
    commit('SET_SEARCH_QUERY', searchQueryParams)
  },
  async setActivePoolingBranch({ commit }, branch) {
    commit('SET_INVENTORY_POOLING_BRANCH', branch)
  },
  async getCorporateStock({ state, commit }, partNumber) {
    if (state.corporateStock[partNumber]) {
      return
    }

    const stock = await SwagelokApiService.GET('/inventory/corporate-stock', {
      partNumber,
    })

    const corporateStock = []
    const futureCorporateStock = []

    let inventory = Array.isArray(stock.entries.inventory)
      ? stock.entries.inventory
      : [stock.entries.inventory]

    inventory.forEach((i) => {
      corporateStock.push({
        location: `${stock.whoto}-${i.swagelokBranch}`,
        available: i.available,
        reserved: i.reserved,
      })

      if (i.schedule) {
        futureCorporateStock.push(
          ...(Array.isArray(i?.schedule) ? i?.schedule : [i?.schedule])?.map(
            (item) => ({
              branchId: i.swagelokBranch,
              branchName: i.swagelokBranchName,
              quantity: item.quantity,
              date: item.date,
            }),
          ),
        )
      }
    })

    commit('SET_CORPORATE_STOCK', {
      stock: { corporateStock, futureCorporateStock },
      partNumber,
    })
  },
  async resetInventoryStock({ commit }, { partNumber }) {
    commit('SET_INVENTORY_STOCK', { partNumber, stock: null })
  },
  async getInventoryStock(
    { state, commit },
    {
      partNumber,
      sscId,
      maxParts,
      currency,
      bpCode,
      skipPricingCall,
      requestingSSCWhoto,
    },
  ) {
    commit('SET_INVENTORY_STOCK_LOADING', { loading: true })

    const req = {
      partNumber,
      sscId,
      quantity: maxParts,
      currency,
      bpCode,
      requestingSSCWhoto,
    }

    if (skipPricingCall) {
      req.skipPricingCall = true
    }

    try {
      const stock = await SwagelokApiService.GET(
        '/inventory/inventory-stock',
        req,
      )

      commit('SET_INVENTORY_STOCK_LOADING', { loading: false })
      commit('SET_INVENTORY_STOCK', { partNumber, stock })
    } catch {
      commit('SET_INVENTORY_STOCK_LOADING', { loading: false })
    }
  },
  updateInventoryData({ commit, state }, newSSC) {
    const currentCollection = state.inventoryData?.sscCollection
    const homeIndex = currentCollection.findIndex((ssc) => ssc.isHome)

    currentCollection.splice(homeIndex ? homeIndex + 1 : 1, 0, newSSC)

    commit('SET_INVENTORY_DATA', {
      sscCollection: currentCollection,
      sscCollectionIndex: state.inventoryData?.sscCollectionIndex,
      isComplete: true,
    })
  },
  async setInventoryData({ commit, state, rootState, getters }) {
    const { partNumber, operator } = state.searchQuery

    const userSsc = rootState.user.userData.ssc

    const updateCollection = async (params) => {
      const {
        collectionSlice,
        collectionIndex,
        isComplete,
        corporateInventory,
      } = await SwagelokApiService.inventory.search(params)

      if (corporateInventory !== null) {
        const homeIndex = collectionSlice.findIndex(
          (el) => el.whoto === userSsc?.uid,
        )

        collectionSlice.splice(homeIndex + 1, 0, corporateInventory)
      }

      const batchId = Date.now()
      const transformedSsc = collectionSlice
        .filter((el) => el)
        .map((el) =>
          transformInventoryEntry(el, userSsc?.uid === el.whoto, batchId),
        )

      const collectionToCommit = state.inventoryData?.sscCollection
        ? [...state.inventoryData.sscCollection, ...transformedSsc]
        : transformedSsc

      commit('SET_INVENTORY_DATA', {
        sscCollection: collectionToCommit,
        sscCollectionIndex: collectionIndex,
        isComplete,
      })
    }

    const searchStrategy = {
      async equals() {
        const getCurrentCount = () => {
          return (
            getters.sscForPart(partNumber)?.filter((ssc) => !ssc.isHome)
              .length || 0
          )
        }
        const initCount = getCurrentCount()
        while (
          initCount === getCurrentCount() &&
          !state.inventoryData?.isComplete
        ) {
          await updateCollection({
            partNumber,
            operator,
            from: state.inventoryData?.sscCollectionIndex || 0,
            ssc: userSsc?.uid,
            maxParts: settings.inventory.maxPartsPerSsc,
          })
        }
      },
      async 'wildcard-home'() {
        await updateCollection({
          partNumber,
          operator,
          from: state.inventoryData?.sscCollectionIndex || 0,
          to: 1,
          ssc: userSsc?.uid,
          maxParts: settings.inventory.maxPartsPerSsc,
        })
      },
      async wildcard() {
        const currentCount = getters.partsWithSsc?.length
        while (
          currentCount === getters.partsWithSsc?.length &&
          !state.inventoryData?.isComplete
        ) {
          await updateCollection({
            partNumber,
            operator,
            from: state.inventoryData?.sscCollectionIndex || 0,
            ssc: userSsc?.uid,
            maxParts: settings.inventory.maxPartsPerSsc,
          })
        }
      },
    }

    if (operator === 'Equals') {
      await searchStrategy['equals']()
    } else if (!state.inventoryData) {
      await searchStrategy['wildcard-home']()
    } else {
      await searchStrategy['wildcard']()
    }
  },

  async confirmSellingStatus({ commit, state }, data) {
    await SwagelokApiService.inventory.confirmSellingStatus(data)

    commit('SET_NEW_INVENTORY_POOLING_DATA', {
      ...state.newInventoryPoolingData,
      sellingRequests: state.newInventoryPoolingData.sellingRequests.filter(
        (i) => i.swagelokBranch !== data.SellingBranch,
      ),
    })
  },

  async cleanInventoryData({ commit }) {
    commit('SET_NEW_INVENTORY_POOLING_DATA', null)
    commit('SET_INVENTORY_POOLING_BRANCH', null)
  },

  async getPoolingInventory({ commit, state }, data) {
    const { sellingRequests, buyingRequests, branches } =
      await SwagelokApiService.inventory.getBranchesByHomeSSC()

    commit('SET_NEW_INVENTORY_POOLING_DATA', {
      sellingRequests,
      buyingRequests,
      branches,
    })
  },

  async confirmSellingRequest({ commit, state }, data) {
    const newInventoryPoolingData = JSON.parse(
      JSON.stringify(state.newInventoryPoolingData),
    )

    newInventoryPoolingData.sellingRequests =
      newInventoryPoolingData.sellingRequests.map((i) => {
        if (
          i.partNumber === data.partNumber &&
          i.swagelokBranch === data.swagelokBranch &&
          i.whoto === data.whoto
        ) {
          return data
        }

        return i
      })

    commit('SET_NEW_INVENTORY_POOLING_DATA', newInventoryPoolingData)
    await SwagelokApiService.inventory.updateRequests(data)
  },

  async submitRequests({ commit, state }) {
    const requests = state.newInventoryPoolingData.buyingRequests.flatMap((i) =>
      i.requests.map((r) => ({
        ...r,
        Status: 'Closed',
      })),
    )

    const newInventoryPoolingData = JSON.parse(
      JSON.stringify(state.newInventoryPoolingData),
    )

    newInventoryPoolingData.buyingRequests = []
    commit('SET_NEW_INVENTORY_POOLING_DATA', newInventoryPoolingData)
    await SwagelokApiService.inventory.updateRequests({ requests })
  },

  async toggleBuyingLocations({ commit, state }, data) {
    const newInventoryPoolingData = JSON.parse(
      JSON.stringify(state.newInventoryPoolingData),
    )

    const index = newInventoryPoolingData.buyingRequests.findIndex(
      (i) => i.partNumber === data.partNumber,
    )

    newInventoryPoolingData.buyingRequests[index] = data

    commit('SET_NEW_INVENTORY_POOLING_DATA', newInventoryPoolingData)
    await SwagelokApiService.inventory.updateRequests(data)
  },

  async removePartNumberFromRequest({ commit, state }, data) {
    const newInventoryPoolingData = JSON.parse(
      JSON.stringify(state.newInventoryPoolingData),
    )

    newInventoryPoolingData.buyingRequests =
      newInventoryPoolingData.buyingRequests.filter(
        (i) => i.partNumber !== data.partNumber,
      )

    commit('SET_NEW_INVENTORY_POOLING_DATA', newInventoryPoolingData)
    await SwagelokApiService.inventory.removePartNumberFromRequest(data)
  },

  async getBranchesByHomeSSC({ commit, state }) {
    const {
      branchList,
      partNumberList,
      inventoryForPartnersBranch,
      partnerSSCData,
      PoolingPartners,
    } = await SwagelokApiService.inventory.getBranchesByHomeSSC()

    commit('SET_INVENTORY_POOLING_DATA', {
      branchList,
      partNumberList,
      inventoryForPartnersBranch,
      partnerSSCData,
      PoolingPartners,
      selectedBranch: branchList[0].swagelokBranch,
    })
  },
  async setSelectedBranch({ commit, state }, swagelokBranch) {
    commit('SET_INVENTORY_POOLING_DATA', {
      branchList: state.inventoryPoolingData.branchList,
      partNumberList: state.inventoryPoolingData.partNumberList,
      inventoryForPartnersBranch:
        state.inventoryPoolingData.inventoryForPartnersBranch,
      partnerSSCData: state.inventoryPoolingData.partnerSSCData,
      PoolingPartners: state.inventoryPoolingData.PoolingPartners,
      selectedBranch: swagelokBranch,
    })
  },
  async getInventoryFromSSCs({ commit }, data) {
    return await SwagelokApiService.inventory.getBySSCs(data)
  },
  async corporateInventoryForPart({ commit }, partNumber) {
    return await SwagelokApiService.inventory.corporate(partNumber)
  },
}

const getters = {
  storedQuery: (state) => state.searchQuery,
  partsForHomeSsc: (state) => {
    const homeSsc = state.inventoryData.sscCollection.find((ssc) => ssc.isHome)
    if (!homeSsc) return null

    const entries = homeSsc.entries
      .slice()
      .filter(
        (entry) =>
          entry.totalAvailable >=
          (state.searchQuery.includeBelowMin ? 1 : state.searchQuery.minQty),
      )
    return {
      ...homeSsc,
      entries,
    }
  },
  partsWithSsc: (state) => {
    const parts = {}
    state.inventoryData?.sscCollection.forEach((ssc) => {
      const {
        BPcode,
        sscId,
        sscName,
        isHome,
        phone,
        email,
        totalPartsFound,
        batchId,
      } = ssc
      ssc.entries.forEach((entry) => {
        const {
          partNumber,
          inventory,
          totalAvailable,
          totalReserved,
          ivaOptOutFlag,
        } = entry
        if (
          totalAvailable >=
          (state.searchQuery.includeBelowMin ? 1 : state.searchQuery.minQty)
        ) {
          if (!parts[entry.partNumber]) parts[entry.partNumber] = []
          parts[entry.partNumber].push({
            batchId,
            partNumber,
            BPcode,
            sscId,
            sscName,
            isHome,
            phone,
            email,
            inventory,
            totalPartsFound,
            totalAvailable,
            totalReserved,
            ivaOptOutFlag,
          })
        }
      })
    })
    return Object.entries(parts)
      .filter(([_k, v]) => v)
      .map(([k, v]) => {
        return {
          batchId: v[0].batchId,
          partNumber: k,
          sscList: v,
          nearestSscId: v[0].sscId,
        }
      })
  },
  sscForPart: (state) => (partNumberArg) => {
    return state.inventoryData?.sscCollection
      .map((ssc) => {
        const partInventory = ssc.entries.find((entry) => {
          return (
            entry.partNumber === partNumberArg &&
            entry.totalAvailable >=
              (ssc.sscId === 'SWGLK' ||
              ssc.isHome ||
              state.searchQuery.includeBelowMin
                ? 0
                : state.searchQuery.minQty)
          )
        })

        if (!partInventory) return

        const {
          sscId,
          sscName,
          isHome,
          phone,
          email,
          totalPartsFound,
          entries,
          BPCode,
        } = ssc

        const {
          partNumber,
          inventory,
          totalAvailable,
          totalReserved,
          sellListPrice,
          sellListPriceCurrency,
          inventoryUoM,
          inventoryUoMValue,
        } = partInventory

        return {
          BPCode,
          sscId,
          sscName,
          isHome,
          phone,
          email,
          totalPartsFound,
          sellListPrice,
          sellListPriceCurrency,
          inventoryUoM,
          inventoryUoMValue,
          partNumber,
          inventory,
          totalAvailable,
          totalReserved,
          ivaOptOutFlag: entries.find((el) => el.partNumber === partNumber)
            ?.ivaOptOutFlag,
        }
      })
      .filter((el) => el)
  },
  metaForPart: (state) => (partNumberArg) => {
    let meta
    const homeSsc = state.inventoryData.sscCollection.find((ssc) => ssc.isHome)
    const homeMeta = homeSsc?.entries.find(
      (entry) => entry.partNumber === partNumberArg,
    )

    if (homeMeta) {
      meta = homeMeta
    } else {
      meta = state.inventoryData.sscCollection
        .find((ssc) =>
          ssc.entries.find((entry) => entry.partNumber === partNumberArg),
        )
        ?.entries.find((entry) => entry.partNumber === partNumberArg)
    }

    if (meta) {
      const { partNumber, partDescription, strategy } = meta
      return { partNumber, partDescription, strategy }
    }
  },
  homeSscDummy: (_state, _getters, rootState) => {
    const userSsc = rootState.user.userData.ssc

    return {
      isHome: true,
      sscId: userSsc.uid,
      sscName: userSsc.name,
      phone: userSsc.phone,
      email: userSsc.email,
      totalPartsFound: 0,
      totalAvailable: 0,
      totalReserved: 0,
      entries: [],
      inventory: [],
    }
  },
  collectionHasAnyResult: (state) => {
    return !!state.inventoryData.sscCollection.filter(
      (ssc) => ssc.totalPartsFound,
    ).length
  },
  numberOfSearchIteration: (state) => {
    const batches = state.inventoryData.sscCollection.map((el) => el.batchId)
    return new Set(batches).size
  },
  getInventoryPooling: (state) => state.inventoryPoolingData,
  corporateStock: (state) => (id) => state.corporateStock[id],
  inventoryStock: (state) => state.inventoryStock,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
  namespaced: true,
}
