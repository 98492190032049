import {
  transformConfiguratorAttributeEntry,
  transformConfiguratorResultEntry,
} from '@/transformers'
import SwagelokApiService from '@/services/swagelok-api'

const initialState = {
  searchAttributesLoading: false,
  searchAttributes: null,
  searchQuery: null,
  configuratorData: null,
  searchMethod: 'Parameters',
}

const mutations = {
  SET_SEARCH_ATTRIBUTES(state, data) {
    state.searchAttributes = data
  },
  SET_SEARCH_QUERY(state, data) {
    state.searchQuery = data
  },
  SET_CONFIGURATOR_DATA(state, data) {
    state.configuratorData = data
  },
  SET_SEARCH_METHOD(state, data) {
    state.searchMethod = data
  },
  SET_SEARCH_ATTRIBUTES_LOADING(state, data) {
    state.searchAttributesLoading = data
  },
}

const actions = {
  async setSearchQueryParams({ commit }, searchQueryParams) {
    commit('SET_SEARCH_QUERY', searchQueryParams)
  },
  async setSearchAttributes({ commit, state, rootState, getters }) {
    const searchAttributes =
      await SwagelokApiService.configurator.attributes.list()
    commit(
      'SET_SEARCH_ATTRIBUTES',
      searchAttributes.map((el) => {
        return transformConfiguratorAttributeEntry(el)
      }),
    )
  },
  async setDynamicSearchAttributes({ commit }, params) {
    commit('SET_SEARCH_ATTRIBUTES_LOADING', true)
    const searchAttributes =
      await SwagelokApiService.configurator.attributes.dynamicList(params)
    commit(
      'SET_SEARCH_ATTRIBUTES',
      searchAttributes.map((el) => {
        return transformConfiguratorAttributeEntry(el)
      }),
    )
    commit('SET_SEARCH_ATTRIBUTES_LOADING', false)
  },
  async setConfiguratorData({ commit, state, rootState, getters }) {
    const { configurations: configuratorData, partsInventory } =
      await SwagelokApiService.configurator.search.list({
        ...state.searchQuery,
        ssc: rootState.user.userData.ssc.uid,
      })
    commit(
      'SET_CONFIGURATOR_DATA',
      configuratorData.map((el) => {
        return transformConfiguratorResultEntry(el, partsInventory)
      }),
    )
  },
  async setConfiguratorDataByPartNumber({ commit }, partNumber) {
    const res = await SwagelokApiService.configurator.search.byPartNumber(
      partNumber,
    )

    if (!res?.length) {
      commit('SET_CONFIGURATOR_DATA', [])
      commit('SET_SEARCH_QUERY', {})
    }

    return res
  },
  async setSearchMethod({ commit }, searchMethod) {
    commit('SET_SEARCH_METHOD', searchMethod)
  },
  async cleanConfiguratorData({ commit }) {
    commit('SET_CONFIGURATOR_DATA', null)
  },
  async cleanSearchAttributer({ commit }) {
    commit('SET_SEARCH_ATTRIBUTES', null)
  },
}

const getters = {
  attributesFor:
    (state) =>
    ({ type, value }) => {
      // Get only available pairs if value is set
      const filter = value
        ? (el) => el.type === type && el.pairs.includes(value)
        : (el) => el.type === type

      return state.searchAttributes
        ?.filter(filter)
        .map((el) => ({
          ...el,
          priority: el.priority ? el.priority : Infinity,
        }))
        .sort((a, b) => a.value.localeCompare(b.value))
        .sort((a, b) => a.priority - b.priority)
    },
  filteredConfigurations: (state) => {
    return state.configuratorData?.filter((el) => {
      return (
        (state.searchQuery.includePipeFittings ? true : !el.hasPipeFittings) &&
        (state.searchQuery.includeOutOfStock ? true : el.available > 0)
      )
    })
  },
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
  namespaced: true,
}
