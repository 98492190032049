import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import settings from '@/settings'
import Loader from '@/components/common/Loader'
import Table2 from '@/components/common/Table2'
import Table3 from '@/components/common/Table3'
import TrafficLight from '@/components/common/TrafficLight'
import Table4 from '@/components/common/Table4'
import Master from '@/components/common/Master'
import UserCreds from '@/components/common/UserCreds'
import InputClearButton from '@/components/form/InputClearButton'
import Input from '@/components/form/Input'
import FormSelectWrapper from '@/components/common/FormSelectWrapper'
import ResultsHomeSsc from '@/components/common/ResultsHomeSsc'
import PageHeading from '@/components/common/PageHeading'
import InfoButton from '@/components/common/InfoButton'
import ToggleButton from 'vue-js-toggle-button'
import Validation from '@/validation'
import VueMask from 'v-mask'
import PerfectScrollbar from 'vue2-perfect-scrollbar'

import 'tailwindcss/tailwind.css'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import '@/styles/styles.css'
import '@/assets/font-icons/fontello.css'
import '@/assets/font-icons/swagelok.css'

import './registerServiceWorker'

Vue.config.productionTip = false
Vue.component('Loader', Loader)
Vue.component('Table2', Table2)
Vue.component('Table3', Table3)
Vue.component('TrafficLight', TrafficLight)
Vue.component('Table4', Table4)
Vue.component('Master', Master)
Vue.component('UserCreds', UserCreds)
Vue.component('FormSelectWrapper', FormSelectWrapper)
Vue.component('ResultsHomeSsc', ResultsHomeSsc)
Vue.component('PageHeading', PageHeading)
Vue.component('InfoButton', InfoButton)
Vue.component('InputClearButton', InputClearButton)
Vue.component('Input', Input)

Validation.init()

Vue.use(PerfectScrollbar)
Vue.use(VueMask)
Vue.use(ToggleButton)

Vue.prototype.navigator = window.navigator

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

console.log(`Swagelok Sales Enablement Tool v${settings.app.version}`)
