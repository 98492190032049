<template>
  <Master>
    <div class="container">
      <OrderHeader>
        <ArrowLeft class="w-5" />
        Outgoing Request
      </OrderHeader>

      <Order :order="item" :whoTo="item.SellingSSC" />

      <div v-if="item.Status === 'Requested'">
        <button
          @click="
            () => {
              $store.dispatch('updateOrder', {
                type: $attrs.type,
                id: $attrs.id,
                order: { ...item, Status: 'Canceled' },
              })
              item.Status = 'Canceled'
            }
          "
          class="link-red"
        >
          Cancel Order Request
        </button>
      </div>
    </div>
  </Master>
</template>

<script>
import ArrowLeft from '@/assets/icons/arrow-left.svg'
import HomeSSC from '@/components/HomeSSC'
import { InventoryOrderList } from '@/components/inventory/InventoryOrderList'
import { Order, OrderHeader } from '@/components/inventory/Order'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import PageMixin from '@/mixins/PageMixin'

export default {
  name: 'OutgoingRequest',
  mixins: [PageMixin, AuthorizedGuardMixin],
  components: { HomeSSC, InventoryOrderList, ArrowLeft, OrderHeader, Order },
  computed: {
    item() {
      return this.$attrs.request
    },
  },
  created() {
    if (!this.$attrs.request || !this.$attrs.type) {
      this.$store.dispatch('router/setPath', {
        name: 'OrderRequestsNotifications',
      })
    }
  },
}
</script>
