<template>
  <Master>
    <div class="inventory-details">
      <loader v-if="$store.state.inventory.inventoryStockLoading" />

      <div class="container" v-if="partMeta">
        <InventoryHeader />

        <div class="">
          <PageHeading :title="partMeta.partNumber">
            <a
              v-if="partMeta.strategy && partMeta.strategy.includes('MTS')"
              :href="`https://products.swagelok.com/p/${partMeta.partNumber}`"
              class="
                icon-swg-info
                text-brandLightBlue
                hover:text-blue-700
                text-xl
              "
              target="_blank"
            />
          </PageHeading>
          <div class="mt-1">{{ partMeta.partDescription }}</div>
          <div class="mt-2 text-strategy flex justify-between items-center">
            <div class="font-bold" id="inv-fulfillment-strategy-code">{{ partMeta.strategy }}</div>

            <div
              v-if="
                isIVAOrderingFunctionalityEnabled &&
                (isAdmin || isInOrderingGroup) &&
                IVAOrderingFunctionality &&
                isSSCVisible &&
                ((!this.isWsCorp && this.allowedGroups) ||
                  this.allowedGroups) &&
                sellPrice
              "
            >
              <span class="mr-2">List Price</span>
              <span class="text-headerColor text-3xl">{{ sellPrice }}</span>
            </div>
          </div>
        </div>
        <hr class="mt-3 -mx-2" />
        <div class="mt-4">
          <PageHeading :title="ssc.sscId" />
          <div class="mt-1">{{ ssc.sscName }}</div>
          <div class="flex mt-2" v-show="ssc.sscId !== 'SWGLK'">
            <div class="icon-swg-phone text-brandBlue text-xl mr-2" />
            <a
              :href="`tel:#${ssc.phone}`"
              class="text-brandGreen"
              v-if="ssc.phone"
              >{{ ssc.phone }}</a
            >
            <div v-else>N/A</div>
          </div>
          <div class="flex mt-1 gap-2" v-show="ssc.sscId !== 'SWGLK'">
            <EnvelopeIcon class="w-6 h-6" />
            <a
              :href="`mailto:${ssc.email}?subject=Inventory Visibility inquiry for part ${partMeta.partNumber}`"
              class="text-brandGreen"
              v-if="ssc.email"
              >{{ ssc.email }}</a
            >
            <div v-else>N/A</div>
          </div>
        </div>
        <hr class="mt-4 -mx-2" />
        <table2
          :header="{ cellA: 'Branch', cellB: 'Available' }"
          :body="{
            cellA: { title: 'title' },
            cellB: 'available',
          }"
          :options="{ cellARegularFont: true, truncate: true }"
          :items="items"
          :itemHasCursor="false"
          itemKey="branchId"
          :withPlusSign="true"
          v-if="items.length && !isHomeSSC && !ssc.inventoryUoMValue"
        />
        <table3
          :header="{ cellA: 'Branch', cellB: 'Available', cellC: '' }"
          :body="{
            cellA: { title: 'title' },
            cellB: 'available',
            cellC: 'inventoryUoMValue',
          }"
          :options="{
            cellARegularFont: true,
            truncate: true,
            cellCSize: 'w-[32px] !p-0 flex-none',
            cellBClass: '!w-full pr-2',
          }"
          :items="items"
          :itemHasCursor="false"
          itemKey="branchId"
          :withPlusSign="true"
          v-if="items.length && !isHomeSSC && ssc.inventoryUoMValue"
        />

        <table3
          :header="{ cellA: 'Branch', cellB: 'Available', cellC: 'Reserved' }"
          :body="{
            cellA: { title: 'title' },
            cellB: 'available',
            cellC: 'reserved',
          }"
          :options="{ cellARegularFont: true, truncate: true }"
          :items="ssc.inventory"
          :itemHasCursor="false"
          itemKey="branchId"
          v-if="ssc.inventory.length && isHomeSSC"
        />

        <div
          v-if="!canOrder"
          class="text-center mt-8 italic text-secondaryGrayer text-sm"
        >
          {{ cannotOrderMessage }}
        </div>

        <InventoryAddToCart
          v-if="canOrder && IVAOrderingFunctionality"
          @onQuantityChange="onQuantityChange"
          :IVAOrderingFunctionality="IVAOrderingFunctionality"
          :minQty="$route.params.minQty"
          :readyNow="readyNow"
          :availability="totalAvailable"
          :partNumber="partMeta.partNumber"
          :sellingSSC="ssc.sscId"
          :requestingSSC="homeSscDummy.sscId"
          :price="Number(inventoryPrice)"
          :sellingSSCEmail="ssc.email"
          :currency="ssc.sellListPriceCurrency"
          :bpCode="BPCode"
        />

        <div v-if="ssc.sscId === 'SWGLK'" class="mt-10">
          <div class="text-xl font-bold text-headerColor">
            Future Corporate Stock
          </div>

          <div class="mt-2" v-if="futureStock.length === 0">
            <hr />
            <div class="mt-4 mb-4">No future stock scheduled at this time</div>
            <hr />
          </div>

          <table3
            :header="{ cellA: 'Branch', cellB: 'Quantity', cellC: 'Date' }"
            :body="{
              cellA: { title: 'branchId' },
              cellB: 'quantity',
              cellC: 'date',
            }"
            :options="{
              textSize: 'text-[16px]',
              cellCSize: 'w-[30%]',
            }"
            :items="futureStock"
            :itemHasCursor="false"
            itemKey="branchId"
            v-if="ssc.inventory.length && futureStock.length"
          />
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import EnvelopeIcon from '@/assets/icons/envelope.svg'
import InventoryAddToCart from '@/components/inventory/InventoryAddToCart'
import InventoryHeader from '@/components/inventory/InventoryHeader'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import InventoryMixin from '@/mixins/InventoryMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    EnvelopeIcon,
    InventoryHeader,
    InventoryAddToCart,
  },
  name: 'InventorySscDetails',
  mixins: [
    PageMixin,
    SscSelectorGuardMixin,
    InventoryMixin,
    AuthorizedGuardMixin,
  ],
  computed: {
    ...mapGetters('settings', ['isIVAOrderingFunctionalityEnabled']),
    ...mapGetters('user', ['isWsCorp', 'isInOrderingGroup', 'isAdmin']),
    inventoryPrice() {
      return this.$store.state.inventory.inventoryStock[this.partNumber]
        ? this.$store.state.inventory.inventoryStock[this.partNumber].price
        : this.price
    },
    sellPrice() {
      if (!this.$store.state.inventory.inventoryStock[this.partNumber].price) {
        return null
      }

      return new Intl.NumberFormat('default', {
        style: 'currency',
        currency:
          this.$store.state.inventory.inventoryStock[this.partNumber].currency,
      }).format(
        this.$store.state.inventory.inventoryStock[this.partNumber].price,
      )
    },
    BPCode() {
      return this.$store.state.inventory.inventoryStock[this.partNumber]
        ?.inventory?.BPCode
    },
    readyNow() {
      return this.ssc.inventory.some((item) => item.readyNow)
    },
    isHomeSSC() {
      return this.homeSscDummy.sscId === this.$route.params.sscId
    },
    canOrder() {
      return (
        this.homeSscDummy.sscId !== this.$route.params.sscId &&
        this.$route.params.sscId !== 'SWGLK'
      )
    },
    cannotOrderMessage() {
      return this.$route.params.sscId !== 'SWGLK'
        ? 'Cannot Order From Own SSC'
        : 'Cannot Order From Corporate through the App'
    },
    items() {
      return this.ssc.inventory.map((item) => ({
        ...item,
        available: `${item.available}${parseInt(item.reserved) ? `+` : ''}`,
        inventoryUoMValue: this.ssc.inventoryUoMValue,
      }))
    },
  },
  methods: {
    ...mapActions('settings', ['getSSCSettings']),
    async onQuantityChange(maxParts) {
      this.$store.dispatch('inventory/getInventoryStock', {
        partNumber: this.partNumber,
        sscId: this.ssc.sscId,
        maxParts,
        currency: this.ssc.sellListPriceCurrency,
        bpCode: this.BPCode
          ? this.BPCode === 'eCommBP'
            ? 'IVABP'
            : this.BPCode
          : 'IVABP',
      })
    },
    getInventoryStock() {
      if (
        !this.IVAOrderingFunctionality ||
        !this.isIVAOrderingFunctionalityEnabled
      ) {
        return
      }

      this.$store.dispatch('inventory/getInventoryStock', {
        partNumber: this.partNumber,
        sscId: this.ssc.sscId,
        maxParts: this.$route.params.minQty || 1,
        currency: this.ssc.sellListPriceCurrency,
        bpCode: null,
      })
    },
  },
  created() {
    try {
      this.partNumber = this.$route.params.partNumber
      const sscId = this.$route.params.sscId

      this.$store.dispatch('inventory/resetInventoryStock', {
        partNumber: this.$route.params.partNumber,
      })

      this.ssc = this.sscForPart(this.partNumber).find(
        (ssc) => ssc.sscId === sscId,
      )

      this.allowedGroups = this.userData.groups.some((group) =>
        ['wsSsc', 'wsSwcon', 'wsAdmin', 'admin', 'support'].includes(group),
      )

      this.getSSCSettings(sscId)
        .then(({ resource }) => {
          if (!resource) {
            return
          }

          this.IVAOrderingFunctionality = resource.IVAOrderingFunctionality

          this.getInventoryStock()

          this.ssc.phone = resource.IVAPhone?.length
            ? resource.IVAPhone
            : this.ssc.phone
          this.ssc.email = resource.IVAEmail?.length
            ? resource.IVAEmail
            : this.ssc.email

          if (this.ssc.sellListPrice && 'ShowHomeSSCListPrice' in resource) {
            const { ShowHomeSSCListPrice } = resource
            // Hide for the home SSC
            // this.isSSCVisible = ShowHomeSSCListPrice && this.ssc.isHome

            this.price = this.ssc.sellListPrice

            this.ssc.sellListPrice = new Intl.NumberFormat('default', {
              style: 'currency',
              currency: this.ssc.sellListPriceCurrency,
            }).format(this.ssc.sellListPrice)
          }
        })
        .catch((error) => console.error(error))

      if (this.ssc.inventory.length > 0) {
        this.ssc.inventory.forEach((value) => {
          value.available = value.available.toLocaleString('default')
          this.totalAvailable += parseInt(value.available.split(',').join(''))
          value.reserved = value.reserved.toLocaleString('default')
          value.title = value.branchName
            ? `${value.branchId}-${value.branchName}`
            : `${value.branchId}`

          if (value.schedule?.length) {
            this.futureStock.push(
              ...value?.schedule?.map((item) => ({
                branchId: value.branchId,
                branchName: value.branchName,
                quantity: item.quantity,
                date: item.date,
              })),
            )
          }
        })
      }
      this.partMeta = this.metaForPart(this.partNumber)

      if (!this.ssc && sscId == this.homeSscDummy.sscId) {
        this.ssc = this.homeSscDummy
      }

      if (!this.ssc || !this.partMeta)
        throw new Error('Display data is not found')
    } catch (e) {
      console.log(e)
      this.setPath({ name: 'Inventory' })
    }
  },
  data() {
    return {
      partNumber: null,
      price: null,
      partMeta: null,
      ssc: null,
      futureStock: [],
      showHeaderMenu: false,
      isSSCVisible: true,
      allowedGroups: false,
      totalAvailable: 0,
      IVAOrderingFunctionality: true,
    }
  },
}
</script>
