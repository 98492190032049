var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Master',[_c('div',{staticClass:"inventory-part-details"},[_c('div',{staticClass:"container"},[(_vm.partMeta)?_c('div',{},[_c('PageHeading',{attrs:{"title":_vm.partMeta.partNumber}}),_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.partMeta.partDescription))]),_c('div',{staticClass:"flex justify-between mt-2 mb-4"},[_c('div',{staticClass:"text-strategy font-bold"},[_vm._v(_vm._s(_vm.partMeta.strategy))]),_c('SvgQuestionCircle',{staticClass:"\n              text-brandLightBlue\n              hover:text-blue-700\n              w-5\n              h-5\n              fill-current\n              cursor-pointer\n            ",on:{"click":_vm.showHelpReserve}})],1)],1):_vm._e(),(_vm.isIVAOrderingFunctionalityEnabled)?_c('div',{staticClass:"italic text-brandLightBlue text-sm mb-2"},[_vm._v(" Select a location to order product ")]):_vm._e(),_c('div',{staticClass:"italic text-brandLightBlue text-sm"},[_vm._v(" Reserved qty indicated by a plus (+) sign ")]),_c('hr',{staticClass:"-mx-2 mt-2"}),_c('div',{},[_c('table2',{attrs:{"header":{
            cellA: 'Location',
            cellB: 'Available',
          },"body":{
            cellA: {
              title: 'sscId',
              subtitle: 'sscName',
              hasIcon: 'isHome',
            },
            cellB: 'totalAvailable',
          },"options":{
            showReadyNow: this.isIVAOrderingFunctionalityEnabled,
          },"items":_vm.items,"itemHasCursor":true,"itemKey":"sscId"},on:{"itemClicked":function (item) { return _vm.showDetails(item); }},scopedSlots:_vm._u([{key:"cellAIcon",fn:function(){return [_c('div',{staticClass:"\n                icon-swg-home\n                text-brandYellow text-xl\n                leading-none\n                top-0.5\n                relative\n              "})]},proxy:true}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }