<template>
  <button
    type="button"
    class="
      icon-swg-circle-close
      flex
      items-center
      cursor-pointer
      hover:text-gray-300
      text-brandBlue text-2xl
    "
    @click="$emit('click')"
  />
</template>

<script>
export default {
  name: 'InputClearButton',
}
</script>
