<template>
  <Master>
    <loader v-if="isLoading" />
    <div class="inventory-pooling">
      <div class="container" v-if="!isLoading">
        <div class="flex justify-between items-baseline mb-4">
          <PageHeading title="Inventory Pooling" />
        </div>

        <ValidationObserver
          tag="form"
          ref="observer"
          class="relative"
          id="set-select-ssc"
        >
          <div class="grid grid-flow-col auto-cols-max mt-3 mb-4">
            <RadioBox
              label="Sell view"
              value="sellView"
              inputName="inv-pooling-filter"
              class="mr-4"
              v-model="displayFilter"
              @change="changeDisplayFilterMode('sellView')"
            />
            <RadioBox
              label="Buy view"
              value="buyView"
              inputName="inv-pooling-filter"
              class="mr-2"
              v-model="displayFilter"
              @change="changeDisplayFilterMode('buyView')"
            />
          </div>

          <BranchSelect
            :branches="newInventoryPoolingData.branches"
            @change="(branch) => (activeBranch = branch)"
          />

          <loader v-if="isLoading" />
        </ValidationObserver>
        <div v-if="activeBranch" class="mt-6">
          <div v-if="displayFilter === 'sellView'">
            <InventoryPoolingSellTable
              id="byPartNumber"
              :items="newInventoryPoolingData.sellingRequests"
              :activeBranch="activeBranch"
              @itemClicked="(item) => showDetails(item)"
            />
          </div>
          <div v-else-if="displayFilter === 'buyView'">
            <InventoryPoolingBuyTable
              id="byPartNumber"
              :items="newInventoryPoolingData.buyingRequests"
              :helpPage="'InventoryPoolingPartnerHelp'"
              :activeBranch="activeBranch"
              @itemClicked="(item) => showDetails(item)"
            />
          </div>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import BranchSelect from '@/components/BranchSelect'
import RadioBox from '@/components/common/RadioBox'
import InventoryPoolingBuyTable from '@/components/inventory/InventoryPoolingBuyTable'
import InventoryPoolingSellTable from '@/components/inventory/InventoryPoolingSellTable'
import InventoryTable2Columns from '@/components/inventory/InventoryTable2Columns'
import InventoryTable5Columns from '@/components/inventory/InventoryTable5Columns'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import InventoryMixin from '@/mixins/InventoryMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'InventoryPooling',
  mixins: [
    PageMixin,
    LoaderMixin,
    SscSelectorGuardMixin,
    InventoryMixin,
    AuthorizedGuardMixin,
  ],
  components: {
    InventoryTable2Columns,
    InventoryTable5Columns,
    RadioBox,
    InventoryPoolingSellTable,
    InventoryPoolingBuyTable,
    BranchSelect,
  },
  methods: {
    ...mapActions('inventory', [
      'getPoolingInventory',
      'getBranchesByHomeSSC',
      'setSelectedBranch',
      'getInventoryFromSSCs',
    ]),
    ...mapActions('settings', ['getSSCSettings']),
    ...mapMutations('inventory', {
      setInventoryPoolingData: 'SET_INVENTORY_POOLING_DATA',
    }),
    showDetails(item) {
      this.setPath({
        name:
          this.displayFilter === 'sellView'
            ? 'InventoryPoolingSellDetail'
            : 'InventoryPoolingBuyDetail',
        params: {
          partNumber: item.partNumber,
          selectedBranch: this.selectedBranch,
          selectedFullNameBranch: this.selectedFullNameBranch,
          item,
          activeBranch: this.activeBranch,
        },
        routeMap: {
          goBackState: {
            name: 'InventoryPooling',
          },
        },
      })
    },
    changeDisplayFilterMode(mode) {
      this.displayFilter = mode
      window.localStorage.setItem('inventoryPoolingView', this.displayFilter)
    },
    changeBranch(swagelokBranch) {
      console.log(swagelokBranch)
    },
  },
  computed: {
    ...mapGetters('inventory', ['getInventoryPooling']),
    ...mapState('inventory', [
      'inventoryPoolingData',
      'newInventoryPoolingData',
    ]),
    ...mapGetters('user', ['isWsCorp']),
  },
  async created() {
    this.isLoading = true
    const userSSC = this.userData.ssc.uid

    const selectedView = window.localStorage.getItem('inventoryPoolingView')

    if (selectedView) {
      this.changeDisplayFilterMode(selectedView)
    }

    if (this.newInventoryPoolingData) {
      this.isLoading = false
      return
    }

    try {
      await this.getPoolingInventory()
      this.isLoading = false
    } catch {
      this.isLoading = false
      this.setPath({
        name: 'ServiceUnavailable',
        params: {
          retryAction: () => {
            this.setPath({ name: 'InventoryPooling' })
          },
        },
      })
    }

    this.setRouteMap({
      Menu: {
        goBackState: { name: 'Home' },
      },
    })
  },
  data() {
    return {
      displayFilter: 'sellView',
      branches: [],
      items: [],
      selectedBranch: null,
      selectedFullNameBranch: null,
      poolingPartnersData: null,
      itemsByPartnerBranch: [],
      partsNeeded: null,
      activeBranch: null,
    }
  },
}
</script>
