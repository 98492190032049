<template>
  <div class="w-full relative">
    <slot></slot>
    <div
      class="icon-swg-dropdown absolute right-4 top-3.5 pointer-events-none"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'FormSelectWrapper',
}
</script>
