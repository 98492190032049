<template>
  <Master>
    <div class="inventory-pooling">
      <div class="container">
        <div class="flex justify-between items-baseline mb-4">
          <PageHeading title="Request(s) Submitted" />
        </div>

        <div class="mb-4">Your request(s) were submitted successfully.</div>
        <div>
          <router-link class="link-a font-semibold" to="/"
            >Return to Main Menu <span class="icon-swg-arrow-right"></span
          ></router-link>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin';
import PageMixin from '@/mixins/PageMixin';

export default {
  name: 'RequestsSubmitted',
  mixins: [PageMixin, AuthorizedGuardMixin],
}
</script>
