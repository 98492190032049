<template>
  <validation-provider tag="div" rules="" :name="name" class="mb-6">
    <label :class="labelClassName" :for="name" v-if="label">{{ label }}</label>
    <div class="flex gap-4">
      <input
        class="form-input"
        :placeholder="placeholder"
        autocomplete="off"
        v-model="model"
        :ref="name"
        :name="name"
        :type="type"
      />
      <InputClearButton
        v-if="showClearButton"
        @click="
          () => {
            model = ''
          }
        "
      />
    </div>
  </validation-provider>
</template>

<script>
export default {
  props: {
    label: { type: String },
    labelClassName: {
      type: String,
      default: 'text-xl text-headerColor font-bold mb-2 block',
    },
    value: { type: String, required: true },
    name: { type: String, required: true },
    placeholder: { type: String, default: '' },
    type: { type: String, default: 'text' },
    showClearButton: { type: Boolean, default: false },
  },

  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  name: 'Input',
}
</script>
