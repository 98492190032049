<template>
  <Master>
    <div class="inventory-pooling-part0number-help">
      <div class="container mt-2">
        <ul>
          <li class="mb-4 mt-3">
            <p class="font-bold text-sm text-headerColor flex mb-2">
              <TrafficLight color="green" class="mr-2" />Available in Full
            </p>
            <p class="text-strategy text-sm">
              Need is fully available at a partner branch.
            </p>
          </li>
          <li class="mb-4">
            <p class="font-bold text-sm text-headerColor flex mb-2">
              <TrafficLight color="yellow" class="mr-2" />Partially Available
            </p>
            <p class="text-strategy text-sm">
              Need is partially available at one or more partner branches, but
              cannot be met by any single branch.
            </p>
          </li>
          <li class="mb-4">
            <p class="font-bold text-sm text-headerColor flex mb-2">
              <TrafficLight color="red" class="mr-2" />Not Available
            </p>
            <p class="text-strategy text-sm">
              Need is not available at any partner branches.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import InventoryMixin from '@/mixins/InventoryMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import TrafficLight from '../components/common/TrafficLight'

export default {
  name: 'InventoryPoolingPartnerHelp',
  components: { TrafficLight },
  mixins: [
    PageMixin,
    LoaderMixin,
    SscSelectorGuardMixin,
    InventoryMixin,
    AuthorizedGuardMixin,
  ],
}
</script>
