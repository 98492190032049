import SwagelokApiService from '@/services/swagelok-api'

const initialState = {
  notifications: null,
  notificationsLoading: false,
  shortHistory: null,
  history: null,
}

const mutations = {
  setNotification(state, notifications) {
    state.notifications = notifications
  },
  setNotificationsLoading(state, status) {
    state.notificationsLoading = status
  },
  setShortHistory(state, shortHistory) {
    state.shortHistory = shortHistory
  },
  setHistory(state, history) {
    state.history = history
  },
  clean(state) {
    state.notifications = null
    state.shortHistory = null
    state.history = null
  },
}

const actions = {
  cleanOrders({ commit }) {
    commit('clean')
  },
  async cleanNotification({ commit, state }, { id, type, partNumber }) {
    const notifications = JSON.parse(JSON.stringify(state.notifications))
    notifications[type] = notifications[type].reduce((acc, i) => {
      if (i.PartNumber === partNumber) {
        if (i.requests.length === 1) {
          return acc
        }

        acc.push({
          ...i,
          requests: i.requests.filter((r) => r.id !== id),
        })

        return acc
      }

      acc.push(i)
      return acc
    }, [])

    commit('setNotification', notifications)

    await SwagelokApiService.POST('/inventory/clean-notification', {
      id,
      type: type === 'outgoing' ? 'ShowOutgoingRequest' : 'ShowIncomingRequest',
    })
  },

  async getNotifications({ commit, state }) {
    commit('setNotificationsLoading', true)
    const notifications = await SwagelokApiService.GET(
      '/inventory/order-history',
      { notifications: true },
    )
    commit('setNotification', notifications)
    commit('setNotificationsLoading', false)
  },

  async getShortOrderHistory({ commit, state }) {
    // if (state.historyShort) {
    //   return
    // }

    commit(
      'setShortHistory',
      await SwagelokApiService.GET('/inventory/order-history', { limit: 5 }),
    )
  },

  async getOrderHistory({ commit, state }, { type }) {
    // if (state.history) {
    //   return
    // }

    commit(
      'setHistory',
      await SwagelokApiService.GET('/inventory/order-history', { type }),
    )
  },

  async updateOrder({ commit, state }, { type, order }) {
    await SwagelokApiService.PUT(`/inventory/order-request/${order.id}`, {
      order,
    })

    const notifications = JSON.parse(JSON.stringify(state.notifications))
    notifications[type] = notifications[type].reduce((acc, i) => {
      if (i.PartNumber === order.PartNumber) {
        const index = i.requests.findIndex((r) => r.id === order.id)
        i.requests[index] = order
        acc.push(i)

        return acc
      }

      acc.push(i)
      return acc
    }, [])

    commit('setNotification', notifications)
  },

  // async cancelOrder({ commit, state }, { type, id, partNumber }) {
  //   await SwagelokApiService.POST(`/inventory/update-order/${id}`, {
  //     status: 'Canceled',
  //   })

  //   const notifications = JSON.parse(JSON.stringify(state.notifications))
  //   notifications[type] = notifications[type].reduce((acc, i) => {
  //     if (i.PartNumber === partNumber) {
  //       const order = i.requests.find((r) => r.id === id)
  //       order.Status = 'Canceled'
  //       acc.push({
  //         ...i,
  //       })

  //       return acc
  //     }

  //     acc.push(i)
  //     return acc
  //   }, [])

  //   commit('setNotification', notifications)
  // },
}

const getters = {
  notifications: (state) => state.notifications,
  notificationsLoading: (state) => state.notificationsLoading,
  notification:
    (state) =>
    ({ type, id }) =>
      state.notifications[type].find((i) => i.id === id),
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
  namespaced: false,
}
