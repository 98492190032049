<template>
  <Master>
    <div class="configurator-help">
      <div class="container">
        <ul class="mt-2 mb-4">
          <li class="mt-2 flex items-center">
            <span
              class="
                w-[20px]
                h-[20px]
                inline-block
                rounded-full
                bg-brandGreen
                mr-2
              "
            ></span>
            <b>Willing to accept inquiries</b>
          </li>
          <p class="mt-3">
            The SSC offers the capability and is willing to accept inquiries at
            this time.
          </p>
          <li class="mt-5 flex items-center">
            <span
              class="
                w-[20px]
                h-[20px]
                inline-block
                rounded-full
                bg-redTrafficLight
                mr-2
              "
            ></span>
            <b>Unable to accept inquiries at this time</b>
          </li>
          <p class="mt-3">
            The SSC offers the capability, but does not have capacity at this
            time to accept inquiries.
          </p>
          <li class="mt-5 flex items-center">
            <span
              class="
                icon-swg-circle-close
                flex
                items-center
                text-headerColor
                w-[20px]
                h-[20px]
              "
            ></span>
            <b>Capability not offered</b>
          </li>
          <p class="mt-3">
            The SSC does not offer the capability. Note this will only show for
            the home SSC; if a peer SSC does not offer a given capability it
            will not appear in the search results.
          </p>
        </ul>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'

export default {
  name: 'SscToSscSourcingHelp',
  mixins: [PageMixin, LoaderMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  data() {
    return {
      showHeaderMenu: true,
    }
  },
}
</script>
