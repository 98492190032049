<template>
  <Master>
    <loader v-if="!partMeta || !sscSettings || !ssc" />
    <div class="container" v-if="partMeta && sscSettings && ssc">
      <InventoryHeader :showOrderHistory="false" />
      <div class="">
        <PageHeading :title="partMeta.partNumber">
          <a
            v-if="partMeta.strategy && partMeta.strategy.includes('MTS')"
            :href="`https://products.swagelok.com/p/${partMeta.partNumber}`"
            class="
              icon-swg-info
              text-brandLightBlue
              hover:text-blue-700
              text-xl
            "
            target="_blank"
          />
        </PageHeading>
        <div class="mt-1">{{ partMeta.description }}</div>
        <div class="mt-2 text-strategy flex justify-between items-center">
          <div class="font-bold">{{ partMeta.strategy }}</div>
          <div v-if="IVAOrderingFunctionality && price">
            <span class="mr-2">Price</span>
            <span class="text-headerColor text-3xl">{{ price }}</span>
          </div>
        </div>
      </div>
      <hr class="mt-3 -mx-2" />
      <div class="mt-4 mb-12">
        <PageHeading :title="ssc.uid" />
        <div class="mt-1">{{ ssc.name }}</div>
        <div class="flex mt-2" v-show="ssc.sscId !== 'SWGLK'">
          <div class="icon-swg-phone text-brandBlue text-xl mr-2" />
          <a :href="`tel:#${phone}`" class="text-brandGreen" v-if="phone">{{
            phone
          }}</a>
          <div v-else>N/A</div>
        </div>
        <div class="flex mt-1 gap-2" v-show="ssc.sscId !== 'SWGLK'">
          <EnvelopeIcon class="w-6 h-6" />
          <a
            :href="`mailto:${email}?subject=Inventory Visibility inquiry for part ${partMeta.partNumber}`"
            class="text-brandGreen"
            v-if="email"
            >{{ email }}</a
          >
          <div v-else>N/A</div>
        </div>
      </div>

      <div class="bg-[#f0f0f0] p-3 px-4">
        Approved Qty: <span class="font-bold">{{ item.ApprovedQuantity }}</span>
      </div>

      <div class="mb-12">
        <InventoryAddToCart
          @onQuantityChange="onQuantityChange"
          @onAddToCart="onAddToCart"
          :showPriceMessage="true"
          :readyNow="true"
          :availability="item.ApprovedQuantity"
          :initialQty="item.ApprovedQuantity"
          :partNumber="partMeta.partNumber"
          :sellingSSC="item.SellingSSC"
          :requestingSSC="homeSscDummy.sscId"
          :price="part.price"
          :currency="partMeta.sellListPriceCurrency"
          :bpCode="part.inventory.BPCode"
          :IVAOrderingFunctionality="IVAOrderingFunctionality"
        />
      </div>

      <div>
        <button
          @click="
            () => {
              $store.dispatch('updateOrder', {
                type: 'outgoing',
                id: item.id,
                order: { ...item, Status: 'Canceled' },
              })
              item.Status = 'Canceled'

              $store.dispatch('router/setPath', {
                name: 'OutgoingRequest',
                params: {
                  request: item,
                  type: 'outgoing',
                },
                routeMap: {
                  goBackState: {
                    name: 'OrderRequestsNotifications',
                  },
                },
              })
            }
          "
          class="link-red"
        >
          Cancel Order Request
        </button>
      </div>
    </div>
  </Master>
</template>

<script>
import ArrowRight from '@/assets/icons/arrow-right.svg'
import EnvelopeIcon from '@/assets/icons/envelope.svg'
import InventoryAddToCart from '@/components/inventory/InventoryAddToCart'
import InventoryHeader from '@/components/inventory/InventoryHeader'
import { Order, OrderHeader } from '@/components/inventory/Order'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import InventoryMixin from '@/mixins/InventoryMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { mapActions, mapGetters, mapState } from 'vuex'
dayjs.extend(utc)

export default {
  name: 'ApprovedRequest',
  mixins: [
    LoaderMixin,
    PageMixin,
    SscSelectorGuardMixin,
    InventoryMixin,
    AuthorizedGuardMixin,
  ],
  components: {
    Order,
    OrderHeader,
    ArrowRight,
    InventoryHeader,
    EnvelopeIcon,
    InventoryAddToCart,
  },
  computed: {
    ...mapState('ssc', ['sscListData']),
    ...mapGetters('user', ['isWsCorp']),
    part() {
      return this.$store.state.inventory.inventoryStock[this.partNumber]
    },
    partMeta() {
      const partMeta = this.part?.inventory?.entries
        ? this.part?.inventory.entries[0]
        : undefined

      return partMeta
    },
    phone() {
      return this.sscSettings.IVAPhone || this.ssc.phone
    },
    email() {
      return this.sscSettings.IVAEmail || this.ssc.email
    },
    price() {
      const allowedGroups = this.userData.groups.some((group) =>
        ['wsSsc', 'wsSwcon', 'wsAdmin', 'admin', 'support'].includes(group),
      )

      if (
        this.partMeta.sellListPriceCurrency &&
        this.sscSettings?.ShowHomeSSCListPrice &&
        ((!this.isWsCorp && allowedGroups) || allowedGroups)
      ) {
        return new Intl.NumberFormat('default', {
          style: 'currency',
          currency: this.partMeta.sellListPriceCurrency,
        }).format(this.part?.price)
      }

      return null
    },
  },
  methods: {
    ...mapActions('ssc', ['setSscListData']),
    ...mapActions('settings', ['getSSCSettings']),
    onQuantityChange(maxParts) {
      this.$store.dispatch('inventory/getInventoryStock', {
        partNumber: this.$attrs.request.PartNumber,
        sscId: this.$attrs.request.SellingSSC,
        requestingSSCWhoto: this.$attrs.request.RequestingSSC,
        maxParts,
        currency: this.partMeta.sellListPriceCurrency,
        bpCode: this.part?.inventory?.BPCode,
      })
    },
    onAddToCart() {
      this.$store.dispatch('updateOrder', {
        type: 'outgoing',
        id: this.item.id,
        order: {
          ...this.item,
          Status: 'Ordered',
        },
      })
    },
  },
  async created() {
    if (!this.$attrs.request || !this.$attrs.type) {
      this.$store.dispatch('router/setPath', {
        name: 'OrderRequestsNotifications',
      })

      return
    }

    if (!this.sscListData) {
      await this.setSscListData()
    }

    this.item = this.$attrs.request
    this.partNumber = this.$attrs.request.PartNumber

    this.ssc = this.sscListData.find(
      (ssc) => ssc.uid === this.$attrs.request.SellingSSC,
    )

    if (!this.ssc) {
      this.$store.dispatch('router/setPath', {
        name: 'OrderRequestsNotifications',
      })
      console.log('SSC not found')
    }

    this.$store.dispatch('inventory/getInventoryStock', {
      partNumber: this.$attrs.request.PartNumber,
      sscId: this.$attrs.request.SellingSSC,
      requestingSSCWhoto: this.$attrs.request.RequestingSSC,
      currency: null,
      maxParts: parseInt(this.$attrs.request.ApprovedQuantity),
      bpCode: null,
    })

    this.getSSCSettings(this.$attrs.request.SellingSSC)
      .then(({ resource }) => {
        this.IVAOrderingFunctionality = resource.IVAOrderingFunctionality
        this.sscSettings = resource
      })
      .catch((error) => console.error(error))
  },
  data() {
    return {
      sscSettings: null,
      emailOverride: null,
      phoneOverride: null,
      approvedQuantity: 1,
      message: '',
      partNumber: null,
      item: null,
      IVAOrderingFunctionality: true,
      ssc: null,
    }
  },
}
</script>
