import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', ['isGuest', 'isAuthorized', 'isSsc']),
    ...mapState('user', ['userData']),
  },
  created() {
    if (this.isGuest) {
      this.setPath({
        name: 'Login',
        currentRouteName: this.$route.name,
      })
    } else if (!this.isAuthorized) {
      this.setPath({ name: 'Unauthorized' })
    } else if (this.isSsc && !this.userData.ssc) {
      this.setPath({
        name: 'ServiceUnavailable',
        params: {
          retryAction: false,
        },
      })
    }
  },
}
