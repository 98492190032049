<template>
  <Master>
    <div class="inventory-help">
      <div class="container">
        <div class="font-bold mt-4">Available</div>
        <div>
          The number in this column is the quantity that the SSC wants to
          advertise as available to sell to other SSCs.
        </div>
        <div class="font-bold mt-4">Reserved [Noted by a plus (+) sign]</div>
        <div>
          This is inventory that the SSC generally wants as a safety level but
          may be willing to make available under the right circumstances.
        </div>
        <div class="font-bold mt-4">
          Inventory Visibilility Module - Definitions
        </div>
        <div class="font-bold mt-4 mb-2">Non-CR Enabled Items</div>
        <div>
          <p class="mb-2">The calculations are as follows:</p>
          <p class="mb-2">
            1. <strong>Available Balance</strong> = In stock - On SO* – Min.
            Inventory
          </p>
          <p>2. <strong>Reserved for SSC</strong> = Min. Inventory</p>
          <p>
            *On SO = All Sales Orders + All Production Orders + All Inventory
            Transfer Requests in the system for that warehouse
          </p>
        </div>
        <div class="font-bold mt-4 mb-2">MTS that are CR Enabled</div>
        <div>
          <p class="mb-2">The calculations are as follows:</p>
          <p class="mb-2">
            1. <strong>Available Balance</strong> = In stock - On SO* - Reserved
            for SSC
          </p>
          <p class="mb-4">
            2. <strong>Reserved for SSC</strong> = the greater of (Reorder level
            + Order quantity – 1) or the Min Inventory value
          </p>
          <p class="mb-4">
            *On SO = Sales Orders with a Need ship date within the time fence**
            + Production Orders with start date within the time fence+ Inventory
            transfer request with posting date within the time fence + Any Sales
            orders that have a Need Ship Date outside the time fence but are
            allocated) for that warehouse.
          </p>
          <p>
            **Time fence = This is the Lead time set at the branch level for CR
            to review Sales orders that need to be fulfilled within this period.
          </p>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'InventoryHelpReserve',
  // mixins: [PageMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  methods: {},
  computed: {
    ...mapState('inventory', ['inventoryData']),
  },
  async created() {
    if (!this.inventoryData) {
      this.setPath({ name: 'Inventory' })
    }
  },
  data() {
    return {
      showHeaderMenu: false,
    }
  },
}
</script>
