<template>
  <Master>
    <div class="inventory-pooling-part0number-help">
      <div class="container mt-2">
        <ul>
          <li class="mb-4">
            <p class="font-bold text-sm text-headerColor">
              PG = Planning Group
            </p>
            <p class="text-strategy text-sm">
              Part planning group is based on the frequency that the CR item is
              sold by your SSC. It is used to segment products so that they can
              be planned based on Activity levels. We have the Home SSC's
              Planning Group 4 and 10 in this list.
            </p>
          </li>
          <li class="mb-4">
            <p class="font-bold text-sm text-headerColor">
              NQA = Net Quantity Available
            </p>
            <p class="text-strategy text-sm">
              The amount of inventory that is currently available to fill new
              customer orders or other demands. Calculated as: Inventory on hand
              - (Approved Sales Orders and Production orders within the time
              fence + Allocated Orders outside the time fence) + Purchase orders
              due within the time fence.
            </p>
          </li>
          <li class="mb-4">
            <p class="font-bold text-sm text-headerColor">
              Need = Amount Needed
            </p>
            <p class="text-strategy text-sm">
              This is the minimum quantity needed by the SSC to meet CR
              Requirements. Calculated as: Reorder level (for that branch) - NQA
            </p>
          </li>
          <li class="mb-4 mt-3">
            <p class="font-bold text-sm text-headerColor flex mb-2">
              <TrafficLight color="green" class="mr-2" />Available in Full
            </p>
            <p class="text-strategy text-sm">
              Need is fully available at a partner branch.
            </p>
          </li>
          <li class="mb-4">
            <p class="font-bold text-sm text-headerColor flex mb-2">
              <TrafficLight color="yellow" class="mr-2" />Partially Available
            </p>
            <p class="text-strategy text-sm">
              Need is partially available at one or more partner branches, but
              cannot be met by any single branch.
            </p>
          </li>
          <li class="mb-4">
            <p class="font-bold text-sm text-headerColor flex mb-2">
              <TrafficLight color="red" class="mr-2" />Not Available
            </p>
            <p class="text-strategy text-sm">
              Need is not available at any partner branches.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import InventoryMixin from '@/mixins/InventoryMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import TrafficLight from '../components/common/TrafficLight'

export default {
  name: 'InventoryPooling',
  components: { TrafficLight },
  mixins: [
    PageMixin,
    LoaderMixin,
    SscSelectorGuardMixin,
    InventoryMixin,
    AuthorizedGuardMixin,
  ],
}
</script>
