<template>
  <div class="footer bg-brandBlue">
    <div
      class="
        mx-auto
        max-w-[414px]
        px-4
        py-3
        flex flex-col
        justify-center
        items-center
        h-full
      "
    >
      <h5 class="text-gray-300" v-html="copyright"></h5>
      <h5 class="text-gray-300" v-html="poweredBy"></h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      copyright:
        '&copy; ' +
        new Date().getFullYear() +
        ' Swagelok Company. All rights reserved.',
      poweredBy:
        'Powered by <span class="text-white font-bold">Swagelok IT</span>',
    }
  },
}
</script>
