var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Master',[_c('div',{staticClass:"feedback"},[_c('div',{staticClass:"container"},[_c('PageHeading',{staticClass:"mb-4",attrs:{"title":"Help/Feedback"}}),(_vm.feedbackData && _vm.feedbackData.isSent)?_c('div',[_c('div',{staticClass:"font-bold text-headerColor"},[_vm._v(" Your message has been sent! ")]),_c('div',{},[_vm._v(" Thank you for your submission. A member of the project team will respond to you as soon as possible, generally within one business day. ")])]):(_vm.showErrorMessage)?_c('div',[_c('div',{staticClass:"font-bold text-headerColor"},[_vm._v("Unexpected Error")]),_c('div',{},[_vm._v(" We're sorry, but an unexpected error occurred while attempting to send feedback. ")]),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"-mx-2 hover:bg-gray-100"},[_c('div',{staticClass:"\n                px-2\n                py-2\n                cursor-pointer\n                hover:bg-gray-100\n                transition-colors\n              ",on:{"click":function($event){_vm.showErrorMessage = false}}},[_c('div',{staticClass:"link-a font-semibold",domProps:{"innerHTML":_vm._s("Retry <span class='icon-swg-arrow-right'></span>")}})])])])]):_c('ValidationObserver',{ref:"observer",staticClass:"relative",attrs:{"tag":"form","id":"set-submit-feedback"},on:{"submit":function($event){$event.preventDefault();return _vm.submitFeedback($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
return [_c('validation-provider',{attrs:{"tag":"div","rules":"required","name":"Subject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"w-full relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.subject),expression:"formData.subject"}],ref:"subject",staticClass:"form-input !pr-9",attrs:{"type":"text","name":"subject","disabled":_vm.isLoading,"placeholder":"Add a subject","id":"set-feedback-subject"},domProps:{"value":(_vm.formData.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "subject", $event.target.value)}}}),_c('SvgCancelCircle',{staticClass:"\n                absolute\n                right-4\n                top-3.5\n                w-6\n                h-6\n                fill-current\n                text-gray-300\n                hover:text-brandGray\n                cursor-pointer\n              ",class:_vm.isLoading || !_vm.formData.subject
                  ? 'hover:text-gray-300 cursor-default'
                  : '',on:{"click":function () { return (_vm.isLoading || !_vm.formData.subject ? null : _vm.resetSubject()); }}})],1),_c('transition',{attrs:{"name":"transition-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"absolute field-error"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)}),_c('validation-provider',{staticClass:"mt-5",attrs:{"tag":"div","rules":"required","name":"Message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"flex justify-between items-center"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.message),expression:"formData.message"}],staticClass:"form-textarea h-[400px]",attrs:{"disabled":_vm.isLoading,"type":"text","placeholder":"Request help, ask a question, or provide feedback","id":"set-feedback-body"},domProps:{"value":(_vm.formData.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "message", $event.target.value)}}})]),_c('transition',{attrs:{"name":"transition-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"absolute field-error"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)}),_c('button',{staticClass:"button-a mt-8",attrs:{"type":"submit","disabled":_vm.isLoading || failed}},[_vm._v(" SUBMIT ")]),(_vm.isLoading)?_c('loader'):_vm._e()]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }