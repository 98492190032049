<template>
  <Master class="flex">
    <div class="container" v-if="searchAttributes">
      <div class="flex justify-between items-baseline mb-4 relative">
        <PageHeading title="Multi-Piece Configurator">
          <div
            class="
              icon-swg-info
              inline-block
              text-brandLightBlue
              hover:text-blue-700
              absolute
              right-0
              top-1
              text-xl
              cursor-pointer
            "
            target="_blank"
            @click="onConfiguratorHelpClick"
          />
        </PageHeading>
      </div>
      <div class="mb-6">
        <div class="grid grid-flow-col justify-start mt-3">
          <p class="mr-4">Search Using</p>
          <RadioBox
            class="mr-4"
            label="Parameters"
            value="Parameters"
            inputName="mpc-search-method"
            :model-value="searchMethod"
            @change="
              () => {
                setSearchMethod('Parameters')
                if (showNoResultByPart) {
                  cleanConfiguratorData()
                }
              }
            "
          />
          <RadioBox
            @change="
              () => {
                setSearchMethod('PartNumber')
                cleanPartNumberResults()
              }
            "
            class="mr-4"
            label="Part Number"
            value="PartNumber"
            inputName="mpc-search-method"
            :model-value="searchMethod"
          />
        </div>
      </div>
      <div
        class="w-full relative flex mb-4 gap-2"
        v-show="searchMethod === 'PartNumber'"
      >
        <input
          class="form-input"
          id="mpc-search-part-number"
          type="text"
          name="partNumber"
          placeholder="Part Number"
          ref="partNumber"
          :value="partNumber"
          @input="partNumber = $event.target.value.toUpperCase()"
          :disabled="isLoading"
          @keypress="submitPartNumberSearch($event)"
        />
        <span
          class="icon-swg-circle-close flex items-center cursor-pointer"
          :class="
            !partNumber.length ? 'hover:text-gray-300 cursor-default' : ''
          "
          @click="resetPartNumber"
        ></span>
      </div>
      <ValidationObserver
        tag="form"
        ref="observer"
        @submit.prevent="doSearch($event)"
        v-slot="{ invalid }"
        class="grid gap-3"
        id="mpc-config"
      >
        <validation-provider
          tag="div"
          rules="required"
          name="Shape"
          v-show="searchMethod === 'Parameters'"
        >
          <FormSelectWrapper>
            <select
              id="mpc-shape"
              class="form-select"
              v-model="formData.shape"
              :disabled="isLoading || searchAttributesLoading"
            >
              <option value="" :disabled="!formData.shape" selected>
                {{ formData.shape ? 'Reset Shape' : 'Select Shape' }}
              </option>
              <option
                :value="shape.value"
                v-for="shape in shapes"
                :key="shape.value"
              >
                {{ shape.value }}
              </option>
            </select>
          </FormSelectWrapper>
        </validation-provider>
        <validation-provider
          tag="div"
          rules="required"
          name="Material"
          v-show="searchMethod === 'Parameters'"
        >
          <FormSelectWrapper>
            <select
              id="mpc-body-material"
              ref="material"
              class="form-select"
              v-model="formData.material"
              :disabled="isLoading || searchAttributesLoading"
            >
              <option value="" :disabled="!formData.material" selected>
                {{
                  formData.material
                    ? 'Reset Body Material'
                    : 'Select Body Material'
                }}
              </option>
              <option
                :value="material.value"
                v-for="material in materials"
                :key="material.value"
              >
                {{ material.value }}
              </option>
            </select>
          </FormSelectWrapper>
        </validation-provider>
        <validation-provider
          tag="div"
          rules="required"
          name="Connection 1 Size"
          v-show="searchMethod === 'Parameters'"
        >
          <FormSelectWrapper>
            <select
              id="mpc-connection-1-size"
              class="form-select"
              v-model="formData.con1Size"
              :disabled="isLoading || searchAttributesLoading"
            >
              <option value="" :disabled="!formData.con1Size" selected>
                {{
                  formData.con1Size
                    ? 'Reset Connection 1 Size'
                    : 'Select Connection 1 Size'
                }}
              </option>
              <option
                :value="connectionSize.value"
                v-for="connectionSize in connection1Sizes"
                :key="connectionSize.value"
              >
                {{ connectionSize.value }}
              </option>
            </select>
          </FormSelectWrapper>
        </validation-provider>
        <validation-provider
          tag="div"
          rules="required"
          name="Connection 1 Type"
          v-show="searchMethod === 'Parameters'"
        >
          <FormSelectWrapper>
            <select
              id="mpc-connection-1-type"
              class="form-select"
              v-model="formData.con1Type"
              :disabled="isLoading || searchAttributesLoading"
            >
              <option value="" :disabled="!formData.con1Type" selected>
                {{
                  formData.con1Type
                    ? 'Reset Connection 1 Type'
                    : 'Select Connection 1 Type'
                }}
              </option>
              <option
                :value="connectionType.value"
                v-for="connectionType in connection1Types"
                :key="connectionType.value"
                v-html="connectionType.value.replace('®', `<sup>®</sup>`)"
              ></option>
            </select>
          </FormSelectWrapper>
        </validation-provider>
        <validation-provider
          tag="div"
          rules="required"
          name="Connection 2 Size"
          v-show="searchMethod === 'Parameters'"
        >
          <FormSelectWrapper>
            <select
              id="mpc-connection-2-size"
              class="form-select"
              v-model="formData.con2Size"
              :disabled="isLoading || searchAttributesLoading"
            >
              <option value="" :disabled="!formData.con2Size" selected>
                {{
                  formData.con2Size
                    ? 'Reset Connection 2 Size'
                    : 'Select Connection 2 Size'
                }}
              </option>
              <option
                :value="connectionSize.value"
                v-for="connectionSize in connection2Sizes"
                :key="connectionSize.value"
              >
                {{ connectionSize.value }}
              </option>
            </select>
          </FormSelectWrapper>
        </validation-provider>
        <validation-provider
          tag="div"
          rules="required"
          name="Connection 2 Type"
          v-show="searchMethod === 'Parameters'"
        >
          <FormSelectWrapper>
            <select
              id="mpc-connection-2-type"
              class="form-select"
              v-model="formData.con2Type"
              :disabled="isLoading || searchAttributesLoading"
            >
              <option value="" :disabled="!formData.con2Type" selected>
                {{
                  formData.con2Type
                    ? 'Reset Connection 2 Type'
                    : 'Select Connection 2 Type'
                }}
              </option>
              <option
                :value="connectionType.value"
                v-for="connectionType in connection2Types"
                :key="connectionType.value"
              >
                {{ connectionType.value }}
              </option>
            </select>
          </FormSelectWrapper>
        </validation-provider>
        <validation-provider
          tag="div"
          rules="required"
          name="Connection 3 Size"
          v-if="isThirdConnectionShown"
          v-show="searchMethod === 'Parameters'"
        >
          <FormSelectWrapper>
            <select
              id="mpc-connection-3-size"
              class="form-select"
              v-model="formData.con3Size"
              :disabled="isLoading || searchAttributesLoading"
            >
              <option value="" :disabled="!formData.con3Size" selected>
                {{
                  formData.con3Size
                    ? 'Reset Connection 3 Size'
                    : 'Select Connection 3 Size'
                }}
              </option>
              <option
                :value="connectionSize.value"
                v-for="connectionSize in connection3Sizes"
                :key="connectionSize.value"
              >
                {{ connectionSize.value }}
              </option>
            </select>
          </FormSelectWrapper>
        </validation-provider>
        <validation-provider
          tag="div"
          rules="required"
          name="Connection 3 Type"
          v-if="isThirdConnectionShown"
          v-show="searchMethod === 'Parameters'"
        >
          <FormSelectWrapper>
            <select
              id="mpc-connection-3-type"
              class="form-select"
              v-model="formData.con3Type"
              :disabled="isLoading || searchAttributesLoading"
            >
              <option value="" :disabled="!formData.con3Type" selected>
                {{
                  formData.con3Type
                    ? 'Reset Connection 3 Type'
                    : 'Select Connection 3 Type'
                }}
              </option>
              <option
                :value="connectionType.value"
                v-for="connectionType in connection3Types"
                :key="connectionType.value"
              >
                {{ connectionType.value }}
              </option>
            </select>
          </FormSelectWrapper>
        </validation-provider>
        <validation-provider
          tag="div"
          rules="required"
          name="Connection 4 Size"
          v-if="isFourthConnectionShown"
          v-show="searchMethod === 'Parameters'"
        >
          <FormSelectWrapper>
            <select
              id="mpc-connection-4-size"
              class="form-select"
              v-model="formData.con4Size"
              :disabled="isLoading || searchAttributesLoading"
            >
              <option value="" :disabled="!formData.con4Size" selected>
                {{
                  formData.con4Size
                    ? 'Reset Connection 4 Size'
                    : 'Select Connection 4 Size'
                }}
              </option>
              <option
                :value="connectionSize.value"
                v-for="connectionSize in connection4Sizes"
                :key="connectionSize.value"
              >
                {{ connectionSize.value }}
              </option>
            </select>
          </FormSelectWrapper>
        </validation-provider>
        <validation-provider
          tag="div"
          rules="required"
          name="Connection 4 Type"
          v-if="isFourthConnectionShown"
          v-show="searchMethod === 'Parameters'"
        >
          <FormSelectWrapper>
            <select
              id="mpc-connection-4-type"
              class="form-select"
              v-model="formData.con4Type"
              :disabled="isLoading || searchAttributesLoading"
            >
              <option value="" :disabled="!formData.con4Type" selected>
                {{
                  formData.con4Type
                    ? 'Reset Connection 4 Type'
                    : 'Select Connection 4 Type'
                }}
              </option>
              <option
                :value="connectionType.value"
                v-for="connectionType in connection4Types"
                :key="connectionType.value"
              >
                {{ connectionType.value }}
              </option>
            </select>
          </FormSelectWrapper>
        </validation-provider>

        <div class="w-full flex justify-between items-end mt-4">
          <div class="">Include Pipe Fittings</div>
          <toggle-button
            v-model="formData.includePipeFittings"
            :sync="true"
            :labels="false"
            :width="58"
            :height="32"
            :color="{ checked: '#66b645', unchecked: '#D1D5DB' }"
            :disabled="isLoading"
            name="mpc-include-pipe-fittings"
          />
        </div>
        <div class="w-full flex justify-between items-end mt-4">
          <div class="">Include Out of Stock Configurations</div>
          <toggle-button
            v-model="formData.includeOutOfStock"
            :sync="true"
            :labels="false"
            :width="58"
            :height="32"
            :color="{ checked: '#66b645', unchecked: '#D1D5DB' }"
            :disabled="isLoading"
            name="mpc-include-out-of-stock"
          />
        </div>
        <FormSelectWrapper class="mt-6" v-if="branchListData">
          <select
            class="form-select"
            v-model="formData.sscUid"
            id="set-selected-branch"
            :disabled="isLoading"
            @change="setBranchPreference($event)"
          >
            <option value="" :selected="userSettingsBranch == ''">
              All Branches
            </option>
            <template v-for="ssc in branchListData">
              <option
                :value="ssc.swagelokBranch"
                :key="ssc.swagelokBranch"
                :selected="userSettingsBranch == ssc.swagelokBranch"
              >
                {{ ssc.swagelokBranch }}
                {{
                  ssc.swagelokBranchName
                    ? ` &mdash; ${ssc.swagelokBranchName}`
                    : ''
                }}
              </option>
            </template>
          </select>
        </FormSelectWrapper>
        <button
          class="button-a mt-6"
          id="submitParameters"
          type="submit"
          :disabled="isLoading || invalid"
          ref="parametersSubmitButton"
          v-show="searchMethod === 'Parameters'"
        >
          SEARCH
        </button>
        <button
          class="button-a mt-6"
          id="submitPartNumber"
          type="submit"
          :disabled="partNumber.length < 3 || isLoading"
          ref="partNumberSubmitButton"
          v-show="searchMethod === 'PartNumber'"
        >
          SEARCH
        </button>
      </ValidationObserver>
      <transition name="transition-fade">
        <div
          ref="results"
          class="relative mt-7"
          :class="isLoading ? 'min-h-[11rem]' : ''"
        >
          <loader v-if="isLoading" />
          <div
            id="mpc-config-results"
            :data-mpc-single-piece-exists="doesSinglePieceExists && ''"
            :data-mpc-multi-piece-exists="doesMultiPieceExists && ''"
            :data-mpc-single-piece-available="doesSinglePieceAvailable && ''"
            :data-mpc-multi-piece-available="doesMultiPieceAvailable && ''"
            :data-mpc-part-not-found="
              showNoResultByPart && searchMethod === 'PartNumber' && ''
            "
            v-if="reorderedFilteredConfigurations && !isLoading"
          >
            <hr class="-mx-2" />
            <ResultsHomeSsc :sscData="homeSscDummy" class="mt-2" />
            <hr class="-mx-2 mt-2" />
            <table3
              v-if="
                isSSCVisible &&
                reorderedFilteredConfigurations.length &&
                ((!this.isWsCorp && this.allowedGroups) || this.allowedGroups)
              "
              :header="{
                cellA: 'Configuration',
                cellB: 'List Price',
                cellC: 'Available',
              }"
              :body="{
                cellA: {
                  title: 'displayVariant',
                },
                cellB: 'sellListPrice',
                cellC: 'available',
              }"
              :items="reorderedFilteredConfigurations"
              :itemHasCursor="true"
              :options="{
                cellARegularFont: true,
                truncate: false,
                lineBreak: true,
                brakeWord: true,
              }"
              :footer="'notes'"
              itemKey="variant"
              @itemClicked="(item) => showDetails(item)"
            >
            </table3>
            <table2
              v-else
              v-show="reorderedFilteredConfigurations.length > 0 && !isLoading"
              :header="{
                cellA: 'Configuration',
                cellB: 'Available',
              }"
              :body="{
                cellA: {
                  title: 'displayVariant',
                },
                cellB: 'available',
              }"
              :items="reorderedFilteredConfigurations"
              :itemHasCursor="true"
              :options="{
                cellARegularFont: true,
                lineBreak: true,
                trafficLights: false,
                brakeWord: true,
              }"
              :footer="'notes'"
              itemKey="variant"
              @itemClicked="(item) => showDetails(item)"
            >
            </table2>
            <div
              class=""
              v-if="
                (showNoResult && searchMethod === 'Parameters') ||
                (!showNoResultByPart &&
                  !reorderedFilteredConfigurations.length &&
                  searchMethod === 'PartNumber')
              "
            >
              <div class="mt-4">
                No configurations match the search criteria.
              </div>
              <hr class="-mx-2 mt-4" />
            </div>
            <div
              class="py-2.5"
              v-if="showNoResultByPart && searchMethod === 'PartNumber'"
            >
              Part number not found.
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-else class="relative min-h-full min-w-full">
      <loader />
    </div>
  </Master>
</template>

<script>
import RadioBox from '@/components/common/RadioBox'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import eventBusService from '@/services/event-bus'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'Configurator',
  mixins: [PageMixin, LoaderMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  components: { RadioBox },
  watch: {
    'formData.shape': {
      handler(nv) {
        if (nv !== 'Cross') {
          this.formData.con4Size = ''
          this.formData.con4Type = ''
        }
        if (!['Tee', 'Cross'].includes(nv)) {
          this.formData.con3Size = ''
          this.formData.con3Type = ''
        }
      },
    },
  },
  computed: {
    ...mapState('configurator', [
      'searchAttributes',
      'searchAttributesLoading',
      'configuratorData',
      'searchQuery',
      'searchMethod',
    ]),
    ...mapGetters('configurator', ['attributesFor', 'filteredConfigurations']),
    ...mapGetters('inventory', ['homeSscDummy']),
    ...mapGetters('user', ['isWsCorp']),
    ...mapState('app', ['isTouchDevice']),
    ...mapState('ssc', ['branchListData']),
    isThirdConnectionShown() {
      return ['Tee', 'Cross'].includes(this.formData.shape)
    },
    isFourthConnectionShown() {
      return this.formData.shape === 'Cross'
    },
    shapes() {
      return this.attributesFor({ type: 'shape' })
    },
    materials() {
      return this.attributesFor({ type: 'material' })
    },
    connection1Types() {
      return this.attributesFor({
        type: 'type',
        value: this.formData.con1Size,
      })
    },
    connection2Types() {
      return this.attributesFor({
        type: 'type',
        value: this.formData.con2Size,
      })
    },
    connection3Types() {
      return this.attributesFor({
        type: 'type',
        value: this.formData.con3Size,
      })
    },
    connection4Types() {
      return this.attributesFor({
        type: 'type',
        value: this.formData.con4Size,
      })
    },
    connection1Sizes() {
      return this.attributesFor({
        type: 'size',
        value: this.formData.con1Type,
      })
    },
    connection2Sizes() {
      return this.attributesFor({
        type: 'size',
        value: this.formData.con2Type,
      })
    },
    connection3Sizes() {
      return this.attributesFor({
        type: 'size',
        value: this.formData.con3Type,
      })
    },
    connection4Sizes() {
      return this.attributesFor({
        type: 'size',
        value: this.formData.con4Type,
      })
    },

    sscData() {
      return this.homeSscDummy
    },
    showNoResult() {
      return !this.isLoading && !this.filteredConfigurations?.length
    },
    doesSinglePieceExists() {
      return this.configuratorData?.some((el) => el.isSpc)
    },
    doesSinglePieceAvailable() {
      return this.configuratorData?.some((el) => el.isSpc && el.available > 0)
    },
    doesMultiPieceExists() {
      return this.configuratorData?.some((el) => !el.isSpc)
    },
    doesMultiPieceAvailable() {
      return this.configuratorData?.some((el) => !el.isSpc && el.available > 0)
    },
    reorderedFilteredConfigurations() {
      if (this.filteredConfigurations?.length > 0 && !this.isLoading) {
        const sortByAvailability = (a, b) => {
          return b.available - a.available
        }

        // Reorder filtered configurations by price
        const sortByVariantASC = (a, b) => {
          // Move 'N/A' to the end
          if (a.sellListPrice === 'N/A' && b.sellListPrice !== 'N/A') {
            return 1
          }

          // Move 'N/A' to the end
          if (a.sellListPrice !== 'N/A' && b.sellListPrice === 'N/A') {
            return -1
          }

          if (a.sellListPrice !== 'N/A' && b.sellListPrice !== 'N/A') {
            const numA = parseFloat(a.sellListPrice.replace('$', ''))
            const numB = parseFloat(b.sellListPrice.replace('$', ''))
            return numA - numB
          }

          return 0
        }

        const reorderByVariant = this.filteredConfigurations
          .slice(0)
          .sort(sortByVariantASC)

        const singlePieceSolutionsAvailable = reorderByVariant
          .filter((i) => i.available)
          .filter((el) => el.variant.indexOf('+') < 0 && !el.notes)

        const singlePieceSolutions = reorderByVariant
          .filter((i) => !i.available)
          .filter((el) => el.variant.indexOf('+') < 0 && !el.notes)

        const multiPieceSolutionsAvailable = reorderByVariant
          .filter((i) => i.available)
          .filter((el) => el.variant.indexOf('+') > -1 && !el.notes)
          .sort(sortByVariantASC)

        const multiPieceSolutions = reorderByVariant
          .filter((i) => !i.available)
          .filter((el) => el.variant.indexOf('+') > -1 && !el.notes)
          .sort(sortByVariantASC)

        const solutionsWithNotesAvailable = reorderByVariant
          .filter((i) => i.available)
          .filter((el) => el.notes)
          .sort(sortByVariantASC)

        const solutionsWithNotes = reorderByVariant
          .filter((i) => !i.available)
          .filter((el) => el.notes)
          .sort(sortByVariantASC)

        return [
          ...singlePieceSolutionsAvailable,
          ...multiPieceSolutionsAvailable,
          ...solutionsWithNotesAvailable,
          ...singlePieceSolutions,
          ...multiPieceSolutions,
          ...solutionsWithNotes,
        ]
      }
      return this.filteredConfigurations
    },
  },
  methods: {
    ...mapActions('configurator', [
      'setSearchAttributes',
      'setDynamicSearchAttributes',
      'setConfiguratorData',
      'setSearchQueryParams',
      'setConfiguratorDataByPartNumber',
      'setSearchMethod',
      'cleanConfiguratorData',
      'cleanSearchAttributer',
    ]),
    ...mapActions('ssc', ['setBranchListData']),
    ...mapActions('settings', [
      'setSwagelokBranch',
      'getSwagelokBranch',
      'getSSCSettings',
    ]),
    ...mapMutations('configurator', {
      setConfiguratorDataMut: 'SET_CONFIGURATOR_DATA',
    }),
    ...mapMutations('configurator', { setSearchQueryMut: 'SET_SEARCH_QUERY' }),
    cleanPartNumberResults() {
      this.showNoResultByPart = false
      this.resetPartNumber()
      this.cleanConfiguratorData()
      this.cleanFormData()
    },
    cleanFormData() {
      this.formData.shape = ''
      this.formData.material = ''
      this.formData.con1Size = ''
      this.formData.con1Type = ''
      this.formData.con2Size = ''
      this.formData.con2Type = ''
      this.formData.con3Size = ''
      this.formData.con3Type = ''
      this.formData.con4Size = ''
      this.formData.con4Type = ''
    },
    withFallback(cb) {
      return cb().catch((e) => {
        console.log(e)
        this.setPath({
          name: 'ServiceUnavailable',
          params: {
            retryAction: () => {
              this.setPath({ name: 'Configurator' })
            },
          },
        })
      })
    },
    async doSearch(event) {
      window.dataLayer?.push({
        event: 'mpc-config-results',
      })

      if (event.submitter.id === 'submitParameters') {
        this.searchWithParameters()
        return
      }

      this.cleanFormData()
      this.isLoading = true
      this.setSearchQueryParams({
        ...this.formData,
        partNumber: this.partNumber,
      })

      const resp = await this.setConfiguratorDataByPartNumber(
        this.partNumber.toUpperCase(),
      )

      if (!resp.length) {
        // this.cleanFormData()
        this.showNoResultByPart = true
        this.isLoading = false
        return
      }

      this.showNoResultByPart = false

      this.formData.shape = resp[0].shape
      this.formData.material = resp[0].body_material
      this.formData.con1Size = resp[0].connection_1_size
      this.formData.con1Type = resp[0].connection_1_type
      this.formData.con2Size = resp[0].connection_2_size
      this.formData.con2Type = resp[0].connection_2_type
      this.formData.con3Size = resp[0].connection_3_size
      this.formData.con3Type = resp[0].connection_3_type
      this.formData.con4Size = resp[0].connection_4_size
      this.formData.con4Type = resp[0].connection_4_type

      this.isLoading = false

      this.$nextTick(() => this.searchWithParameters())
    },
    async searchWithParameters() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return
      }

      await this.withLoader(() => {
        this.setConfiguratorDataMut(null)
        this.setSearchQueryParams({
          ...this.formData,
          partNumber: this.partNumber,
        })
        if (this.isTouchDevice) {
          this.$nextTick(() => this.$refs.results.scrollIntoView(true))
        }
        return this.withFallback(this.setConfiguratorData)
      })

      if (this.isTouchDevice) {
        this.$nextTick(() => this.$refs.results.scrollIntoView(true))
      }
    },
    showDetails(item) {
      this.setPath({
        name: 'ConfigurationDetails',
        params: { variant: item.variant },
      })
    },
    clear() {
      this.setConfiguratorDataMut(null)
      for (let k in this.formData) {
        this.formData[k] = typeof this.formData[k] === 'string' ? '' : false
      }
    },
    onConfiguratorHelpClick() {
      this.setPath({
        name: 'ConfiguratorHelp',
      })
    },
    setBranchPreference(event) {
      const { value: swagelokBranch } = event.target
      window.localStorage.setItem('swagelokBranch', swagelokBranch)

      this.doSearch(event)
      this.setSwagelokBranch(swagelokBranch).catch((error) =>
        console.error(error),
      )
    },
    resetPartNumber() {
      this.partNumber = ''
    },
    submitPartNumberSearch(event) {
      const { keyCode } = event

      if (keyCode === 13) {
        this.$refs.partNumberSubmitButton.click()
      }
    },
  },
  updated() {
    eventBusService.$emit('configurator.updated')
  },
  beforeDestroy() {
    if (this.$route.name === 'ConfigurationDetails') {
      this.setSearchMethod(this.searchMethod)
      return
    }

    if (this.$route.name === 'ConfiguratorHelp') {
      return
    }

    this.cleanSearchAttributer()
  },
  async created() {
    this.withLoader(() => {
      return this.setBranchListData()
    })

    if (!this.searchAttributes) {
      this.withFallback(() => {
        return this.withLoader(() =>
          this.setDynamicSearchAttributes(this.formData),
        )
      })
    }

    if (this.searchQuery) {
      this.formData = { ...this.searchQuery }
      this.partNumber = this.searchQuery.partNumber
    }

    this.$watch('formData.includePipeFittings', () => {
      if (this.configuratorData) {
        this.withLoader(() => {
          return this.setSearchQueryParams({
            ...this.searchQuery,
            partNumber: this.partNumber,
            includePipeFittings: this.formData.includePipeFittings,
          })
        })
      }
    })
    this.$watch('formData.includeOutOfStock', () => {
      if (this.configuratorData) {
        this.withLoader(() => {
          return this.setSearchQueryParams({
            ...this.searchQuery,
            partNumber: this.partNumber,
            includeOutOfStock: this.formData.includeOutOfStock,
          })
        })
      }
    })
    this.setRouteMap({
      Menu: {
        goBackState: { name: 'Configurator' },
      },
    })

    const swagelokBranch = window.localStorage.getItem('swagelokBranch')

    if (swagelokBranch) {
      this.formData.sscUid = swagelokBranch
      this.userSettingsBranch = swagelokBranch
    } else {
      this.getSwagelokBranch()
        .then((response) => {
          if (response.statusCode === 200 && response.resource) {
            const { SwagelokBranch } = response.resource
            this.userSettingsBranch = SwagelokBranch ?? ''
            window.localStorage.setItem(
              'swagelokBranch',
              this.userSettingsBranch,
            )
            this.formData.sscUid = this.userSettingsBranch
            this.allowedGroups = this.userData.groups.some((group) =>
              ['wsSsc', 'wsSwcon', 'wsAdmin', 'admin', 'support'].includes(
                group,
              ),
            )
          }
        })
        .catch((error) => console.error(error))
    }

    this.getSSCSettings(this.homeSscDummy.sscId)
      .then((resp) => {
        const { resource } = resp

        if (resource && 'ShowHomeSSCListPrice' in resource) {
          const { ShowHomeSSCListPrice } = resource
          this.isSSCVisible = ShowHomeSSCListPrice
        }
      })
      .catch((error) => console.error(error))
  },
  data() {
    return {
      formData: {
        shape: '',
        material: '',
        con1Size: '',
        con1Type: '',
        con2Size: '',
        con2Type: '',
        con3Size: '',
        con3Type: '',
        con4Size: '',
        con4Type: '',
        includeOutOfStock: false,
        includePipeFittings: false,
      },
      partNumber: '',
      userSettingsBranch: '',
      isSSCVisible: false,
      allowedGroups: false,
      showNoResultByPart: false,
    }
  },
}
</script>
