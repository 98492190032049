<template>
  <div class="flex gap-2 items-center text-sm">
    <div class="h-3.5">
      {{ homeSscDummy.sscId }}
    </div>
    <div
      class="
        icon-swg-home
        text-brandYellow text-xl
        leading-none
        top-0.5
        relative
      "
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HomeSSC',
  computed: {
    ...mapGetters('inventory', ['homeSscDummy']),
  },
}
</script>
