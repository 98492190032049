import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', ['isWsSscAdmin', 'isAdmin', 'isWsAdmin']),
  },
  created() {
    if (!this.isWsSscAdmin && !this.isAdmin && !this.isWsAdmin) {
      this.setPath({ name: 'Home' })
    }
  },
}
