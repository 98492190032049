<template>
  <button @click="action">{{ value }}</button>
</template>

<script>
export default {
  name: 'InfoButton',
  props: ['value', 'action'],
  methods: {
    handleClick() {
      console.log('handleClick')
    },
  },
}
</script>
