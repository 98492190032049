<template>
  <div class="">
    <div class="flex justify-between py-2.5">
      <div class="font-bold text-headerColor w-[44%] whitespace-nowrap">
        {{ header.cellA }}
      </div>
      <div
        :class="[options && options.cellBClass ? options.cellBClass : '']"
        class="font-bold text-headerColor w-[34%] text-right"
      >
        {{ header.cellB }}
      </div>
      <div
        :class="[options && options.cellCSize ? options.cellCSize : 'w-[25%]']"
        class="font-bold text-headerColor text-right"
      >
        {{ header.cellC }}
      </div>
    </div>
    <hr class="-mx-2" />
    <transition-group name="transition-list" tag="div">
      <div
        class="transition-list-item"
        v-for="(item, idx) in items"
        @click="$emit('itemClicked', item)"
        :key="item[itemKey] + idx"
      >
        <div class="-mx-2 transition-colors hover:bg-gray-100 space-y-2 py-2">
          <div
            class="flex justify-between items-center px-2"
            :class="itemHasCursor ? 'cursor-pointer' : null"
          >
            <div class="w-[44%]">
              <div class="flex">
                <div
                  v-html="
                    options && options.lineBreak
                      ? item[body.cellA.title].replaceAll(', ', '<br />')
                      : item[body.cellA.title]
                  "
                  class="text-headerColor mr-1"
                  :class="{
                    'font-bold': !options.cellARegularFont,
                    'brake-word': options.brakeWord,
                    truncate: !!options.truncate,
                  }"
                ></div>
                <slot name="cellAIcon" v-if="item[body.cellA.hasIcon]"></slot>
              </div>
              <div class="truncate text-headerColor">
                {{ item[body.cellA.subtitle] }}
              </div>
            </div>
            <div
              :class="[
                options && options.textSize ? options.textSize : 'text-[22px]',
                options && options.cellBClass ? options.cellBClass : '',
              ]"
              class="text-headerColor w-[34%] text-right"
            >
              {{ item[body.cellB] }}
            </div>
            <div
              class="text-headerColor text-right pl-[1.3rem]"
              :class="[
                options && options.cellCSize ? options.cellCSize : 'w-[25%]',
                options && options.textSize ? options.textSize : 'text-[22px]',
                options && options.trafficLights === 'displayComponents'
                  ? 'flex justify-between items-center'
                  : options && options.trafficLights === 'color'
                  ? 'flex justify-center items-center'
                  : null,
              ]"
              v-html="
                options && options.trafficLights === 'displayComponents'
                  ? (item['displayComponents'].every((el) => el.available > 0)
                      ? green
                      : item['displayComponents'].some((el) => el.available > 0)
                      ? yellow
                      : red) + item[body.cellC]
                  : options && options.trafficLights === 'color'
                  ? (item[body.cellB] === 'green'
                      ? green
                      : item[body.cellB] === 'yellow'
                      ? yellow
                      : red) + item[body.cellC]
                  : item[body.cellC]
              "
            ></div>
            <div v-if="showUOM" class="absolute right-[-28px]">
              <b class="text-black">{{ item.inventoryUoMValue }}</b>
            </div>
          </div>
          <div
            v-if="footer && item[footer] && item[footer].length"
            class="px-2 font-medium text-sm"
          >
            <span>{{ item[footer] }}</span>
          </div>
        </div>
        <hr class="-mx-2" />
      </div>
    </transition-group>
  </div>
</template>
<script>
export default {
  name: 'Table3',
  props: [
    'header',
    'itemKey',
    'body',
    'footer',
    'items',
    'itemHasCursor',
    'options',
    'showUOM',
  ],
  computed: {
    green() {
      return `<span class='${this.trafficLight} bg-brandGreen'></span>`
    },
    yellow() {
      return `<span class='${this.trafficLight} bg-yellow-300'></span>`
    },
    red() {
      return `<span class='${this.trafficLight} bg-red-700'></span>`
    },
  },
  data() {
    return {
      trafficLight: 'w-[20px] h-[20px] inline-block rounded-full',
    }
  },
}
</script>
