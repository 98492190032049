<template>
  <Master>
    <div class="settings-info-page">
      <div class="container">
        <div class="settings-info-content mx-2">
          <ul>
            <li class="mb-4 text-headerColor">
              <p class="font-semibold">Favorite SSCs</p>
              <span
                >SSCs in this list will be priotitized in the first batch of
                inventory search results.</span
              >
            </li>
            <li class="mb-4 text-headerColor">
              <p class="font-semibold">Search SSCs in Batches Of</p>
              <span
                >Controls how many SSCs are queried simultaneously when
                performing an Inventory search. Default is 10.<br />This setting
                is a tradeoff between fewer clicks and better performance;
                smaller batch sizes will return fewer results at a time but more
                quickly.</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'

export default {
  name: 'SettingsInfo',
  mixins: [PageMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
}
</script>
