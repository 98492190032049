<template>
  <Master>
    <div class="inventory-pooling-details">
      <div class="container">
        <div class="">
          <PageHeading :title="branchName" />
          <div class="mt-1 text-strategy mb-2">{{ swagelokBranchName }}</div>
          <div class="flex">
            <div class="icon-swg-phone text-brandBlue text-xl mr-2"></div>
            <a
              class="text-brandGreen mb-1"
              :href="`tel:${partnerSSCData.phone.replace(/\D/g, '')}`"
              >{{ partnerSSCData.phone }}</a
            >
          </div>

          <div class="flex gap-2">
            <EnvelopeIcon class="w-6 h-6" />
            <a
              :href="`mailto:${partnerSSCData.email}`"
              class="text-brandGreen"
              >{{ partnerSSCData.email }}</a
            >
          </div>
        </div>
        <hr class="mt-4 -mx-2" />

        <table3
          :header="{ cellA: 'Part Number', cellB: 'Need', cellC: 'Available' }"
          :body="{
            cellA: {
              title: 'partNumber',
            },
            cellB: 'need',
            cellC: 'available',
          }"
          :items="items"
          :itemHasCursor="false"
          :trafficLightItems="['available']"
          :options="{ cellARegularFont: true }"
          itemKey="partNumber"
        />
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import InventoryMixin from '@/mixins/InventoryMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import { mapGetters, mapState } from 'vuex'
import EnvelopeIcon from '@/assets/icons/envelope.svg'

export default {
  components: { EnvelopeIcon },
  name: 'InventoryPoolingDetailsBranch',
  mixins: [
    PageMixin,
    SscSelectorGuardMixin,
    InventoryMixin,
    AuthorizedGuardMixin,
  ],
  computed: {
    ...mapGetters('inventory', ['getInventoryPooling']),
    ...mapState('inventory', ['inventoryPoolingData']),
  },
  created() {
    try {
      const { branchName, swagelokBranchName, partnerSSCData, branchData } =
        this.$route.params
      this.branchName = branchName
      this.swagelokBranchName = swagelokBranchName
      this.partnerSSCData = partnerSSCData
      this.branchData = branchData

      this.items = this.getInventoryPooling.partNumberList
        .filter((item) => {
          return !!item.inventory.find(
            (el) =>
              el.swagelokBranch === this.getInventoryPooling.selectedBranch &&
              !!item.partnerBranchesInventory.find(
                (el) => el.whoto === branchName,
              ),
          )
        })
        .map((item) => {
          const { partNumber } = item

          const partInfo = item.partnerBranchesInventory
            .find((el) => el.whoto === branchName)
            ?.entries.find((el) => el.partNumber === partNumber)
            ?.inventory.find((el) => el.swagelokBranch === swagelokBranchName)

          const { available, need } = partInfo

          return {
            partNumber,
            need,
            available,
          }
        })
        .filter((el) => {
          const { partNumber, available, need } = el

          const currentPartNumberInfo =
            this.inventoryPoolingData.partNumberList.find(
              (el) => el.partNumber === partNumber,
            )

          const { poolingValueThreshold, ListPriceUSD } = currentPartNumberInfo

          return (
            available >= need ||
            available * (ListPriceUSD ?? 0) >= poolingValueThreshold
          )
        })
    } catch (error) {
      console.log('error', error)
    }
  },
  data() {
    return {
      items: [],
    }
  },
}
</script>
