import { mapState, mapGetters } from 'vuex'
import settings from '@/settings'

export default {
  methods: {},
  computed: {
    ...mapGetters('inventory', [
      'partsWithSsc',
      'sscForPart',
      'metaForPart',
      'partsForHomeSsc',
      'homeSscDummy',
      'collectionHasAnyResult',
      'numberOfSearchIteration',
    ]),
    ...mapState('inventory', ['inventoryData']),
    maxPartsPerSsc() {
      return settings.inventory.maxPartsPerSsc
    },
  },
}
