<template>
  <Master>
    <div class="inventory-pooling-details">
      <div class="container">
        <div class="">
          <PageHeading :title="partNumber" />
          <div class="mt-1">{{ description }}</div>
          <div class="mt-2 text-strategy font-bold">
            {{ selectedFullNameBranch }}
          </div>
        </div>
        <hr class="mt-4 -mx-2" />
        <div class="flex py-2.5">
          <div class="text-headerColor flex items-center flex-grow flex-shrink">
            <span class="text-sm font-bold mr-2">PG</span>
            <span :class="headingSize">{{ pg }}</span>
          </div>
          <div class="text-headerColor flex items-center mr-4">
            <span class="text-sm font-bold mr-2">NQA</span>
            <span :class="headingSize">{{ nqa }}</span>
          </div>
          <div class="text-headerColor flex items-center">
            <span class="text-sm font-bold mr-2">Need</span>
            <span :class="headingSize">{{ need }}</span>
          </div>
        </div>
        <hr class="-mx-2" />
        <loader v-if="isLoading" />
        <InventoryTable2Columns
          :header="{ cellA: 'Local Branches', cellB: 'Available' }"
          :body="{
            cellA: {
              title: 'swagelokBranchName',
              subtitle: 'swagelokBranch',
            },
            cellB: 'available',
          }"
          :items="items"
          :itemHasCursor="false"
          :trafficLightItems="['available']"
          :options="{ cellARegularFont: false }"
          itemKey="swagelokBranch"
          v-if="items.length > 0"
        />
        <InventoryTable2Columns
          :header="{ cellA: 'Partner Branches', cellB: 'Available' }"
          :body="{
            cellA: {
              title: 'whoto',
              subtitle: 'branch',
            },
            cellB: 'available',
          }"
          :items="poolingPartnersItems"
          :itemHasCursor="false"
          :trafficLightItems="[]"
          :hideValue="[]"
          :options="{ cellARegularFont: false }"
          itemKey="branch"
          v-if="poolingPartnersItems.length > 0"
        />
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import InventoryMixin from '@/mixins/InventoryMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import InventoryTable2Columns from '@/components/inventory/InventoryTable2Columns'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'InventoryPoolingDetails',
  components: {
    InventoryTable2Columns,
  },
  methods: {
    ...mapActions('router', ['goPageBack', 'setPath']),
    ...mapActions('settings', ['getSSCSettings']),
    ...mapActions('inventory', ['getInventoryFromSSCs']),
  },
  mixins: [
    PageMixin,
    SscSelectorGuardMixin,
    InventoryMixin,
    AuthorizedGuardMixin,
    LoaderMixin,
  ],
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('inventory', ['inventoryPoolingData']),
    headingSize() {
      return this.need.toString().length > 3 || this.nqa.toString().length > 3
        ? 'text-[24px]'
        : 'text-[28px]'
    },
  },
  async created() {
    if (Object.keys(this.$route.params).length === 1) {
      this.goPageBack()
    }
    try {
      const {
        partNumber,
        selectedBranch,
        selectedFullNameBranch,
        description,
        pg,
        avail,
        nqa,
        need,
      } = this.$route.params
      this.partNumber = partNumber
      this.selectedBranch = selectedBranch
      this.selectedFullNameBranch = selectedFullNameBranch
      this.description = description
      this.pg = pg
      this.avail = avail
      this.nqa = nqa
      this.need = need
      this.items =
        this.inventoryPoolingData.partNumberList.find(
          (el) => el.partNumber === partNumber,
        )?.localBranchesInventory ?? 'N/A'

      const currentPartNumberInfo =
        this.inventoryPoolingData.partNumberList.find(
          (el) => el.partNumber === partNumber,
        )

      const { partnerBranchesInventory, poolingValueThreshold, ListPriceUSD } =
        currentPartNumberInfo

      partnerBranchesInventory.forEach((el) => {
        const { whoto, entries } = el

        entries.forEach((ele) => {
          ele.inventory.forEach((elem) => {
            elem.whoto = whoto
            elem.meetsThreshold =
              elem.available * (ListPriceUSD ?? 0) >= poolingValueThreshold
          })
        })
      })

      const poolingPartnersItems = partnerBranchesInventory
        .flatMap((el) => {
          return (
            el.entries.find((el) => el.partNumber === partNumber)?.inventory ??
            null
          )
        })
        .filter((el) =>
          this.showUnavailableFromPartner
            ? el
            : el.available >= el.need || el.meetsThreshold,
        )

      this.poolingPartnersItems = poolingPartnersItems
    } catch (error) {
      console.log('error', error)
    }
  },
  data() {
    return {
      showHeaderMenu: false,
      showUnavailableFromPartner: false,
      partMeta: null,
      partNumber: null,
      selectedBranch: null,
      description: null,
      pg: null,
      avail: null,
      nqa: null,
      need: null,
      items: [],
      poolingPartnersItems: [],
    }
  },
}
</script>
