<template>
  <Master>
    <loader v-if="loading" />
    <div class="inventory-pooling">
      <div class="container" v-if="!loading">
        <div class="flex justify-between items-baseline mb-2">
          <PageHeading :title="partNumber" />
        </div>
        <div class="mb-3">{{ item.description }}</div>

        <div class="font-bold text-sm mb-2">
          {{ item.requestingSSCBranch }}-{{ item.requestingSSC }}
        </div>

        <div class="mb-6">
          <hr />
          <div class="flex justify-between my-3">
            <div class="flex gap-3 items-center">
              <div class="font-bold text-headerColor">PG</div>
              <div class="text-[24px] text-headerColor">
                {{ planningGroup }}
              </div>
            </div>
            <div class="flex gap-4">
              <div class="col-span-3 flex gap-3 items-center">
                <div class="font-bold text-headerColor">NQA</div>
                <div class="text-[24px] text-headerColor">{{ needNQA }}</div>
              </div>
              <div class="col-span-2 flex gap-3 items-center justify-self-end">
                <div class="font-bold text-headerColor">Need</div>
                <div class="text-[24px] text-headerColor">
                  {{ item.requestedQuantity }}
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>

        <div class="mb-8">
          <h1 class="text-2xl text-headerColor font-bold mb-4">
            Selling Locations
          </h1>
          <hr />
          <div class="grid grid-cols-12 py-2">
            <div class="col-span-5 text-headerColor font-bold">Location</div>
            <div class="col-span-5 text-headerColor font-bold">
              Willing to Sell
            </div>
            <div class="font-bold text-headerColor col-span-2 justify-self-end">
              Bundle
            </div>
          </div>
          <hr />

          <div>
            <div
              class="border-b border-gray-500 border-opacity-30"
              v-for="(request, index) in item.requests"
              :key="`request-${index}`"
            >
              <div class="grid grid-cols-12 py-3">
                <div class="col-span-5 flex items-center gap-3">
                  <input
                    :id="`request-${index}`"
                    type="checkbox"
                    class="form-checkbox"
                    :checked="request.Status === 'Order'"
                    :model="request.Status"
                    @change="(event) => updateRequestStatus({ request, event })"
                  />

                  <label
                    :for="`request-${index}`"
                    v-if="request.SellingSSC !== 'SWGLK'"
                  >
                    {{ request.SellingBranch }}-{{ request.SellingSSC }}
                  </label>
                  <label
                    :for="`request-${index}`"
                    v-if="request.SellingSSC === 'SWGLK'"
                  >
                    {{ request.SellingSSC }}
                  </label>
                </div>
                <div
                  class="
                    col-span-3
                    text-[22px] text-headerColor
                    justify-self-end
                  "
                >
                  {{ request.ApprovedQuantity }}
                </div>
                <div class="col-span-4 pr-3 flex items-center justify-end">
                  {{ request.bundle }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-12">
          <button
            @click="
              () => {
                toggleBuyingLocations(item)
                goPageBack()
              }
            "
            class="button-a"
          >
            SELECT LOCATION(s)
          </button>
        </div>
        <div>
          <button
            @click="
              () => {
                removePartNumberFromRequest(item)
                goPageBack()
              }
            "
            class="link-red"
          >
            Remove This Part Number From Request
          </button>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import RadioBox from '@/components/common/RadioBox'
import InventoryPoolingBuyTable from '@/components/inventory/InventoryPoolingBuyTable'
import InventoryPoolingSellTable from '@/components/inventory/InventoryPoolingSellTable'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import InventoryMixin from '@/mixins/InventoryMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'InventoryPoolingBuyDetail',
  mixins: [
    PageMixin,
    LoaderMixin,
    SscSelectorGuardMixin,
    InventoryMixin,
    AuthorizedGuardMixin,
  ],
  components: {
    RadioBox,
    InventoryPoolingSellTable,
    InventoryPoolingBuyTable,
  },
  methods: {
    ...mapActions('inventory', [
      'confirmSellingRequest',
      'removePartNumberFromRequest',
      'toggleBuyingLocations',
    ]),
    ...mapActions('router', ['goPageBack']),
    updateRequestStatus({
      request,
      event: {
        target: { checked },
      },
    }) {
      request.Status = checked ? 'Order' : 'Approved'
    },
  },
  computed: {
    ...mapState('inventory', ['newInventoryPoolingData']),
    planningGroup() {
      return this.item.inventory.find(
        (i) => i.swagelokBranch === this.activeBranch,
      )?.planningGroup
    },
    needNQA() {
      return this.item.inventory.find(
        (i) => i.swagelokBranch === this.activeBranch,
      )?.needNQA
    },
  },
  async created() {
    if (Object.keys(this.$route.params).length === 1) {
      this.goPageBack()
    }

    try {
      const {
        partNumber,
        selectedBranch,
        selectedFullNameBranch,
        item,
        activeBranch,
      } = this.$route.params

      this.selectedBranch = selectedBranch
      this.activeBranch = activeBranch
      this.selectedFullNameBranch = selectedFullNameBranch
      this.item = JSON.parse(JSON.stringify(item))
      this.item.requests = this.item.requests.sort((a, b) =>
        b.requestingSSC === 'SWGLK' ? -1 : 1,
      )
      this.partNumber = partNumber

      this.item.requests.forEach((request) => {
        request.buy = request.Status === 'Order'

        request.bundle =
          request.SellingSSC === 'SWGLK'
            ? 'No'
            : this.newInventoryPoolingData.buyingRequests.find((i) =>
                i.requests.find(
                  (req) =>
                    req.SellingSSC === request.SellingSSC &&
                    req.SellingBranch === request.SellingBranch &&
                    req.PartNumber !== request.PartNumber &&
                    req.Status === 'Order',
                ),
              )
            ? 'Yes'
            : 'No'
      })
      this.$forceUpdate()
    } catch (error) {
      console.log('error', error)
    }
  },
  data() {
    return {
      loading: false,
      selectedBranch: null,
      selectedFullNameBranch: null,
      item: null,
      partNumber: null,
      activeBranch: null,
    }
  },
}
</script>
