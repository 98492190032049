import eventBus from '@/services/event-bus'
import settings from '@/settings'
import axios from 'axios'

export const fetcher = (url) =>
  axios({
    withCredentials: true,
    timeout: 60000,
    method: 'get',
    url: `${settings.services.swagelokApi.baseUrl}${url}`,
  })
    .then((res) => {
      console.log(
        '%cGET',
        'background-color: yellow; font-weight: bold; padding: 1px',
        url,
        res.data,
      )

      return res.data
    })
    .catch((error) => {
      if (_isForbidden(error)) {
        eventBus.$emit('auth.notAuthorized')
      } else {
        throw error
      }
    })

const SwagelokApiService = function () {
  const defaultOptions = {
    withCredentials: true,
    timeout: 60000,
  }

  const GET = (url, params) =>
    call({
      url: `${settings.services.swagelokApi.baseUrl}${url}`,
      method: 'get',
      params,
    })

  const POST = (url, data, params) =>
    call({
      url: `${settings.services.swagelokApi.baseUrl}${url}`,
      method: 'post',
      params,
      data,
    })

  const PUT = (url, data, params) =>
    call({
      url: `${settings.services.swagelokApi.baseUrl}${url}`,
      method: 'put',
      params,
      data,
    })

  const _isForbidden = (error) => error?.response?.status === 403

  const call = function (options) {
    return axios({ ...defaultOptions, ...options })
      .then((res) => res.data)
      .catch((error) => {
        if (_isForbidden(error)) {
          eventBus.$emit('auth.notAuthorized')
        } else {
          throw error
        }
      })
  }

  const user = {
    login(providerId) {
      const providers = {
        aad: settings.services.swagelokApi.baseUrl + '/aad/login',
      }
      window.location.href = providers[providerId]
    },
    logout() {
      return call({
        url: settings.services.swagelokApi.baseUrl + '/user/logout',
        method: 'post',
      })
    },
    details() {
      return call({
        url: settings.services.swagelokApi.baseUrl + '/user',
        method: 'get',
      })
    },
    setSsc(sscUid) {
      return call({
        url: settings.services.swagelokApi.baseUrl + '/user/ssc',
        method: 'patch',
        data: {
          uid: sscUid,
        },
      })
    },
  }

  const inventory = {
    cleanNotification({ id, type }) {
      return call({
        url:
          settings.services.swagelokApi.baseUrl +
          '/inventory/clean-notification',
        method: 'post',
        data: {
          id,
          type,
        },
      })
    },
    search({ partNumber, from, to, ssc, operator, maxParts }) {
      return call({
        url: settings.services.swagelokApi.baseUrl + '/inventory/ssc',
        method: 'get',
        params: {
          operator,
          partNumber,
          from,
          to,
          ssc,
          maxParts,
        },
      })
    },
    confirmSellingStatus(data) {
      return call({
        url:
          settings.services.swagelokApi.baseUrl +
          `/inventory/ssc/confirm-selling-status`,
        method: 'post',
        data,
      })
    },
    getBranchesByHomeSSC() {
      return call({
        url:
          settings.services.swagelokApi.baseUrl + `/inventory/ssc/branches-new`,
        method: 'get',
      })
    },
    updateRequests(data) {
      return call({
        url:
          settings.services.swagelokApi.baseUrl +
          `/inventory/ssc/update-requests`,
        method: 'post',
        data,
      })
    },
    toggleBuyingLocations(data) {
      return call({
        url:
          settings.services.swagelokApi.baseUrl +
          `/inventory/ssc/update-requests`,
        method: 'post',
        data,
      })
    },
    removePartNumberFromRequest(data) {
      return call({
        url:
          settings.services.swagelokApi.baseUrl +
          `/inventory/ssc/remove-part-number-from-request`,
        method: 'post',
        data,
      })
    },
    getBySSCs(data) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/inventory/sscs`,
        method: 'post',
        data: {
          ...data,
        },
      })
    },
    addToCart(data) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/inventory/add-to-cart`,
        method: 'post',
        data,
      })
    },
    submitRequest(data) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/inventory/order-request`,
        method: 'post',
        data,
      })
    },
    getCardUrl(data) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/inventory/get-cart-url`,
        method: 'post',
        data,
      })
    },
    corporate(partNumber) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/inventory/corporate/${partNumber}`,
        method: 'get',
      })
    },
  }

  const userSettings = {
    searchSSCs(sscValue) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/search-ssc`,
        method: 'get',
      })
    },
    getFavoriteSSCsByUser() {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/favorite`,
        method: 'get',
      })
    },
    handleUpdateFavoriteList(items) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/favorite`,
        method: 'put',
        data: {
          items,
        },
      })
    },
    getBatchSize() {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/batch-size`,
        method: 'get',
      })
    },
    getUserSettings() {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/user`,
        method: 'get',
      })
    },
    handleUpdateBatchSize(batchSize) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/batch-size/${batchSize}`,
        method: 'put',
      })
    },
    getSwagelokBranch() {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/branch`,
        method: 'get',
      })
    },
    handleSwagelokBranch(swagelokBranch) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/branch/${swagelokBranch}`,
        method: 'put',
      })
    },
    updateSingleSetting(name, value, updateMethod, options) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/update`,
        method: 'patch',
        data: {
          name,
          value,
          updateMethod,
          options,
        },
      })
    },
    updateSSCSettings({ id, operations }) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/updateSSCSettings/${id}`,
        method: 'patch',
        data: { operations },
      })
    },
    setSSC2SSCPoliciesReadDate() {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/ssc-sourcing`,
        method: 'patch',
      })
    },
    getSSC2SSCPoliciesReadDate() {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/ssc-sourcing`,
        method: 'get',
      })
    },
    sendEmail({ fromName, fromEmail, to, replyTo, cc, subject, text, html }) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/email/send`,
        method: 'post',
        data: { fromName, fromEmail, to, replyTo, cc, subject, text, html },
      })
    },
  }

  const SSCSettings = {
    get(whoToCode) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/settings/ssc/${whoToCode}`,
        method: 'get',
      })
    },
  }

  const SSCSourcing = {
    searchBySSC() {
      return call({
        url: settings.services.swagelokApi.baseUrl + '/sourcing/ssc',
        method: 'get',
      })
    },

    capabilities() {
      return call({
        url: settings.services.swagelokApi.baseUrl + '/sourcing/capabilities',
        method: 'get',
      })
    },

    capabilitiesInfo() {
      return call({
        url:
          settings.services.swagelokApi.baseUrl + '/sourcing/capabilities/info',
        method: 'get',
      })
    },

    send({ idx, Contact, Capacity, Note, FunctionCode, CapCode }) {
      return call({
        url: settings.services.swagelokApi.baseUrl + '/sourcing/capabilities',
        method: 'put',
        data: { idx, Contact, Capacity, Note, FunctionCode, CapCode },
      })
    },

    getResults(data) {
      return call({
        url:
          settings.services.swagelokApi.baseUrl + '/sourcing/capabilities/ssc',
        method: 'post',
        data,
      })
    },

    update(data) {
      return call({
        url: settings.services.swagelokApi.baseUrl + '/sourcing/update',
        method: 'patch',
        data,
      })
    },
  }

  const announcements = {
    search() {
      return call({
        url: settings.services.swagelokApi.baseUrl + '/announcements',
        method: 'get',
      })
    },

    searchByID(id) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/announcements/${id}`,
        method: 'get',
      })
    },

    searchByDate(date) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/announcements/active/${date}`,
        method: 'get',
      })
    },

    create(formFields) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/announcements`,
        method: 'POST',
        data: {
          ...formFields,
        },
      })
    },

    update(data) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/announcements`,
        method: 'PUT',
        data: {
          ...data,
        },
      })
    },

    delete(id) {
      return call({
        url: `${settings.services.swagelokApi.baseUrl}/announcements`,
        method: 'delete',
        data: {
          id,
        },
      })
    },
  }

  const feedback = {
    send({ subject, message }) {
      return call({
        url: settings.services.swagelokApi.baseUrl + '/feedback',
        method: 'post',
        data: {
          subject,
          message,
        },
      })
    },
  }

  const ivaOrdering = {
    initSession({ buyingSSC, ivaUserId, ivaUserName }) {
      return call({
        url:
          settings.services.iva.baseUrl +
          '/rest/v2/swagelok/iva/session?page=CART',
        method: 'post',
        data: { buyingSSC, ivaUserId, ivaUserName },
      })
    },
    addToCart({
      bpCode,
      buyingSSC,
      ivaUserId,
      maxQuantity,
      partNumber,
      price,
      quantity,
      sellingSSC,
      currency
    }) {
      return call({
        url: settings.services.iva.baseUrl + '/rest/v2/swagelok/iva/addProduct',
        method: 'post',
        data: {
          bpCode,
          buyingSSC,
          ivaUserId,
          maxQuantity,
          partNumber,
          price,
          quantity,
          currency,
          sellingSSC,
        },
      })
    },
  }

  const sap = {
    ssc: {
      list() {
        return call({
          url: settings.services.swagelokApi.baseUrl + '/sap/ssc',
          method: 'get',
        })
      },
    },
    branch: {
      list() {
        return call({
          url: settings.services.swagelokApi.baseUrl + '/configurator/branches',
          method: 'get',
        })
      },
    },
  }

  const configurator = {
    attributes: {
      list() {
        return call({
          url:
            settings.services.swagelokApi.baseUrl + '/configurator/attributes',
          method: 'get',
        })
      },
      dynamicList(dynamicAttributes) {
        const params = {}

        // TODO: Remove this after testing
        // Object.entries(dynamicAttributes)
        //   .filter(([, value]) => value)
        //   .forEach(([key, value]) => {
        //     params[key] = value
        //   })

        return call({
          url:
            settings.services.swagelokApi.baseUrl +
            '/configurator/dynamic-attributes',
          method: 'get',
          params,
        })
      },
    },
    search: {
      list({
        shape,
        material,
        con1Size,
        con1Type,
        con2Size,
        con2Type,
        con3Size,
        con3Type,
        con4Size,
        con4Type,
        includeOutOfStock,
        includePipeFittings,
        sscUid,
        ssc,
      }) {
        return call({
          url:
            settings.services.swagelokApi.baseUrl +
            '/configurator/configurations',
          method: 'get',
          params: {
            shape,
            material,
            con1Size,
            con1Type,
            con2Size,
            con2Type,
            con3Size,
            con3Type,
            con4Size,
            con4Type,
            includeOutOfStock,
            includePipeFittings,
            sscUid,
            ssc,
          },
        })
      },
      byPartNumber(partNumber) {
        return call({
          url: `${settings.services.swagelokApi.baseUrl}/configurator/search/${partNumber}`,
          method: 'get',
        })
      },
    },
  }

  return {
    call,
    user,
    inventory,
    feedback,
    sap,
    configurator,
    announcements,
    userSettings,
    SSCSettings,
    SSCSourcing,
    GET,
    POST,
    PUT
  }
}

export default SwagelokApiService()
