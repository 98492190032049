<template>
  <Master>
    <div class="announcements" v-if="title.length">
      <div class="container">
        <div class="bg-brandGreen text-white p-4 mb-4" v-if="newAnnouncement">
          The announcement was created successfully
        </div>
        <div
          class="bg-brandGreen text-white p-4 mb-4"
          v-if="editedAnnouncement"
        >
          The announcement was updated successfully
        </div>
        <div class="flex justify-between items-baseline mb-3 ml-2">
          <PageHeading :title="title" />
          <button
            v-if="
              this.userData.groups.some((group) =>
                ['wsAdmin', 'admin'].includes(group),
              )
            "
            @click="handleClick(id)"
            class="edit-announcement-button"
          ></button>
        </div>
        <p class="ml-2 -mt-2 mb-4 text-secondaryGrayer">
          {{
            startDate
              ? new Date(startDate).toLocaleString(
                  navigator.language && 'default',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  },
                )
              : ''
          }}
        </p>
        <p class="ml-2 mt-5 announcement-body" v-html="content"></p>
        <div class="announcement-form-actions flex gap-2.5 mt-10 ml-2">
          <div class="flex-1">
            <button
              class="button-a border-solid border-2 border-brandGreen"
              @click="handleAcceptAnnouncement"
              :disabled="isAccepted"
            >
              GOT IT
            </button>
          </div>
          <div class="flex-1">
            <button class="button-b" @click="handleFeedbackClick">
              FEEDBACK
            </button>
          </div>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import AuthenticatedGuardMixin from '@/mixins/AuthenticatedGuardMixin'
import { mapState, mapGetters, mapActions } from 'vuex'
import {
  setAnnouncementsCookie,
  getAnnouncementsCookie,
  announcementsCookieExists,
} from '@/helpers/utils.js'

export default {
  name: 'AnnouncementsDetails',
  mixins: [PageMixin, AuthenticatedGuardMixin],
  props: ['id', 'newAnnouncement', 'editedAnnouncement'],
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('user', ['isGuest', 'isSsc', 'isAuthorized']),
  },
  methods: {
    ...mapActions('announcements', ['getAnnouncements', 'getAnnouncementById']),
    ...mapActions('settings', ['updateSingleSetting', 'getUserSettings']),
    handleClick(id) {
      this.setPath({
        name: 'AnnouncementsEdit',
        params: {
          id,
        },
      })
    },
    handleFeedbackClick() {
      this.handleAcceptAnnouncement('Feedback')
    },
    withFallback(cb) {
      return cb().catch((e) => {
        console.log(e)
        this.setPath({
          name: 'ServiceUnavailable',
          params: {
            retryAction: () => {
              this.setPath({ name: 'SscSelector' })
            },
          },
        })
      })
    },
    handleAcceptAnnouncement(param) {
      const { id } = this

      this.withFallback(() => {
        return this.updateSingleSetting({
          name: 'ReadAnnouncements',
          value: { id },
          updateMethod: 'add',
          options: {
            incrementArray: true,
          },
        }).then((resp) => {
          const { ReadAnnouncements } = resp

          if (
            ReadAnnouncements &&
            ReadAnnouncements.findIndex((el) => el.id === this.id) > -1
          ) {
            this.isAccepted = true
          }
        })
      })

      if (typeof param === 'string') {
        this.setPath({ name: param })
      } else {
        setTimeout(() => {
          this.$router.back(1)
        }, 300)
      }
    },
    async checkIfAccepted() {
      const userSettings = await this.getUserSettings()
      const { ReadAnnouncements } = userSettings

      if (
        ReadAnnouncements &&
        ReadAnnouncements.findIndex((el) => el.id === this.id) > -1
      ) {
        this.isAccepted = true
      }
    },
  },
  async created() {
    await this.getAnnouncementById(this.id).then((annoumcement) => {
      if (!annoumcement) {
        this.setPath({
          name: 'ServiceUnavailable',
          params: {
            retryAction: () => {
              this.setPath({
                name: 'AnnouncementsDetails',
                params: { id: this.id },
              })
            },
          },
        })
      }

      const { title, body, startDate } = annoumcement

      if (
        annoumcement.startDate <= new Date().toISOString() ||
        this.isAuthorized
      ) {
        this.title = title
        this.content = JSON.parse(body)
        this.startDate = startDate
      } else {
        this.setPath({
          name: 'Announcements',
        })
      }
    })

    this.checkIfAccepted()
  },
  data() {
    return {
      title: '',
      body: '',
      startDate: '',
      content: '',
      isAccepted: false,
      showHeaderMenu: true,
    }
  },
}
</script>
