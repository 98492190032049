<template>
  <transition name="transition-fade">
    <div class="loader top-0 absolute min-h-16">
      <div class="loader-overlay"></div>
      <div v-if="variant === 'ring'" class="lds-dual-ring" />
      <div v-else class="swg-loader" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Loader',
  props: ['variant'],
}
</script>
