var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Master',[_c('div',{staticClass:"inventory-pooling-details"},[_c('div',{staticClass:"container"},[_c('div',{},[_c('PageHeading',{attrs:{"title":_vm.partNumber}}),_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.description))]),_c('div',{staticClass:"mt-2 text-strategy font-bold"},[_vm._v(" "+_vm._s(_vm.selectedFullNameBranch)+" ")])],1),_c('hr',{staticClass:"mt-4 -mx-2"}),_c('div',{staticClass:"flex py-2.5"},[_c('div',{staticClass:"text-headerColor flex items-center flex-grow flex-shrink"},[_c('span',{staticClass:"text-sm font-bold mr-2"},[_vm._v("PG")]),_c('span',{class:_vm.headingSize},[_vm._v(_vm._s(_vm.pg))])]),_c('div',{staticClass:"text-headerColor flex items-center mr-4"},[_c('span',{staticClass:"text-sm font-bold mr-2"},[_vm._v("NQA")]),_c('span',{class:_vm.headingSize},[_vm._v(_vm._s(_vm.nqa))])]),_c('div',{staticClass:"text-headerColor flex items-center"},[_c('span',{staticClass:"text-sm font-bold mr-2"},[_vm._v("Need")]),_c('span',{class:_vm.headingSize},[_vm._v(_vm._s(_vm.need))])])]),_c('hr',{staticClass:"-mx-2"}),(_vm.isLoading)?_c('loader'):_vm._e(),(_vm.items.length > 0)?_c('InventoryTable2Columns',{attrs:{"header":{ cellA: 'Local Branches', cellB: 'Available' },"body":{
          cellA: {
            title: 'swagelokBranchName',
            subtitle: 'swagelokBranch',
          },
          cellB: 'available',
        },"items":_vm.items,"itemHasCursor":false,"trafficLightItems":['available'],"options":{ cellARegularFont: false },"itemKey":"swagelokBranch"}}):_vm._e(),(_vm.poolingPartnersItems.length > 0)?_c('InventoryTable2Columns',{attrs:{"header":{ cellA: 'Partner Branches', cellB: 'Available' },"body":{
          cellA: {
            title: 'whoto',
            subtitle: 'branch',
          },
          cellB: 'available',
        },"items":_vm.poolingPartnersItems,"itemHasCursor":false,"trafficLightItems":[],"hideValue":[],"options":{ cellARegularFont: false },"itemKey":"branch"}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }