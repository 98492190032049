<template>
  <div
    id="inv-search-inventory-results"
    :data-inv-met-criteria-home="
      !!(sscData && sscData.length && sscData[0].totalPartsFound) && ''
    "
    :data-inv-met-criteria-peer="
      !!(sscData && sscData.length > 1 && sscData[1].totalPartsFound) && ''
    "
    :data-inv-searched-full-network="isSearchComplete && !isLoading && ''"
  >
    <div v-if="sscData && !isLoading">
      <template v-if="partMeta">
        <hr class="-mx-2" />
        <div class="mt-3">
          <PageHeading :title="partMeta.partNumber" />
          <div class="mt-1">{{ partMeta.partDescription }}</div>
          <div class="flex justify-between mt-2 mb-4">
            <div
              id="inv-fulfillment-strategy-code"
              class="text-strategy font-bold"
            >
              {{ partMeta.strategy }}
            </div>
            <SvgQuestionCircle
              class="
                text-brandLightBlue
                hover:text-blue-700
                w-5
                h-5
                fill-current
                cursor-pointer
              "
              @click="showHelpReserve"
            />
          </div>
          <div
            v-if="isIVAOrderingFunctionalityEnabled"
            class="italic text-brandLightBlue text-sm mb-2"
          >
            Select a location to order product
          </div>

          <div class="italic text-brandLightBlue text-sm">
            Reserved qty indicated by a plus (+) sign
          </div>
        </div>
      </template>
      <template v-if="sscData.length">
        <hr class="-mx-2 mt-2" />
        <table3
          v-if="showUOM"
          :header="{
            cellA: 'Location',
            cellB: 'Available',
            cellC: '',
          }"
          :body="{
            cellA: {
              title: 'sscId',
              subtitle: 'sscName',
              hasIcon: 'isHome',
            },
            cellB: 'totalAvailable',
            cellC: 'inventoryUoMValue',
          }"
          :options="{
            cellCSize: 'w-[32px] !p-0 flex-none',
            cellBClass: '!w-full pr-2',
          }"
          :items="items"
          :itemHasCursor="true"
          itemKey="sscId"
          @itemClicked="(item) => showDetails(item)"
        >
          <template v-slot:cellAIcon>
            <div
              class="
                icon-swg-home
                text-brandYellow text-xl
                leading-none
                top-0.5
                relative
              "
            />
          </template>
        </table3>
        <Table2
          v-else
          :header="{
            cellA: 'Location',
            cellB: 'Available',
            cellC: 'Reserved',
          }"
          :body="{
            cellA: {
              title: 'sscId',
              subtitle: 'sscName',
              hasIcon: 'isHome',
            },
            cellB: 'totalAvailable',
          }"
          :options="{
            showReadyNow: this.isIVAOrderingFunctionalityEnabled,
          }"
          :withPlusSign="true"
          :items="items"
          :itemHasCursor="true"
          itemKey="sscId"
          @itemClicked="(item) => showDetails(item)"
        >
          <template v-slot:cellAIcon>
            <div
              class="
                icon-swg-home
                text-brandYellow text-xl
                leading-none
                top-0.5
                relative
              "
            />
          </template>
        </Table2>
      </template>

      <div class="" v-if="showNoResult && isSearchComplete">
        <hr class="-mx-2 mt-4" />
        <div class="mt-4">Part not found.</div>
        <hr class="-mx-2 mt-4" />
      </div>

      <div
        v-if="!isSearchComplete && !isLoading"
        id="inv-search-more-sscs"
        class="link-a mt-4 hover:text-brandGreen-darker font-semibold"
        @click="$emit('searchMoreClicked')"
      >
        {{ 'Search More Locations' }} <span class="icon-swg-arrow-right"></span>
      </div>
      <div
        class="flex items-center mt-4"
        v-else-if="isSearchComplete && !isLoading"
      >
        <SvgExclamationTriangle
          class="text-brandYellow w-[22px] h-[22px] fill-current"
        />
        <div class="flex justify-center items-center ml-4">
          The full network has been searched.
        </div>
      </div>
    </div>
    <loader v-if="isLoading" />
  </div>
</template>

<script>
import SvgExclamationTriangle from '@/assets/icons/exclamation-triangle.svg'
import SvgQuestionCircle from '@/assets/icons/question-circle-solid.svg'
import InventoryMixin from '@/mixins/InventoryMixin'
import { mapActions, mapGetters } from 'vuex'
import Table2 from '../common/Table2.vue'

export default {
  name: 'InventoryResultEquals',
  mixins: [InventoryMixin],
  components: {
    SvgQuestionCircle,
    SvgExclamationTriangle,
    Table2,
  },
  props: ['isLoading', 'partNumber', 'minQty'],
  methods: {
    ...mapActions('router', ['setPath']),
    showDetails(ssc) {
      this.setPath({
        name: 'InventorySscDetails',
        params: {
          partNumber: this.partMeta.partNumber,
          sscId: ssc.sscId,
          minQty: this.minQty,
        },
        routeMap: {
          goBackState: {
            name: 'Inventory',
          },
        },
      })
    },
    showHelpReserve() {
      this.setPath({
        name: 'InventoryHelpReserve',
        routeMap: {
          goBackState: {
            name: 'Inventory',
          },
        },
      })
    },
    getPartMeta() {
      return this.metaForPart(this.partNumber)
    },
    getSscData() {
      const sscList = this.sscForPart(this.partNumber)

      return sscList.filter(
        (ssc) =>
          ssc.totalPartsFound &&
          (ssc.ivaOptOutFlag === 'N' ||
            ssc.ivaOptOutFlag === undefined ||
            ssc.isHome === true),
      )
    },
  },
  computed: {
    ...mapGetters('settings', ['isIVAOrderingFunctionalityEnabled']),
    items() {
      return this.sscData.map((item) => {
        const readyNow = item.inventory.some((inv) => inv.readyNow)

        let totalAvailable = `${item.totalAvailable}${
          !readyNow &&
          item.sscId !== 'SWGLK' &&
          parseInt(item.totalReserved) > 0
            ? `+`
            : ''
        }`

        return {
          ...item,
          readyNow,
          totalAvailable: totalAvailable,
        }
      })
    },
    showNoResult() {
      return !this.isLoading && !this.sscData?.length
    },
    isSearchComplete() {
      if (this.sscData) {
        this.sscData.forEach((ssc) => {
          ssc.totalAvailable = ssc.totalAvailable.toLocaleString(
            navigator.language ?? 'default',
          )
          ssc.totalReserved =
            ssc.sscId === 'SWGLK'
              ? '&mdash;'
              : ssc.totalReserved.toLocaleString(
                  navigator.language ?? 'default',
                )
        })
      }
      return this.inventoryData.isComplete
    },
  },
  created() {
    if (!this.isLoading) {
      this.sscData = this.getSscData()
      this.partMeta = this.getPartMeta()
      this.showUOM = this.sscData.some(
        (el) => el.inventoryUoMValue !== undefined,
      )

      if (this.showNoResult && !this.isSearchComplete) {
        this.$emit('searchMoreClicked')
      }
    }
    this.$watch('isLoading', (nv) => {
      if (nv === false) {
        this.sscData = this.getSscData()
        this.partMeta = this.getPartMeta()
        this.showUOM = this.sscData.some(
          (el) => el.inventoryUoMValue !== undefined,
        )

        if (this.showNoResult && !this.isSearchComplete) {
          this.$emit('searchMoreClicked')
        }
      }
    })
  },
  data() {
    return {
      sscData: null,
      partMeta: null,
      showUOM: false,
    }
  },
}
</script>
