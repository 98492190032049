<template>
  <div
    v-if="(isInOrderingGroup && isIVAOrderingFunctionalityEnabled) || isAdmin"
    class="flex justify-between mb-6"
  >
    <button
      @click="openOrderHistory"
      class="font-semibold text-sm text-secondaryGrayer flex items-center gap-2"
      v-if="showOrderHistory"
    >
      <OrderHistoryIcon class="w-[24px] h-[24px]" />
      Order History
    </button>
    <span></span>
    <button
      @click="openCart"
      class="text-brandBlue font-semibold text-sm flex items-center gap-2"
    >
      <CartIcon class="w-[24px] h-[24px]" />
      View Cart
    </button>
  </div>
</template>
<script>
import CartIcon from '@/assets/icons/cart.svg'
import OrderHistoryIcon from '@/assets/icons/order-history.svg'
import PageMixin from '@/mixins/PageMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'InventoryHeader',
  mixins: [PageMixin],
  methods: {
    ...mapActions('cart', ['openCart', 'openOrderHistory']),
  },
  computed: {
    ...mapGetters('user', ['isInOrderingGroup', 'isAdmin']),
    ...mapGetters('settings', ['isIVAOrderingFunctionalityEnabled']),
  },
  components: {
    CartIcon,
    OrderHistoryIcon,
  },
  props: {
    showOrderHistory: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
