<template>
  <Master>
    <div class="announcements">
      <div class="container">
        <div class="flex justify-between items-baseline mb-4 ml-2">
          <PageHeading title="Announcements" />
          <span
            v-if="
              this.userData.groups.some((group) =>
                ['wsAdmin', 'admin'].includes(group),
              )
            "
            class="
              text-brandBlue text-[38px]
              hover:text-brandBlue-dark
              absolute
              top-[6px]
              right-[26px]
              cursor-pointer
            "
            @click="
              setPath({
                name: 'AnnouncementsAdd',
                routeMap: { goBackState: { name: 'Announcements' } },
              })
            "
            >+</span
          >
        </div>

        <div class="announcements-list mx-2">
          <div
            class="bg-secondaryRed text-white p-4 mb-4"
            v-if="action == 'delete'"
          >
            The announcement was deleted successfully
          </div>

          <div
            class="
              flex
              items-center
              mt-4
              border-t border-brandGray border-solid
            "
            v-show="!announcementData.length && !loading"
          >
            <SvgExclamationTriangle
              class="text-brandYellow w-[22px] h-[22px] fill-current mt-2"
            />
            <div class="flex justify-center items-center ml-4 pt-4">
              There are no announcements at this time.<br />Check back later for
              updates!
            </div>
          </div>

          <table
            class="table-fixed text-headerColor w-full"
            v-if="announcementData.length"
          >
            <thead>
              <tr class="border-solid border-secondaryGray border-b border-t">
                <th class="w-4/5 text-left py-2">Title</th>
                <th class="w-1/5 text-right py-2">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in announcementData"
                :key="item.id"
                :class="{
                  'text-secondaryRed':
                    item.startDate > new Date().toISOString(),
                }"
                class="
                  border-solid border-secondaryGray border-b
                  cursor-pointer
                  h-16
                  leading-5
                "
                @click="handleClick(item.id)"
              >
                <td class="py-3 pr-5">{{ item.title }}</td>
                <td class="text-right">
                  {{
                    new Date(item.startDate).toLocaleString(
                      navigator.language && 'default',
                      {
                        timeZone: 'UTC',
                        day: 'numeric',
                        month: 'numeric',
                        year: '2-digit',
                      },
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import { mapActions } from 'vuex'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import { mapState, mapGetters } from 'vuex'
import SvgExclamationTriangle from '@/assets/icons/exclamation-triangle.svg'
import { DateTime } from 'luxon'

export default {
  name: 'Announcements',
  mixins: [PageMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  props: ['action'],
  components: {
    SvgExclamationTriangle,
  },
  methods: {
    ...mapActions('announcements', ['getAnnouncements']),
    ...mapActions('router', ['setPath']),
    handleClick(id) {
      this.setPath({
        name: 'AnnouncementsDetails',
        params: {
          id,
        },
      })
    },
  },
  data() {
    return {
      loading: true,
      announcementData: [],
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('user', ['isGuest', 'isSsc', 'isAuthorized']),
  },
  async created() {
    await this.getAnnouncements().then((data) => {
      if (
        this.userData.groups.some((group) =>
          ['wsAdmin', 'admin'].includes(group),
        )
      ) {
        this.announcementData = data
      } else {
        this.announcementData = data.filter((announcement) => {
          return announcement.startDate <= DateTime.utc().toISO()
        })
      }

      this.loading = false
    })
  },
}
</script>
