import SwagelokApiService from '@/services/swagelok-api'

const initialState = {
  announcementData: null,
}

const mutations = {
  SET_ANNOUNCEMENT_DATA(state, data) {
    state.announcementData = data
  },
}

const actions = {
  async getAnnouncements() {
    return await SwagelokApiService.announcements.search()
  },

  async getAnnouncementById({ commit }, id) {
    return await SwagelokApiService.announcements.searchByID(id)
  },

  async getActiveAnnouncements({ commit }, date) {
    return await SwagelokApiService.announcements.searchByDate(date)
  },

  async createAnnouncement({ commit }, data) {
    return await SwagelokApiService.announcements.create(data)
  },

  async updateAnnouncement({ commit }, data) {
    return await SwagelokApiService.announcements.update(data)
  },

  async deleteAnnouncement({ commit }, id) {
    return await SwagelokApiService.announcements.delete(id)
  },
}

export default {
  state: initialState,
  mutations,
  actions,
  namespaced: true,
}
