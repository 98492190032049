import SwagelokApiService from '@/services/swagelok-api'
import * as dayjs from 'dayjs'

const initialState = {
  cardURL: null,
  cardURLCreatedAt: null,
  orderHistoryURL: null,
  orderHistoryURLCreatedAt: null,
}

const mutations = {
  SET_CARD_URL(state, cardURL) {
    state.cardURL = cardURL
    state.cardURLCreatedAt = dayjs()
  },
  SET_ORDER_HISTORY_URL(state, url) {
    state.orderHistoryURL = url
    state.orderHistoryURLCreatedAt = dayjs()
  },
}

const actions = {
  async addToCart(
    {},
    {
      buyingSSC,
      maxQuantity,
      partNumber,
      price,
      quantity,
      sellingSSC,
      bpCode,
      currency,
      // requestMessage,
    },
  ) {
    await SwagelokApiService.POST('/sap/add-to-cart', {
      bpCode,
      buyingSSC,
      maxQuantity,
      note: '',
      currency,
      // note: requestMessage,
      partNumber,
      price,
      quantity,
      sellingSSC,
    })
  },
  async openCart({ commit, state }) {
    if (
      state.cardURLCreatedAt &&
      state.cardURLCreatedAt.isAfter(dayjs().subtract(15, 'minutes'))
    ) {
      window.open(state.cardURL, '_blank')
      return
    }

    const { redirectUrl } = await SwagelokApiService.GET('/sap/cart-url')
    commit('SET_CARD_URL', redirectUrl)
    window.open(redirectUrl, '_blank')
  },
  async openOrderHistory({ commit, state }) {
    if (
      state.orderHistoryURL &&
      state.orderHistoryURLCreatedAt.isAfter(dayjs().subtract(15, 'minutes'))
    ) {
      window.open(state.orderHistoryURL, '_blank')
      return
    }

    const { redirectUrl } = await SwagelokApiService.GET(
      '/sap/order-history-url',
    )
    commit('SET_ORDER_HISTORY_URL', redirectUrl)
    window.open(redirectUrl, '_blank')
  },
}

const getters = {}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
  namespaced: true,
}
