<template>
  <Master>
    <div class="about">
      <div class="container">
        <div class="flex justify-between items-baseline mb-4">
          <PageHeading title="Sales Enablement Tool" />
        </div>
        <div>Version: {{ version }}</div>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import AuthenticatedGuardMixin from '@/mixins/AuthenticatedGuardMixin'
import settings from '@/settings'

export default {
  name: 'About',
  mixins: [PageMixin, AuthenticatedGuardMixin],
  methods: {},
  computed: {
    version() {
      return settings.app.version
    },
  },
  data() {
    return {
      showHeaderMenu: false,
    }
  },
}
</script>
