import { transformSscEntry } from '@/transformers'
import SwagelokApiService from '@/services/swagelok-api'

const initialState = {
  sscListData: null,
  branchListData: null,
}

const mutations = {
  SET_SSC_LIST_DATA(state, data) {
    state.sscListData = data
  },
  SET_BRANCH_LIST_DATA(state, data) {
    state.branchListData = data
  },
}

const actions = {
  async setSscListData({ commit }) {
    const sscList = await SwagelokApiService.sap.ssc.list()
    commit(
      'SET_SSC_LIST_DATA',
      sscList.map((el) => {
        return transformSscEntry(el)
      }),
    )
  },
  async setBranchListData({ commit }) {
    const branchList = await SwagelokApiService.sap.branch.list()
    commit('SET_BRANCH_LIST_DATA', branchList)
  },
}

export default {
  state: initialState,
  mutations,
  actions,
  namespaced: true,
}
