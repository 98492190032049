<template>
  <div class="transition-colors hover:bg-gray-100">
    <div class="flex justify-between py-1.5">
      <div class="text-headerColor w-[35%] flex-none mt-2 text-sm">
        {{ item.partNumber }}
      </div>

      <div class="flex-none w-full space-y-4">
        <div v-for="request in item.requests" :key="request.id">
          <div class="flex w-full items-center">
            <div class="text-headerColor w-[20%] text-sm whitespace-nowrap">
              {{ request.RequestingSSCBranch }}-{{ request.RequestingSSC }}
            </div>
            <div
              class="
                text-[22px] text-headerColor
                flex
                items-center
                justify-end
                w-[15%]
                text-right
              "
            >
              {{ request.RequestedQuantity }}
            </div>
            <div
              class="
                text-[22px] text-headerColor
                flex
                items-center
                justify-end
                text-right
                w-[15%]
              "
            >
              {{ item.available }}
            </div>
            <div
              class="
                items-center
                text-[22px] text-headerColor text-right
                w-[15%]
                flex
                justify-end
              "
            >
              <span
                class="font-bold text-[#67b645]"
                v-if="request.Sell === 'Yes'"
              >
                Y
              </span>
              <span class="text-[#dd291f] font-bold" v-else>N</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InventoryPoolingSellItem',
  created() {},
  props: ['item'],
}
</script>
