const currentDateTime = () => {
  const currentDate = new Date()

  const day =
    currentDate.getDate() < 10
      ? `0${currentDate.getDate()}`
      : `${currentDate.getDate()}`

  const month =
    currentDate.getMonth() + 1 < 10
      ? `0${currentDate.getMonth() + 1}`
      : `${currentDate.getMonth() + 1}`

  const year = `${currentDate.getFullYear()}`

  const currentTime = new Date()

  const hour =
    currentTime.getHours() < 10
      ? `0${currentTime.getHours()}`
      : `${currentTime.getHours()}`

  const minute =
    currentTime.getMinutes() < 10
      ? `0${currentTime.getMinutes()}`
      : `${currentTime.getMinutes()}`

  return {
    date: `${year}-${month}-${day}`,
    time: `${hour}:${minute}`,
  }
}

export const dateTimeConfig = {
  timezone: 'America/New_York',
  date: 'yyyy-LL-dd',
  time: 'HH:mm',
}

export const currentDateTimeObject = currentDateTime()

export function getAnnouncementsCookie(cookieKey) {
  const cookies = document.cookie.split('; ')

  const announcementsCookie = cookies.filter(
    (cookie) => cookie.indexOf(cookieKey) > -1,
  )

  if (announcementsCookie.length > 0 && announcementsCookie[0] !== '') {
    const cookieValue = announcementsCookie[0].split('=')[1]
    return JSON.parse(cookieValue).announcements
  }
}

export function setAnnouncementsCookie(cookieKey, values) {
  const date = new Date()
  date.setFullYear(date.getFullYear() + 1)
  document.cookie = `${cookieKey}=${values}; expires=${date}; path=/`
}

export function announcementsCookieExists(cookieKey) {
  const cookies = document.cookie.split('; ')

  const announcementsCookie = cookies.filter(
    (cookie) => cookie.indexOf(cookieKey) > -1,
  )

  if (announcementsCookie.length > 0) {
    return true
  }

  return false
}

export function debounce(fn, wait) {
  let timer
  return function (...args) {
    if (timer) {
      clearTimeout(timer)
    }
    const context = this
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, wait)
  }
}
