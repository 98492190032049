const initialState = {
  showHeaderMenu: false,
  showAdvancedSearchParams: true,
  pageTransition: {
    name: 'fade',
    mode: 'out-in',
  },
}

const mutations = {
  SET_SHOW_HEADER_MENU(state, val) {
    state.showHeaderMenu = val
  },
  SET_SHOW_ADVANCED_SEARCH_PARAMS(state, val) {
    state.showAdvancedSearchParams = val
  },
  SET_PAGE_TRANSITION(state, value) {
    if (value === 'slide-forward') {
      state.pageTransition = {
        name: 'transition-slide-forward',
        mode: 'in-out',
      }
    } else if (value === 'slide-back') {
      state.pageTransition = {
        name: 'transition-slide-back',
        mode: '',
      }
    } else if (value === 'fade') {
      state.pageTransition = {
        name: 'transition-fade',
        mode: 'out-in',
      }
    } else if (value === 'none') {
      state.pageTransition = {
        name: '',
        mode: '',
      }
    }
  },
}

const getters = {
  isHeaderMenuVisible: (state, rootGetters) => {
    return !rootGetters['user/isGuest'] && state.showHeaderMenu
  },
}

export default {
  state: initialState,
  mutations,
  getters,
  namespaced: true,
}
