<template>
  <Master>
    <div class="configurator-help">
      <div class="container">
        <div class="font-bold text-headerColor mt-2">Tees & Crosses</div>
        <div>
          Ordering numbers for tees and crosses indicate first the size of the
          run (<strong>1</strong> to <strong>2</strong>) and then the size of
          the branch (<strong>3</strong> for tees and <strong>3</strong> to
          <strong>4</strong>
          for crosses).
        </div>
        <div class="mt-8 grid grid-flow-col justify-evenly">
          <img src="/img/Tees.png" alt="tees" />
          <img src="/img/Crosses.png" alt="crosses" />
        </div>
        <div class="font-bold text-headerColor mt-4">List Price</div>
        <div>
          Total list price for all parts in the configuration. Configurations
          are sorted by list price.
        </div>
        <div class="font-bold text-headerColor mt-4">Available</div>
        <div>
          Total number of complete configurations that can be made given current
          availability. For example, if part A in the configuration has 10
          pieces available and part B has 7 available, the displayed value will
          be 7.<br /><br />The colored circle next to the Available value is an
          indicator of the level of availability at the selected SSC or branch:
        </div>
        <ul class="mt-6 mb-4">
          <li class="mt-4 flex items-center">
            <span
              class="
                w-[20px]
                h-[20px]
                inline-block
                rounded-full
                bg-brandGreen
                mr-2
              "
            ></span>
            All configuration parts have positive availability
          </li>
          <li class="mt-4 flex items-center">
            <span
              class="
                w-[20px]
                h-[20px]
                inline-block
                rounded-full
                bg-yellowTrafficLight
                mr-2
              "
            ></span>
            Some configuration parts have positive availability
          </li>
          <li class="mt-4 flex items-center">
            <span
              class="
                w-[20px]
                h-[20px]
                inline-block
                rounded-full
                bg-redTrafficLight
                mr-2
              "
            ></span>
            No configuration parts have positive availability
          </li>
        </ul>
        <div class="font-bold text-headerColor mt-6">
          Safe Product Selection
        </div>
        <div>
          When selecting a product, the total system design must be considered
          to ensure safe, trouble-free performance. Function, material
          compatibility, adequate ratings, proper installation, operation, and
          maintenance are the responsibilities of the system designer and user.
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import { mapState } from 'vuex'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'

export default {
  name: 'ConfiguratorHelp',
  mixins: [PageMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  methods: {},
  computed: {
    ...mapState('configurator', ['searchAttributes']),
  },
  async created() {
    if (!this.searchAttributes) {
      this.setPath({ name: 'Configurator' })
    }
  },
  data() {
    return {
      showHeaderMenu: false,
    }
  },
}
</script>
