<template>
  <Master>
    <div class="consent-details">
      <div class="container">
        <div class="flex justify-between items-baseline mb-4">
          <PageHeading title="Use of Personal Information" />
        </div>
        <div>
          Swagelok controls, operates, and administers the application from its
          offices in the United States of America. Swagelok Company stores and
          uses your information, including your visits to this application, for
          analytics purposes, to understand how the application is used, to make
          the application more intuitive and to enhance business activities,
          including improving supply chain operations and inventory levels. Your
          personal data may be shared for these purposes with other Swagelok
          companies as well as third party services providers that perform
          functions on our behalf, including Google Analytics (its privacy
          policy can be found
          <a
            class="link-a"
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >here</a
          >), and may be transferred to authorized distributors of Swagelok
          products and services that may contact you directly. You acknowledge
          and agree that your use or access of any of the Sites from locations
          where any content is illegal is prohibited. You may not use the
          application in violation of U.S. export laws and regulations. If you
          access the application from locations outside of the United States,
          you acknowledge and agree that you do so at your own risk and that it
          is your responsibility to comply with any and all foreign laws and
          regulations which exist outside of the United States.
        </div>
        <div class="flex justify-between items-baseline mt-4 mb-4">
          <h1 class="text-2xl text-headerColor">Opt-Out</h1>
        </div>
        <div>
          To opt out, you can use the Feedback form within this application,
          send an email to
          <a class="link-a" href="mailto:infosec@swagelok.com"
            >infosec@swagelok.com</a
          >, or write to us at Attn: Data Privacy Officer, Swagelok Company,
          6262 Cochran Road, Solon, Ohio USA 44139.
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import GuestGuardMixin from '@/mixins/GuestGuardMixin'

export default {
  name: 'ConsentDetails',
  mixins: [PageMixin, GuestGuardMixin],
  methods: {},
  computed: {},
  data() {
    return {
      showHeaderMenu: false,
    }
  },
}
</script>
