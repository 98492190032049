var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Master',[(_vm.userData && _vm.isSscSelectorGuardValid)?_c('div',{staticClass:"container"},[_c('UserCreds',{attrs:{"userData":_vm.userData,"isSsc":_vm.isSsc}}),_c('div',{staticClass:"grid grid-cols-2 gap-6 text-center mt-9"},[_c('div',{staticClass:"text-m text-headerColor cursor-pointer hover:bg-gray-100"},[_c('div',{on:{"click":function($event){return _vm.setPath({
              name: 'Inventory',
              routeMap: { goBackState: { name: 'Home' } },
            })}}},[_c('i',{staticClass:"font-icon icon-inventory text-[100px] text-brandGreen"}),_c('div',{staticClass:"px-10 py-2"},[_vm._v("Inventory Visibility")])])]),_c('div',{staticClass:"text-m text-headerColor cursor-pointer hover:bg-gray-100"},[_c('div',{on:{"click":function($event){return _vm.setPath({
              name: 'Configurator',
              routeMap: { goBackState: { name: 'Home' } },
            })}}},[_c('i',{staticClass:"font-icon icon-configurator text-[100px] text-brandGreen"}),_c('div',{staticClass:"px-4 py-2"},[_c('div',[_vm._v("Multi-Piece Configurator")]),_c('div',{staticClass:"text-m text-red-700 font-semibold"})])])]),(_vm.showInventoryPooling)?_c('div',{staticClass:"text-m text-headerColor cursor-pointer hover:bg-gray-100"},[_c('div',{on:{"click":function($event){return _vm.setPath({
              name: 'InventoryPooling',
              routeMap: { goBackState: { name: 'Home' } },
            })}}},[_c('InventoryPoolingIcon',{staticClass:"w-[100px] m-auto"}),_c('div',{staticClass:"px-4 py-2"},[_c('div',[_vm._v("Inventory"),_c('br'),_vm._v("Pooling")]),_c('div',{staticClass:"text-m text-red-700 font-semibold"},[_vm._v("BETA")])])],1)]):_vm._e(),_c('div',{staticClass:"text-m text-headerColor cursor-pointer hover:bg-gray-100"},[_c('div',{on:{"click":function($event){return _vm.setPath({
              name: 'SscToSscSourcingSplashScreen',
              routeMap: { goBackState: { name: 'Home' } },
            })}}},[_c('SscToSscSourcingIcon',{staticClass:"max-w-[100px] m-auto"}),_c('div',{staticClass:"px-4 py-2"},[_c('div',[_vm._v(" SSC to SSC "),_c('br'),_vm._v(" Cross-Sourcing ")]),_c('div',{staticClass:"text-m text-red-700 font-semibold"})])],1)])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }