const state = {
  isTouchDevice:
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0,
}

const actions = {
  async setAppData(
    { dispatch },
    { resolve: resolveBoot, reject: rejectBoot, bootData },
  ) {
    const immediateQueue = []
    const dependencyQueue = []
    const delayedQueue = []
    const { sessionType } = bootData

    switch (sessionType) {
      case 'anonymous':
        break
      case 'user':
        immediateQueue.push(() =>
          dispatch('user/setUserData', null, { root: true }),
        )
        break
    }

    Promise.all(immediateQueue.map((cb) => cb()))
      .then(() => {
        if (dependencyQueue.length) {
          Promise.all(dependencyQueue.map((cb) => cb()))
        }
      })
      .then(() => {
        resolveBoot()
        if (delayedQueue.length) {
          Promise.all(delayedQueue.map((cb) => cb()))
        }
      })
      .catch((error) => {
        rejectBoot(error)
      })
  },

  async doLogout({ dispatch }) {
    dispatch('user/logout', null, { root: true }).finally(() => {
      window.location.href = '/'
    })
  },

  async doLogin({ dispatch }) {
    return dispatch('router/setPath', { path: '/login' }, { root: true })
  },

  async reLogin({ dispatch }) {
    await dispatch('user/logout', null, { root: true })
    return dispatch('doLogin')
  },
}

export default {
  state,
  actions,
  namespaced: true,
}
