var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"inv-search-inventory-results","data-inv-met-criteria-home":!!(_vm.sscData && _vm.sscData.entries.length > 0) && '',"data-inv-truncated-results":!!(_vm.sscData && _vm.sscData.totalPartsFound > _vm.maxPartsPerSsc) && ''}},[(_vm.sscData && !_vm.isLoading)?_c('div',[(_vm.sscData.totalPartsFound > _vm.maxPartsPerSsc)?_c('div',{staticClass:"flex items-center -mt-3 mb-3"},[_c('SvgExclamationTriangle',{staticClass:"text-brandYellow w-[22px] h-[22px] fill-current"}),_vm._m(0)],1):_vm._e(),_c('hr',{staticClass:"-mx-2"}),_c('ResultsHomeSsc',{staticClass:"mt-2",attrs:{"sscData":_vm.sscData}}),_c('hr',{staticClass:"-mx-2 mt-2"}),_c('div',{},[_c('table2',{attrs:{"header":{
          cellA: 'Part Number',
          cellB: 'Available',
        },"body":{
          cellA: {
            title: 'partNumber',
          },
          cellB: 'totalAvailable',
        },"items":_vm.sscData.entries,"itemHasCursor":true,"options":{ cellARegularFont: true },"itemKey":"partNumber"},on:{"itemClicked":function (item) { return _vm.showDetails(item); }}}),(_vm.showNoResult)?_c('div',{},[_c('div',{staticClass:"mt-4"},[_vm._v("No parts found with minimum inventory.")]),_c('hr',{staticClass:"-mx-2 mt-4"})]):_vm._e(),(!_vm.isSearchComplete)?_c('div',{staticClass:"link-a mt-4 hover:text-brandGreen-darker font-semibold",attrs:{"id":"inv-search-more-sscs"},on:{"click":_vm.searchMoreHandler}},[_vm._v(" Search More Locations "),_c('span',{staticClass:"icon-swg-arrow-right"})]):_vm._e()],1)],1):_vm._e(),(_vm.isLoading)?_c('loader'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col ml-4"},[_c('div',[_vm._v("Not all search results are being displayed.")]),_c('div',[_vm._v("Refine your search for better results.")])])}]

export { render, staticRenderFns }