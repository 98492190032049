<template>
  <div class="header">
    <div class="h-14 bg-brandBlue">
      <div
        class="
          mx-auto
          max-w-[414px]
          bg-brandBlue
          flex
          justify-center
          items-center
          h-full
          relative
        "
      >
        <transition name="transition-fade">
          <div
            class="absolute left-4"
            v-if="isGoBackVisible"
            @click="onGoBackClick"
          >
            <div
              class="
                icon-swg-back
                text-white
                fill-current
                cursor-pointer
                hover:opacity-50
                transition-opacity
                text-xl
                top-[1px]
                relative
              "
            />
          </div>
        </transition>
        <img
          src="@/assets/images/logo.png"
          alt="logo"
          class="inline w-28 cursor-pointer hover:opacity-50 transition-opacity"
          @click="setPath({ name: 'Home' })"
        />
        <transition name="transition-fade">
          <div
            class="
              absolute
              right-4
              cursor-pointer
              hover:opacity-50
              transition-opacity
            "
            @click="
              setPath({
                name: 'Menu',
              })
            "
            v-if="isHeaderMenuVisible"
          >
            <div
              class="
                icon-swg-menu
                text-3xl text-white
                fill-current
                top-[2px]
                relative
              "
            ></div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SvgMenu from '@/assets/icons/bars-solid.svg'
import SvgLeft from '@/assets/icons/chevron-left-solid.svg'

export default {
  name: 'Header',
  components: { SvgMenu, SvgLeft },
  computed: {
    ...mapGetters('layout', ['isHeaderMenuVisible']),
    ...mapGetters('router', ['hasHistory', 'getRouteMap']),
    isGoBackVisible() {
      return !!this.getRouteMap(this.$route.name).goBackState
    },
  },
  methods: {
    ...mapActions('router', ['goPageBack', 'setPath']),
    onGoBackClick() {
      this.goPageBack()
    },
  },
  data() {
    return {}
  },
}
</script>
