<template>
  <Master>
    <div class="favorites-sscs-page">
      <div class="container">
        <div class="flex items-baseline mb-4 pb-2">
          <PageHeading title="Favorite SSCs" />
          <span
            class="
              text-brandBlue text-[38px]
              hover:text-brandBlue-dark
              absolute
              top-[6px]
              right-[26px]
              cursor-pointer
            "
            @click="
              setPath({
                name: 'FavoriteAdd',
                routeMap: { goBackState: { name: 'FavoriteList' } },
              })
            "
            >+</span
          >
        </div>

        <div class="favorites-sscs-content mx-2" v-if="favoriteList.length">
          <draggable
            v-model="favoriteList"
            handle=".favorite-item-drag-handler"
          >
            <div
              v-for="ssc in favoriteList"
              :key="ssc.SSC"
              :id="ssc.SSC"
              class="list-item py-2 border-t"
              :v-model="ssc.SortOrder"
            >
              <div class="flex-none w-10 relative z-20 mr-1">
                <button
                  class="
                    icon-swg-single-horizontal-bar
                    text-brandBlue text-2xl
                    w-10
                  "
                  @click="handleRemoveSSCFromFavorite(ssc.SSC)"
                ></button>
              </div>
              <div class="flex-auto w-70 text-headerColor">
                <div class="font-semibold">{{ ssc.SSC }}</div>
                <div>{{ ssc.details[0].name }}</div>
              </div>
              <div class="flex-none w-20 text-right favorite-item-drag-handler">
                <span class="icon-swg-menu text-brandBlue text-2xl"></span>
              </div>
              <!--  -->
              <div
                v-show="modalDisplay && sscToRemove === ssc.SSC"
                class="
                  w-screen
                  h-screen
                  fixed
                  top-0
                  left-0
                  z-40
                  backdrop-brightness-75
                  flex
                  justify-center
                  items-center
                "
              >
                <div class="bg-white rounded p-4 text-black flex flex-col">
                  <p class="my-4">
                    Are you sure you want to remove this SSC from your favorite
                    list?
                  </p>
                  <div class="favorite-list-form-actions flex gap-2.5 mt-4">
                    <div class="flex-1">
                      <button
                        @click="removeSSCFromFavorite(ssc.SSC)"
                        class="button-a border-solid border-2 border-brandGreen"
                      >
                        <svg
                          v-show="loading == true"
                          class="animate-spin h-5 w-5 text-white m-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="white"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span v-show="loading == false">Confirm</span>
                      </button>
                    </div>
                    <div class="flex-1">
                      <button @click="modalDisplay = false" class="button-b">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </draggable>
        </div>
        <div v-else v-show="noSSCsFoundMessage">No Favorite SSCs selected.</div>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import { mapActions } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'FavoriteList',
  components: {
    draggable,
  },
  mixins: [PageMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  methods: {
    ...mapActions('settings', [
      'searchSSCs',
      'getFavoriteSSCsByUser',
      'handleUpdateFavoriteList',
    ]),
    handleRemoveSSCFromFavorite(ssc) {
      this.sscToRemove = ssc
      this.modalDisplay = true
    },
    removeSSCFromFavorite(ssc) {
      const updatedFavoriteList = this.favoriteList
        .filter((favorite) => favorite.SSC !== ssc)
        .map((item) => {
          return {
            SSC: item.SSC,
            SortOrder: item.SortOrder,
          }
        })

      this.handleUpdateFavoriteList(updatedFavoriteList).then((response) => {
        if (response.status === 200) {
          this.favoriteList = this.favoriteList.filter(
            (favorite) => favorite.SSC !== ssc,
          )
          this.modalDisplay = false
          this.sscToRemove = null
          if (this.favoriteList.length === 0) {
            this.noSSCsFoundMessage = true
          }
        }
      })
    },
  },
  watch: {
    favoriteList(favoritesNewOrder, favoritesOldOrder) {
      if (favoritesOldOrder.length === 0) return

      if (favoritesNewOrder !== favoritesOldOrder) {
        const updatedFavoriteOrder = favoritesNewOrder.map(
          (favorite, index) => {
            return {
              SSC: favorite.SSC,
              SortOrder: index,
            }
          },
        )

        this.handleUpdateFavoriteList(updatedFavoriteOrder).then((response) => {
          if (response.status !== 200) {
            console.log(response.status)
          }
        })
      }
    },
  },
  async created() {
    this.sscList = await this.searchSSCs().then((response) =>
      response.length > 0 ? response : [],
    )
    await this.getFavoriteSSCsByUser().then((response) => {
      if (response[0].FavoriteSSCs.length === 0) {
        this.noSSCsFoundMessage = true
        return
      }
      response[0].FavoriteSSCs.sort((a, b) =>
        a.SortOrder > b.SortOrder ? 1 : -1,
      )
      this.favoriteList = response[0].FavoriteSSCs.map((value) => {
        return {
          ...value,
          details: this.sscList.filter((item) => item.uid == value.SSC),
        }
      })
    })
  },
  data() {
    return {
      sscList: [],
      favoriteList: [],
      modalDisplay: false,
      loading: false,
      sscToRemove: null,
      noSSCsFoundMessage: false,
    }
  },
}
</script>
