import modules from '@/store/modules/index'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer: (state) => {
        const filteredUser = { ...state.user }
        return {
          user: {
            userData: filteredUser.userData,
          },
        }
      },
    }),
  ],
})

export default store

export const useStore = () => store
