var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Master',[_c('div',{staticClass:"announcements"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex justify-between items-baseline mb-4 ml-2"},[_c('PageHeading',{attrs:{"title":"Announcements"}}),(
            this.userData.groups.some(function (group) { return ['wsAdmin', 'admin'].includes(group); }
            )
          )?_c('span',{staticClass:"\n            text-brandBlue text-[38px]\n            hover:text-brandBlue-dark\n            absolute\n            top-[6px]\n            right-[26px]\n            cursor-pointer\n          ",on:{"click":function($event){return _vm.setPath({
              name: 'AnnouncementsAdd',
              routeMap: { goBackState: { name: 'Announcements' } },
            })}}},[_vm._v("+")]):_vm._e()],1),_c('div',{staticClass:"announcements-list mx-2"},[(_vm.action == 'delete')?_c('div',{staticClass:"bg-secondaryRed text-white p-4 mb-4"},[_vm._v(" The announcement was deleted successfully ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.announcementData.length && !_vm.loading),expression:"!announcementData.length && !loading"}],staticClass:"\n            flex\n            items-center\n            mt-4\n            border-t border-brandGray border-solid\n          "},[_c('SvgExclamationTriangle',{staticClass:"text-brandYellow w-[22px] h-[22px] fill-current mt-2"}),_c('div',{staticClass:"flex justify-center items-center ml-4 pt-4"},[_vm._v(" There are no announcements at this time."),_c('br'),_vm._v("Check back later for updates! ")])],1),(_vm.announcementData.length)?_c('table',{staticClass:"table-fixed text-headerColor w-full"},[_c('thead',[_c('tr',{staticClass:"border-solid border-secondaryGray border-b border-t"},[_c('th',{staticClass:"w-4/5 text-left py-2"},[_vm._v("Title")]),_c('th',{staticClass:"w-1/5 text-right py-2"},[_vm._v("Date")])])]),_c('tbody',_vm._l((_vm.announcementData),function(item){return _c('tr',{key:item.id,staticClass:"\n                border-solid border-secondaryGray border-b\n                cursor-pointer\n                h-16\n                leading-5\n              ",class:{
                'text-secondaryRed':
                  item.startDate > new Date().toISOString(),
              },on:{"click":function($event){return _vm.handleClick(item.id)}}},[_c('td',{staticClass:"py-3 pr-5"},[_vm._v(_vm._s(item.title))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(new Date(item.startDate).toLocaleString( _vm.navigator.language && 'default', { timeZone: 'UTC', day: 'numeric', month: 'numeric', year: '2-digit', } ))+" ")])])}),0)]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }