<template>
  <Master>
    <div class="ssc-selector">
      <div class="container">
        <PageHeading title="Select an SSC" class="mb-4" />

        <ValidationObserver
          tag="form"
          ref="observer"
          class="relative"
          @submit.prevent="submitSsc"
          v-slot="{ failed }"
          id="set-select-ssc"
        >
          <validation-provider
            tag="div"
            rules="required"
            name="Ssc Code"
            v-slot="{ errors }"
          >
            <FormSelectWrapper>
              <select
                class="form-select"
                v-model="formData.sscUid"
                id="set-selected-ssc"
              >
                <option value="" disabled selected>Please select an SSC</option>
                <option
                  :value="ssc.uid"
                  v-for="ssc in sscListData"
                  :key="ssc.uid"
                >
                  {{ ssc.uid }} - {{ ssc.name }}
                </option>
              </select>
            </FormSelectWrapper>
            <transition name="transition-fade">
              <div v-show="errors.length" class="absolute field-error">
                {{ errors[0] }}
              </div>
            </transition>
          </validation-provider>

          <button
            class="button-a mt-8"
            type="submit"
            :disabled="isLoading || failed"
          >
            SUBMIT
          </button>
          <loader v-if="isLoading" />
        </ValidationObserver>
      </div>
    </div>
  </Master>
</template>

<script>
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import PageMixin from '@/mixins/PageMixin'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'SscSelector',
  mixins: [PageMixin, LoaderMixin, AuthorizedGuardMixin],
  computed: {
    ...mapGetters('user', ['isSsc', 'isSwcon']),
    ...mapState('user', ['userData']),
    ...mapState('ssc', ['sscListData']),
    ...mapState('router', ['redirectAfterAuthTo']),
  },
  methods: {
    ...mapActions('inventory', ['cleanInventoryData']),
    ...mapActions('user', ['setUserSsc']),
    ...mapActions('ssc', ['setSscListData']),
    ...mapActions('settings', ['getUserSSCSettings']),
    async submitSsc() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return
      await this.withFallback(async () => {
        await this.withLoader(() => this.setUserSsc(this.formData))
        if (this.userData.ssc) {
          this.cleanInventoryData()
          this.$store.dispatch('cleanOrders')

          await this.getUserSSCSettings(this.userData.ssc?.uid)

          this.setPath({
            name: this.redirectAfterAuthTo ? this.redirectAfterAuthTo : 'Home',
            params: {
              refresh: true,
            },
          })
          localStorage.removeItem('redirectAfterAuthTo')
        }
      })
    },
    withFallback(cb) {
      return cb().catch((e) => {
        console.log(e)
        this.setPath({
          name: 'ServiceUnavailable',
          params: {
            retryAction: () => {
              this.setPath({ name: 'SscSelector' })
            },
          },
        })
      })
    },
  },
  async created() {
    if (this.isSsc || this.isSwcon) {
      this.setPath({ name: 'Home' })
    } else {
      if (!this.sscListData) {
        this.withFallback(() => this.withLoader(this.setSscListData))
      }
      if (this.userData?.ssc) {
        this.formData.sscUid = this.userData.ssc.uid
      }
    }
  },
  data() {
    return {
      showHeaderMenu: false,
      formData: {
        sscUid: '',
      },
    }
  },
}
</script>
