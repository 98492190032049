import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, min } from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'

export default {
  init: () => {
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)

    extend('required', {
      ...required,
      message: messages['required'],
    })
    extend('min', {
      ...min,
      message: messages['min'],
    })
  },
}
