import SwagelokApiService from '@/services/swagelok-api'

const initialState = {
  feedbackData: null,
}

const mutations = {
  SET_FEEDBACK_DATA(state, data) {
    state.feedbackData = data
  },
}

const actions = {
  async sendFeedback({ commit }, { subject, message }) {
    await SwagelokApiService.feedback.send({ subject, message })
    commit('SET_FEEDBACK_DATA', { isSent: true })
  },
}

export default {
  state: initialState,
  mutations,
  actions,
  namespaced: true,
}
