<template>
  <Master>
    <div class="inventory-help">
      <div class="container">
        <div class="font-bold mt-2">Minimum Quantity</div>
        <div>
          Restricts search results to those SSCs with available inventory
          greater than or equal to the specified amount.
        </div>
        <div class="font-bold mt-4">Include All Sites with Inventory</div>
        <div>
          Overrides the Minimum Quantity value. When selected, any SSC with
          positive inventory will be listed.
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import { mapState } from 'vuex'

export default {
  name: 'InventoryHelpMinQty',
  mixins: [PageMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  methods: {},
  computed: {
    ...mapState('inventory', ['inventoryData']),
  },
  data() {
    return {
      showHeaderMenu: false,
    }
  },
}
</script>
