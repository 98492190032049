import ArrowLeft from '@/assets/icons/arrow-left.svg'
import ArrowRight from '@/assets/icons/arrow-right.svg'
import { OrderStatus } from '@/components/inventory/OrderStatus'

export const InventoryOrderList = ({
  props: { type, items, canRemove },
  parent: { $root },
  data,
  children,
}) => {
  const title = type === 'outgoing' ? 'Outgoing Requests' : 'Incoming Requests'
  const locationTitle = type === 'outgoing' ? 'To' : 'From'
  const sscKey = type === 'outgoing' ? 'SellingSSC' : 'RequestingSSC'

  return (
    <div>
      <h1 class="text-2xl text-headerColor font-bold flex items-center gap-2">
        {type === 'outgoing' ? (
          <ArrowLeft class="w-5" />
        ) : (
          <ArrowRight class="w-5" />
        )}

        {title}
      </h1>

      <hr class="my-2" />

      {children}

      <div class="flex gap-0">
        <div class="font-bold text-headerColor w-[130px] mr-2">Part Number</div>
        <div class="font-bold text-headerColor w-[55px] mr-1">
          {locationTitle}
        </div>
        <div class="font-bold text-headerColor w-[55px] text-right mr-1">
          Qty
        </div>
        <div
          style={{
            width: !canRemove ? '116px' : '92px',
          }}
          class="font-bold text-headerColor text-right"
        >
          Status
        </div>
        {canRemove ? (
          <div class="font-bold text-headerColor w-15% text-right"></div>
        ) : null}
      </div>
      <hr class="mt-2" />
      <div class="mb-2">
        {items.map((item) => (
          <div
            key={item.PartNumber}
            class="flex justify-between  border-b border-[#d2d4d8]"
          >
            <div class="text-headerColor w-[130px] flex-none text-sm mr-2 flex items-center">
              {item.PartNumber}
            </div>

            <div class="w-full">
              {item.requests.map((request) => (
                <div
                  onClick={() => {
                    $root.$store.dispatch('router/setPath', {
                      name:
                        type === 'outgoing'
                          ? request.Status === 'Approved'
                            ? 'ApprovedRequest'
                            : 'OutgoingRequest'
                          : 'IncomingRequest',
                      params: {
                        request,
                        type,
                      },
                      routeMap: {
                        goBackState: {
                          name: 'OrderRequestsNotifications',
                        },
                      },
                    })
                  }}
                  role="button"
                  key={request.id}
                  class="flex w-full items-center gap-1 border-b py-2 last:border-0"
                >
                  <div class="text-headerColor w-[55px] text-sm whitespace-nowrap mr-1">
                    0-{request[sscKey]}
                  </div>
                  <div class=" text-[22px] text-headerColor flex items-center justify-end w-[55px] text-right mr-1">
                    {request.Status === 'Approved'
                      ? request.ApprovedQuantity
                      : request.RequestedQuantity}
                  </div>
                  <div
                    style={{
                      width: !canRemove ? '110px' : '95px',
                    }}
                    class="text-headerColor flex items-center justify-end text-right"
                  >
                    <OrderStatus
                      status={request.Status}
                      requestedDate={request.RequestedDateTime}
                    />
                  </div>
                  {canRemove ? (
                    <div class=" items-center text-[22px] text-headerColor text-right w-[12%] flex justify-end">
                      <button
                        onClick={(e) => {
                          e.stopPropagation()
                          data.on.remove({
                            id: request.id,
                            partNumber: item.PartNumber,
                          })
                        }}
                        type="button"
                        class=" icon-swg-circle-close flex  items-center cursor-pointer text-brandBlue text-lg"
                      />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
