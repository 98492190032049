<template>
  <div class="transition-list-item">
    <div class="-mx-2 transition-colors hover:bg-gray-100">
      <div class="grid grid-cols-12 py-1.5 px-2">
        <div class="col-span-4 self-center flex items-center">
          <div
            v-if="isMultipleLocations"
            class="bg-brandBlue rounded-full mr-1 w-[3px] h-[3px]"
          ></div>
          {{ item.partNumber }}
        </div>
        <div
          class="
            text-[22px] text-headerColor
            self-center
            justify-self-end
            col-span-2
          "
        >
          {{ item.requestedQuantity }}
        </div>
        <div
          :class="[isMultipleLocations ? 'font-semibold text-black' : '']"
          class="col-span-4 pl-4 self-center"
        >
          {{ location }}
        </div>
        <div class="col-span-2 text-[22px] self-center justify-self-end">
          <div
            v-if="isMultiple && !isConfirmed"
            class="
              rotate-180
              icon-swg-back
              text-brandBlue
              fill-current
              cursor-pointer
              text-xl
            "
          />
          <span
            class="font-semibold"
            :class="[
              totalConfirmedLocationsWithEnoughQuantity < 2
                ? 'text-brandGreen'
                : 'text-brandYellow',
            ]"
            v-else
          >
            {{ totalConfirmedItems }}
          </span>
        </div>
      </div>
    </div>
    <hr class="-mx-2" />
  </div>
</template>
<script>
export default {
  name: 'InventoryPoolingBuyItem',
  methods: {
    handleHelpClick() {
      this.setPath({
        name: this.helpPage,
      })
    },
  },
  created() {
    this.totalConfirmedLocations = this.item.requests.filter(
      (request) => request.Status === 'Order',
    )

    this.isMultiple =
      this.item.requests.filter((i) => i.SellingSSC !== 'SWGLK').length > 1

    this.isConfirmed = this.totalConfirmedLocations.length > 0

    this.totalConfirmedItems = this.totalConfirmedLocations.length
      ? this.totalConfirmedLocations.reduce(
          (acc, request) => acc + parseInt(request.ApprovedQuantity),
          0,
        )
      : this.item.requestedQuantity

    this.totalConfirmedLocationsWithEnoughQuantity = this
      .totalConfirmedLocations.length
      ? this.totalConfirmedLocations.reduce((acc, request) => {
          const isEnough =
            parseInt(request.ApprovedQuantity) ===
            parseInt(this.item.requestedQuantity)
          return isEnough ? acc + 1 : acc
        }, 0)
      : 0

    this.location = this.totalConfirmedLocations.length
      ? this.totalConfirmedLocations.length > 1
        ? 'Multiple'
        : this.totalConfirmedLocations[0].SellingSSC
      : this.item.location
  },
  computed: {
    isMultipleLocations() {
      return (
        (this.isMultiple && !this.isConfirmed) ||
        this.totalConfirmedLocations.length > 1
      )
    },
  },
  props: ['item'],
  data: () => ({
    totalConfirmedLocationsWithEnoughQuantity: 0,
    totalConfirmedLocations: 0,
    totalConfirmedItems: 0,
    isMultiple: false,
    isConfirmed: false,
    location: '',
  }),
}
</script>
