import * as dayjs from 'dayjs'
import dayjsBusinessDays from 'dayjs-business-days2'

dayjs.extend(dayjsBusinessDays)

export const isOrderExpired = (requestedDate) => {
  const diff = dayjs(requestedDate).businessDaysAdd(3).businessDiff(dayjs())

  return diff <= 0
}

export const OrderStatus = ({ props }) => {
  const { status, requestedDate } = props
  const diff = dayjs(requestedDate).businessDaysAdd(3).businessDiff(dayjs())

  if (status === 'Approved') {
    return <div class="font-bold text-brandGreen">Approved</div>
  }

  if (status === 'Denied') {
    return <div class="font-bold text-headerColor">Denied</div>
  }

  if (status === 'Canceled' || status === 'Ordered') {
    return <div class="font-bold text-brandBlue">{status}</div>
  }

  if (diff <= 0) {
    return <div class="font-bold text-red-700">Expired</div>
  }

  return (
    <div class={diff === 1 ? 'font-bold text-red-700' : 'text-headerColor'}>
      {diff} {diff === 1 ? 'Day' : 'Days'}
    </div>
  )
}
