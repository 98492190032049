<template>
  <div class="">
    <div class="flex justify-between py-2.5">
      <div class="font-bold text-headerColor w-[36%]">
        {{ header.cellA }}
      </div>
      <div class="font-bold text-headerColor w-[34%] text-right">
        {{ header.cellB }}
      </div>
      <div class="font-bold text-headerColor w-[25%] text-right">
        {{ header.cellC }}
      </div>
      <div class="font-bold text-headerColor w-[8%] text-right">
        {{ header.cellD }}
      </div>
    </div>
    <hr class="-mx-2" />
    <transition-group name="transition-list" tag="div">
      <div
        class="transition-list-item"
        v-for="(item, idx) in items"
        @click="$emit('itemClicked', item)"
        :key="item[itemKey] + idx"
      >
        <div class="-mx-2 transition-colors hover:bg-gray-100">
          <div
            class="flex justify-between items-center py-2 px-2"
            :class="itemHasCursor ? 'cursor-pointer' : null"
          >
            <div class="w-[36%]">
              <div class="flex">
                <div
                  v-html="
                    options && options.lineBreak
                      ? item[body.cellA.title].replaceAll(', ', '<br />')
                      : item[body.cellA.title]
                  "
                  class="text-headerColor truncate mr-1"
                  :class="
                    options && options.cellARegularFont ? null : 'font-bold'
                  "
                ></div>
                <slot name="cellAIcon" v-if="item[body.cellA.hasIcon]"></slot>
              </div>
              <div class="truncate text-headerColor">
                {{ item[body.cellA.subtitle] }}
              </div>
            </div>
            <div class="text-[22px] text-headerColor w-[34%] text-right">
              {{ item[body.cellB] }}
            </div>
            <div
              class="
                text-[22px] text-headerColor
                w-[25%]
                text-right
                pl-[1.3rem]
              "
              :class="
                options && options.trafficLights
                  ? 'flex justify-between items-center'
                  : null
              "
              v-html="
                options && options.trafficLights
                  ? (item['displayComponents'].every((el) => el.available > 0)
                      ? green
                      : item['displayComponents'].some((el) => el.available > 0)
                      ? yellow
                      : red) + item[body.cellC]
                  : item[body.cellC]
              "
            ></div>
            <div
              class="
                text-[16px]
                text-headerColor
                w-[8%]
                text-right
                mt-[4px]
              "
            >
              {{ item.inventoryUoMValue }}
            </div>
          </div>
        </div>
        <hr class="-mx-2" />
      </div>
    </transition-group>
  </div>
</template>
<script>
export default {
  name: 'Table4',
  props: ['header', 'itemKey', 'body', 'items', 'itemHasCursor', 'options'],
  computed: {
    green() {
      return `<span class='${this.trafficLight} bg-brandGreen'></span>`
    },
    yellow() {
      return `<span class='${this.trafficLight} bg-yellow-300'></span>`
    },
    red() {
      return `<span class='${this.trafficLight} bg-red-700'></span>`
    },
  },
  data() {
    return {
      trafficLight: 'w-[20px] h-[20px] inline-block rounded-full',
    }
  },
}
</script>
