<template>
  <Master>
    <loader v-if="!history" />

    <div v-if="history" class="container">
      <PageHeading title="Request History" class="flex justify-between">
        <HomeSSC />
      </PageHeading>

      <div class="italic text-brandLightBlue text-sm mt-2p mb-2">
        History is maintained for one year
      </div>

      <hr class="my-2" />

      <InventoryOrderList :items="history" :type="$route.params.type" />
    </div>
  </Master>
</template>

<script>
import HomeSSC from '@/components/HomeSSC'
import { InventoryOrderList } from '@/components/inventory/InventoryOrderList'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import PageMixin from '@/mixins/PageMixin'

export default {
  name: 'OrderRequestsHistoryFull',
  mixins: [PageMixin, AuthorizedGuardMixin],
  components: { HomeSSC, InventoryOrderList },
  computed: {
    history() {
      return this.$store.state.orders.history
    },
  },
  created() {
    this.$store.dispatch('getOrderHistory', {
      type: this.$route.params.type,
    })
  },
}
</script>
