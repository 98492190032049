<template>
  <div class="">
    <hr class="-mx-2" />
    <div class="flex justify-between py-2.5">
      <div
        class="font-bold text-headerColor w-[40%]"
        :class="helpPage && 'flex items-center'"
      >
        {{ header.cellA }}
        <button v-if="helpPage" @click="handleHelpClick" class="ml-2 w-[20px]">
          <QuestionCircleSolid class="text-brandLightBlue" />
        </button>
      </div>
      <div class="font-bold text-headerColor w-[15%] text-center">
        {{ header.cellB }}
      </div>
      <div class="font-bold text-headerColor w-[15%] text-center">
        {{ header.cellC }}
      </div>
      <div class="font-bold text-headerColor w-[15%] text-center">
        {{ header.cellD }}
      </div>
      <div class="font-bold text-headerColor w-[15%] text-center">
        {{ header.cellE }}
      </div>
    </div>
    <hr class="-mx-2" />
    <transition-group name="transition-list" tag="div">
      <div
        class="transition-list-item"
        v-for="(item, idx) in items"
        @click="$emit('itemClicked', item)"
        :key="item[itemKey] + idx"
      >
        <div class="-mx-2 transition-colors hover:bg-gray-100">
          <div
            class="flex justify-between items-center py-1.5 px-2"
            :class="itemHasCursor ? 'cursor-pointer' : null"
          >
            <div
              :class="`${
                item[body.cellD].toString().length > 4 ? 'w-[30%]' : 'w-[40%]'
              }`"
            >
              <div class="flex">
                <div
                  class="text-headerColor truncate mr-1"
                  :class="
                    options && options.cellARegularFont ? null : 'font-bold'
                  "
                >
                  {{ item[body.cellA.title] }}
                </div>
                <slot name="cellAIcon" v-if="item[body.cellA.hasIcon]"></slot>
              </div>
              <div class="truncate text-headerColor">
                {{ item[body.cellA.subtitle] }}
              </div>
            </div>
            <div class="text-[22px] text-headerColor w-[15%] text-center">
              {{ item[body.cellB] }}
            </div>
            <div
              class="text-[22px] text-headerColor w-[15%] text-center"
              v-if="trafficLightItems.includes(body.cellC)"
            >
              <TrafficLight :color="item[body.cellC]" class="m-auto" />
            </div>
            <div
              class="text-[22px] text-headerColor w-[15%] text-center"
              v-else
            >
              {{ item[body.cellC] }}
            </div>
            <div
              class="text-[22px] text-headerColor text-center"
              :class="`${
                item[body.cellD].toString().length > 4 ? 'w-[30%]' : 'w-[15%]'
              }`"
            >
              {{ item[body.cellD] }}
            </div>
            <div
              class="text-[22px] text-headerColor text-center"
              :class="`${
                item[body.cellE].toString().length > 4 ? 'w-[30%]' : 'w-[15%]'
              }`"
            >
              {{ item[body.cellE] }}
            </div>
          </div>
        </div>
        <hr class="-mx-2" />
      </div>
    </transition-group>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import TrafficLight from '@/components/common/TrafficLight'
import QuestionCircleSolid from '@/assets/icons/question-circle-solid.svg'

export default {
  name: 'InventoryTable5Columns',
  methods: {
    ...mapActions('router', ['setPath']),
    handleHelpClick() {
      this.setPath({
        name: this.helpPage,
      })
    },
  },
  props: [
    'header',
    'itemKey',
    'body',
    'items',
    'itemHasCursor',
    'options',
    'trafficLightItems',
    'helpPage',
  ],
  components: {
    TrafficLight,
    QuestionCircleSolid,
  },
}
</script>
