import SwagelokApiService from '@/services/swagelok-api'
import settings from '@/settings'
import { transformUserEntry } from '@/transformers'

const initialState = {
  userData: null,
}

const mutations = {
  SET_USER_DATA(state, data) {
    state.userData = data
  },
}

const actions = {
  async login(_storeProps, providerId) {
    SwagelokApiService.user.login(providerId)
  },

  async postLogin({ dispatch }) {
    return dispatch('setUserData')
  },

  async setUserData({ commit }) {
    const user = await SwagelokApiService.user.details()
    return commit('SET_USER_DATA', transformUserEntry(user))
  },

  async logout({ dispatch }) {
    return SwagelokApiService.user.logout().finally(() => {
      return dispatch('resetUserData')
    })
  },

  async resetUserData({ commit }) {
    commit('SET_USER_DATA', null)
    return Promise.resolve()
  },

  async setUserSsc({ dispatch, commit }, { sscUid }) {
    await SwagelokApiService.user.setSsc(sscUid)
    commit('inventory/SET_INVENTORY_DATA', null, { root: true })
    commit('inventory/SET_SEARCH_QUERY', null, { root: true })
    return dispatch('setUserData')
  },
}

const getters = {
  isGuest: (state) => !state.userData,
  isSsc: (state) => state.userData?.groups.includes(settings.user.groups.wsSsc),
  isSwcon: (state) =>
    state.userData?.groups.includes(settings.user.groups.wsSwcon),
  isWsCorp: (state) =>
    state.userData?.groups.includes(settings.user.groups.wsCorp),
  isAuthorized: (state) => !!state.userData && !!state.userData.groups.length,
  isWsSscAdmin: (state) =>
    state.userData?.groups.includes(settings.user.groups.wsSscAdmin),
  isAdmin: (state) =>
    state.userData?.groups.includes(settings.user.groups.admin),
  isWsAdmin: (state) =>
    state.userData?.groups.includes(settings.user.groups.wsAdmin),
  isInIVAOrdering: (state) =>
    state.userData?.groups.includes(settings.user.groups.approver) ||
    state.userData?.groups.includes(settings.user.groups.ordering),
  isInOrderingGroup: (state) =>
    state.userData?.groups.includes(settings.user.groups.ordering),
  isInApproverGroup: (state) => {
    return state.userData?.groups.includes(settings.user.groups.approver)
  },
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
  namespaced: true,
}
