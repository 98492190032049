<template>
  <Master>
    <div class="configurator-help">
      <div class="container">
        <PageHeading title="SSC to SSC Cross-Sourcing Admin" />
        <div v-if="!invalid">
          <button
            @click.prevent="handleSetMultiple()"
            class="mt-3 font-bold text-brandGreen"
          >
            Set Multiple
            <i class="icon-right-open-big ml-2"></i>
          </button>
          <hr class="-mx-2 mt-5" />
          <div v-for="(item, idx) in items" :key="idx">
            <table2
              v-if="item"
              :header="{
                cellA: item.FunctionName,
                cellB: 'Capacity',
              }"
              :body="{
                cellA: {
                  title: 'CapName',
                  subtitle: 'Contact',
                },
                cellB: 'color',
              }"
              :orderBy="'CapName'"
              :items="item.Capabilities"
              :itemHasCursor="true"
              :options="{
                cellARegularFont: false,
                truncate: false,
                lineBreak: true,
                trafficLights: 'color',
              }"
              itemKey="CapCode"
              @itemClicked="(item) => showDetails(item)"
            />
            <hr class="-mx-2 mt-2" v-if="item" />
          </div>
        </div>
        <div
          v-else
          class="flex items-center mt-4 border-t border-brandGray border-solid"
        >
          <SvgExclamationTriangle
            class="text-brandYellow w-[22px] h-[22px] fill-current mt-2"
          />
          <div class="flex justify-center items-center ml-4 pt-4">
            SSC to SSC Cross-Sourcing not found.<br />Please, check back later.
          </div>
        </div>
        <button
          v-if="!invalid"
          @click.prevent="handleReturnToTop()"
          class="mt-6 font-bold text-brandGreen"
        >
          Return to Top
          <i class="icon-right-open-big ml-2"></i>
        </button>
      </div>
    </div>
    <loader v-if="isLoading" />
  </Master>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import AdminSSCGuardMixin from '@/mixins/AdminSSCGuardMixin'
import SourcingCapacityMixin from '@/mixins/SourcingCapacityMixin'
import SvgExclamationTriangle from '@/assets/icons/exclamation-triangle.svg'

export default {
  name: 'SscToSscSourcingAdmin',
  mixins: [
    PageMixin,
    LoaderMixin,
    SscSelectorGuardMixin,
    AuthorizedGuardMixin,
    SourcingCapacityMixin,
    AdminSSCGuardMixin,
  ],
  components: { SvgExclamationTriangle },
  methods: {
    ...mapActions('sourcing', ['searchSSCs', 'setCapabilities']),
    handleSetMultiple() {
      this.setPath({
        name: 'SscToSscSourcingAdminUpdateCapabilities',
      })
    },
    showDetails(item) {
      this.setPath({
        name: 'SscToSscSourcingAdminDetails',
        params: item,
      })
    },
    handleReturnToTop() {
      const appWrapper = document.querySelector('#appWrapper')
      appWrapper.scrollTo(0, 0)
    },
    getFunctionNameByCode(functionCode, capCode) {
      return this.capabilityData
        .find((el) => el.FunctionCode === functionCode)
        ?.Capabilities.find((el) => el.CapCode === capCode)?.CapName
    },
  },
  computed: {
    ...mapState('sourcing', ['SSCSourcingData', 'capabilityData']),
  },
  async created() {
    if (this.capabilityData === null) {
      return this.setPath({
        name: 'SscToSscSourcing',
      })
    }

    if (!this.SSCSourcingData) {
      await this.withLoader(async () => {
        return await this.searchSSCs()
      })
    }

    if (this.SSCSourcingData === null || !Array.isArray(this.SSCSourcingData)) {
      this.invalid = true
      return
    }

    if (!Array.isArray(this.capabilityData)) {
      return this.setPath({
        name: 'ServiceUnavailable',
        params: {
          retryAction: () => {
            this.setPath({
              name: 'SscToSscSourcingAdmin',
            })
          },
        },
      })
    }

    this.items = this.capabilityData.map((el) => {
      const { FunctionName, FunctionCode, Capabilities } = el

      return {
        FunctionName,
        Capabilities: Capabilities.map((el) => {
          const { CapCode, CapName } = el

          const hasSSCSettings = this.SSCSourcingData.find(
            (el) => el.Capability === CapCode,
          )

          const Contact = hasSSCSettings?.Contact ?? 'No contact'
          const Capacity = hasSSCSettings?.Capacity ?? 'Capability not offered'
          const Note = hasSSCSettings?.Note

          const idx = this.SSCSourcingData.findIndex(
            (el) => el.Capability === CapCode,
          )

          return {
            idx: idx > -1 && idx,
            FunctionCode,
            CapCode,
            CapName,
            Contact,
            Capacity,
            Note,
            color: this.getCapacityColor(Capacity),
          }
        }),
      }
    })
  },
  data() {
    return {
      showHeaderMenu: true,
      items: [],
      invalid: false,
    }
  },
}
</script>
