<template>
  <div class="content transition">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Master',
}
</script>
