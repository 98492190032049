import { mapMutations, mapActions, mapGetters } from 'vuex'
import PageTitleMixin from '@/mixins/PageTitleMixin'

export default {
  mixins: [PageTitleMixin],
  props: ['onPageMounted'],
  computed: {
    ...mapGetters('router', ['getRouteMap']),
    pageTitle() {
      return this.$route.meta?.title
    },
  },
  methods: {
    ...mapMutations('router', { setGoBack: 'SET_GO_BACK' }),
    ...mapMutations('layout', {
      setShowHeaderMenu: 'SET_SHOW_HEADER_MENU',
    }),
    ...mapActions('router', ['setPath', 'setRouteMap']),
    getCurrentPath() {
      return window.location.pathname
    },
  },
  created() {
    this.setGoBack(this.getRouteMap(this.$route.name).goBackState)
    this.setPageTitle(this.pageTitle)
    this.setShowHeaderMenu(this.showHeaderMenu)
  },
  mounted() {
    if (this.onPageMounted) this.onPageMounted(this)
  },
  data() {
    return {
      showHeaderMenu: true,
    }
  },
}
