<template>
  <Master>
    <div class="configurator-help">
      <div class="container">
        <PageHeading title="Request Submitted" />
        <p class="mt-4">
          Your request has been submitted via email to the following SSCs:
        </p>

        <div class="mt-5">
          <div v-for="(recipient, index) in selectedRecipients" :key="index">
            {{ recipient.uid }} - {{ recipient.name }}
          </div>
        </div>

        <button
          @click.prevent="
            setPath({ name: 'SscToSscSourcing', params: { clearState: true } })
          "
          class="mt-6 font-bold text-brandGreen"
        >
          Return to SSC to SSC Cross-Sourcing Home
          <i class="icon-right-open-big ml-2"></i>
        </button>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'

export default {
  name: 'SscToSscSourcingRequestMultipleSubmitted',
  mixins: [PageMixin, LoaderMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  props: ['selectedRecipients'],
  data() {
    return {
      showHeaderMenu: true,
    }
  },
}
</script>
