<template>
  <Master>
    <div class="ssc-to-ssc-sourcing-details">
      <div class="container">
        <div>
          <PageHeading :title="sscId" />
          <div class="mt-1">{{ sscData.name }}</div>
        </div>

        <div class="flex mt-1" v-if="telFormat">
          <div class="icon-swg-phone text-brandBlue text-xl mr-2" />
          <a :href="`tel:#${telFormat}`" class="text-brandGreen">{{
            sscData.phone
          }}</a>
        </div>

        <div class="mt-6" v-for="item in SSCSourcing" :key="item.Capability">
          <PageHeading :title="getCapability(item.Function, item.Capability)" />
          <div class="flex mt-1 gap-2">
            <EnvelopeIcon class="w-6 h-6" />
            <a
              :href="`mailto:${item.Contact.toLowerCase()}?subject=Capability Request`"
              class="text-brandGreen"
              >{{ item.Contact.toLowerCase() }}</a
            >
          </div>
          <div class="mt-3 flex items-center">
            <TrafficLight :color="getCapacityColor(item.Capacity)" />
            <p class="ml-3">{{ item.Capacity }}</p>
          </div>
          <p
            v-if="item.Capacity"
            class="mt-3 mb-6 flex gap-2 italic items-center"
          >
            <DocumentPenIcon class="w-6 h-6 relative top-[1px]" />
            {{ item.Note }}
          </p>
        </div>

        <div class="mt-6" v-if="!isHome">
          <PageHeading title="Initiate a Request" />
          <p class="mt-2">
            Submitting the form below will send an email to the designated
            contact designated at this SSC responsible for managing this
            capability.
          </p>
        </div>

        <div class="w-full relative mt-2" v-if="!isHome">
          <p class="mb-3"><b>To:</b></p>
          <span v-for="contact in contacts" :key="contact">
            {{ contact }}<br />
          </span>
        </div>

        <ValidationObserver
          v-if="!isHome"
          tag="form"
          ref="observer"
          class="relative mt-3"
          @submit.prevent="submitRequest"
          v-slot="{ failed }"
          id="sss-request"
        >
          <input
            type="checkbox"
            class="hidden"
            :id="sscData.uid"
            :value="capabilitiesList"
          />
          <validation-provider
            tag="div"
            class="mt-5"
            name="Message"
            v-slot="{ errors }"
          >
            <Input
              label="CC:"
              labelClassName="mb-3 font-bold block"
              v-model="formData.cc"
              name="cc"
              type="text"
            />
            <transition name="transition-fade">
              <div class="text-secondaryRed -mt-4 mb-2">
                <small v-if="emailInvalid">
                  Please, add a valid email address
                </small>
              </div>
            </transition>

            <div class="justify-between items-center">
              <p class="mb-3"><b>Message:</b></p>
              <textarea
                class="form-textarea placeholder-italic h-[400px]"
                v-model="formData.message"
                :disabled="isLoading"
                type="text"
                id="set-request-message"
              />
            </div>
            <transition name="transition-fade">
              <div v-show="errors.length" class="absolute field-error">
                {{ errors[0] }}
              </div>
            </transition>
          </validation-provider>
          <button
            class="button-a mt-8"
            type="submit"
            :disabled="isLoading || failed"
          >
            SUBMIT
          </button>
        </ValidationObserver>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import SourcingCapacityMixin from '@/mixins/SourcingCapacityMixin'
import { mapState, mapActions } from 'vuex'
import DocumentPenIcon from '@/assets/icons/document-pen.svg'
import EnvelopeIcon from '@/assets/icons/envelope.svg'

export default {
  components: {
    DocumentPenIcon,
    EnvelopeIcon,
  },
  name: 'SscToSscSourcingDetails',
  mixins: [
    PageMixin,
    LoaderMixin,
    SscSelectorGuardMixin,
    AuthorizedGuardMixin,
    SourcingCapacityMixin,
  ],
  props: ['sscId', 'isHome', 'sscData', 'SSCSourcing'],
  methods: {
    ...mapActions('settings', ['sendEmail']),
    isValidEmail(email) {
      return !!email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      )
    },
    async submitRequest() {
      const { message, cc } = this.formData

      this.emailInvalid = false

      if (cc && !this.isValidEmail(cc)) {
        this.emailInvalid = true
        return
      }

      const { uid: loggedUserEmail, fullName } = this.userData
      const requestingSSC = this.userData.ssc.uid
      const receivingSSC = this.sscId
      const fromName = 'Swagelok Webmaster'
      const fromEmail = 'webmaster@swagelok.com'

      const requestedCapabilities = this.capabilities
        .map((el) => {
          return `${this.getCapability(this.functionCode[0], el)}<br />`
        })
        .join('')

      const sendMail = await this.sendEmail({
        fromName,
        fromEmail,
        to: this.contacts.join(','),
        replyTo: loggedUserEmail,
        cc: cc ? `${loggedUserEmail}, ${cc}` : loggedUserEmail,
        subject: `${
          process.env.VUE_APP_ENV === 'prod' ? '' : 'TEST: '
        }SSC-to-SSC Cross-Sourcing Request: ${
          this.capabilities.length > 1
            ? 'Multiple Capabilities'
            : this.getCapability(this.functionCode[0], this.capabilities[0])
        }`,
        text: message,
        html:
          this.capabilities.length > 1
            ? `<div>
                <p>${requestingSSC}-to-${receivingSSC} Cross-Sourcing Request</p>
                <p style="margin-bottom: 0;">
                  <b>Capability:</b><br /> ${requestedCapabilities}
                </p>
                <p style="margin-bottom: 0;">
                  <b>Requested By:</b> ${fullName}
                </p>
                <p style="margin-bottom: 0;">
                  <b>Replies to this email message will go directly to</b> ${loggedUserEmail}
                </p>
                <p style="margin-bottom: 0;">
                  <b>Message:</b>
                </p>
                <br />
                ${message}
              </div>
              `
            : `<div>
                <p>${requestingSSC}-to-${receivingSSC} Sourcing Request</p>
                 <p style="margin-bottom: 0;">
                   <b>Capability:</b><br /> ${requestedCapabilities}
                 </p>
                 <p style="margin-bottom: 0;">
                   <b>Requested By:</b> ${fullName}
                 </p>
                 <p style="margin-bottom: 0;">
                   <b>Replies to this email message will go directly to</b> ${loggedUserEmail}
                 </p>
                 <br />
                 ${message}
              </div>`,
      })

      if (sendMail) {
        this.setPath({
          name: 'SscToSscSourcingRequestSubmitted',
          params: { email: this.contacts },
        })
      } else {
        this.setPath({
          name: 'ServiceUnavailable',
          params: {
            retryAction: false,
          },
        })
      }
    },
    getCapability(functionCode, CapCode) {
      return this.capabilityData
        .find((func) => func.FunctionCode === functionCode)
        ?.Capabilities.find((cap) => cap.CapCode === CapCode)?.CapName
    },
  },
  computed: {
    ...mapState('sourcing', ['capabilityData']),
    contacts() {
      return this.SSCSourcing.map((el) => el.Contact.toLowerCase()).filter(
        (contact, index, arr) => arr.indexOf(contact) === index,
      )
    },
    telFormat() {
      return this.sscData.phone && this.sscData.phone.replace(/\D/g, '')
    },
    capabilitiesList() {
      return this.capabilities?.join(', ')
    },
  },
  created() {
    this.capabilities = this.SSCSourcing.map((el) => el.Capability)
    this.functionCode = this.SSCSourcing.map((el) => el.Function)
  },
  data() {
    return {
      showHeaderMenu: true,
      emailInvalid: false,
      formData: {
        message: '',
        cc: '',
      },
    }
  },
}
</script>
