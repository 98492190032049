import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('user', ['setUserSsc']),
    ...mapActions('settings', ['getUserSSCSettings']),
  },
  computed: {
    ...mapGetters('user', ['isSsc', 'isSwcon']),
    ...mapState('user', ['userData']),
    isSscSelectorGuardValid() {
      return !(!this.isSsc && !this.userData?.ssc)
    },
  },
  created() {
    if (this.isSwcon && this.userData?.ssc?.uid !== 'SWCON') {
      this.formData.sscUid = 'SWCON'
      this.setUserSsc(this.formData)
      this.setPath({ name: 'Home' })
    }

    if (!this.isSscSelectorGuardValid) {
      this.setPath({ name: 'SscSelector' })
      return
    }

    this.getUserSSCSettings(this.userData?.ssc?.uid)
  },
  data() {
    return {
      formData: {
        sscUid: '',
      },
    }
  },
}
