<template>
  <div class="">
    <hr />
    <div v-if="filteredItems.length">
      <div class="py-2.5 grid grid-cols-12">
        <div class="font-bold text-headerColor col-span-4">Part number</div>
        <div
          class="
            font-bold
            text-headerColor text-center
            col-span-2
            justify-self-end
          "
        >
          Need
        </div>
        <div class="font-bold text-headerColor col-span-4 pl-4">Location</div>
        <div class="font-bold text-headerColor text-right col-span-2 items-end">
          Willing
        </div>
      </div>
      <hr />
      <transition-group name="transition-list" tag="div">
        <div
          class="cursor-pointer"
          :key="item.partNumber"
          v-for="(item, idx) in filteredItems"
          @click="$emit('itemClicked', item)"
        >
          <InventoryPoolingBuyItem :item="item" />
        </div>
      </transition-group>

      <router-link
        :disabled="!isAllConfirmed"
        tag="button"
        class="button-a uppercase mt-8 block text-center"
        to="/inventory-pooling/request-summary"
        >Preview Request(s)</router-link
      >
    </div>
    <div v-if="!filteredItems.length">
      <div class="my-2 text-center italic">No new parts to review</div>
      <hr />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import InventoryPoolingBuyItem from './InventoryPoolingBuyItem.vue'

export default {
  components: {
    InventoryPoolingBuyItem,
  },
  name: 'InventoryPoolingBuyTable',
  methods: {
    ...mapActions('router', ['setPath']),
    handleHelpClick() {
      this.setPath({
        name: this.helpPage,
      })
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter(
        (item) => item.requestingSSCBranch === this.activeBranch,
      )
    },
    isAllConfirmed() {
      return (
        this.items.filter((item) =>
          item.requests.some((r) => r.Status === 'Order'),
        ).length === this.items.length
      )
    },
  },
  props: ['items', 'helpPage', 'activeBranch'],
}
</script>
