<template>
  <Master>
    <div class="container" v-if="userData && isSscSelectorGuardValid">
      <UserCreds :userData="userData" :isSsc="isSsc" />
      <div class="grid grid-cols-2 gap-6 text-center mt-9">
        <div class="text-m text-headerColor cursor-pointer hover:bg-gray-100">
          <div
            @click="
              setPath({
                name: 'Inventory',
                routeMap: { goBackState: { name: 'Home' } },
              })
            "
          >
            <i
              class="font-icon icon-inventory text-[100px] text-brandGreen"
            ></i>
            <div class="px-10 py-2">Inventory Visibility</div>
          </div>
        </div>
        <div class="text-m text-headerColor cursor-pointer hover:bg-gray-100">
          <div
            @click="
              setPath({
                name: 'Configurator',
                routeMap: { goBackState: { name: 'Home' } },
              })
            "
          >
            <i
              class="font-icon icon-configurator text-[100px] text-brandGreen"
            ></i>
            <div class="px-4 py-2">
              <div>Multi-Piece Configurator</div>
              <div class="text-m text-red-700 font-semibold"></div>
            </div>
          </div>
        </div>
        <div
          class="text-m text-headerColor cursor-pointer hover:bg-gray-100"
          v-if="showInventoryPooling"
        >
          <div
            @click="
              setPath({
                name: 'InventoryPooling',
                routeMap: { goBackState: { name: 'Home' } },
              })
            "
          >
            <InventoryPoolingIcon class="w-[100px] m-auto" />
            <div class="px-4 py-2">
              <div>Inventory<br />Pooling</div>
              <div class="text-m text-red-700 font-semibold">BETA</div>
            </div>
          </div>
        </div>
        <div class="text-m text-headerColor cursor-pointer hover:bg-gray-100">
          <div
            @click="
              setPath({
                name: 'SscToSscSourcingSplashScreen',
                routeMap: { goBackState: { name: 'Home' } },
              })
            "
          >
            <SscToSscSourcingIcon class="max-w-[100px] m-auto" />
            <div class="px-4 py-2">
              <div>
                SSC to SSC <br />
                Cross-Sourcing
              </div>
              <div class="text-m text-red-700 font-semibold"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import InventoryPoolingIcon from '@/assets/icons/inventory-polilng-icon_adobe_express.svg'
import SscToSscSourcingIcon from '@/assets/icons/ssc-to-ssc-sourcing.svg'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'Home',
  mixins: [PageMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  components: {
    SscToSscSourcingIcon,
    InventoryPoolingIcon,
  },
  props: ['refresh'],
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('user', ['isSsc']),
    ...mapState('settings', ['userSSCSettings']),
    showInventoryPooling() {
      return this.userSSCSettings?.PoolingPartners?.length > 0
    },
  },
  methods: {
    ...mapMutations('inventory', { setInventoryDataMut: 'SET_INVENTORY_DATA' }),
    ...mapMutations('inventory', {
      setInventorySearchQueryMut: 'SET_SEARCH_QUERY',
    }),
    ...mapMutations('configurator', {
      setConfiguratorDataMut: 'SET_CONFIGURATOR_DATA',
    }),
    ...mapMutations('configurator', {
      setConfiguratorSearchQueryMut: 'SET_SEARCH_QUERY',
    }),
    ...mapMutations('layout', {
      setShowAdvancedSearchParams: 'SET_SHOW_ADVANCED_SEARCH_PARAMS',
    }),
    ...mapActions('inventory', ['setActivePoolingBranch']),
    ...mapActions('sourcing', [
      'setSourcingData',
      'setCurrentSelectedFunction',
      'setCurrentSelectedCapabilities',
      'setSscSourcingResults',
      'clearSSCCapacities',
    ]),
  },
  beforeRouteEnter(_to, from, next) {
    const { name } = from

    next((vm) => {
      if (
        name &&
        (name.toLowerCase().indexOf('sourcing') > -1 ||
          name.toLowerCase().indexOf('menu') > -1 ||
          name.indexOf('SscSelector') > -1)
      ) {
        vm.setCurrentSelectedFunction(null)
        vm.setCurrentSelectedCapabilities(null)
        vm.setSscSourcingResults([])
        vm.setSourcingData({})
        vm.clearSSCCapacities()
      }
    })
  },
  async created() {
    if (this.$route.params.refresh) {
      window.location.href
    }

    this.setActivePoolingBranch(null)
    this.setInventoryDataMut(null)
    this.setInventorySearchQueryMut(null)
    this.setConfiguratorDataMut(null)
    this.setConfiguratorSearchQueryMut(null)

    const userSSC = this.userData.ssc?.uid

    if (!userSSC) return

    this.setRouteMap({
      Menu: {
        goBackState: { name: 'Home' },
      },
    })
  },
}
</script>
