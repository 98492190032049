<template>
  <h1 class="text-2xl text-headerColor font-bold">
    {{ title }}
    <slot></slot>
  </h1>
</template>

<script>
export default {
  name: 'PageHeading',
  props: ['title'],
}
</script>
