<template>
  <div id="app" class="text-textColor h-screen">
    <template v-if="isInitialized">
      <template v-if="!isTouchDevice">
        <perfect-scrollbar ref="scroll" id="appWrapper">
          <Header />
          <transition :name="pageTransition.name" :mode="pageTransition.mode">
            <router-view class="" :key="$route.path"> </router-view>
          </transition>
          <Footer />
        </perfect-scrollbar>
      </template>
      <template v-else>
        <Header />
        <transition :name="pageTransition.name" :mode="pageTransition.mode">
          <router-view class="" :key="$route.path"> </router-view>
        </transition>
        <Footer />
      </template>
    </template>
    <loader v-else />
  </div>
</template>

<script>
import Footer from '@/components/common/Footer'
import Header from '@/components/common/Header'
import {
currentDateTimeObject as currentDate,
dateTimeConfig as options
} from '@/helpers/utils.js'
import eventBusService from '@/services/event-bus'
import settings from '@/settings'
import { DateTime } from 'luxon'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapGetters('user', ['isAuthorized']),
    ...mapState('user', ['userData']),
    ...mapState('layout', ['pageTransition']),
    ...mapState('app', ['isTouchDevice']),
  },
  methods: {
    ...mapActions('router', ['goPageBack', 'setPath']),
    ...mapActions('app', ['setAppData', 'doLogin', 'doLogout', 'reLogin']),
    ...mapActions('announcements', ['getActiveAnnouncements']),
    ...mapActions('settings', ['getUserSettings', 'getAppSettings']),
    parseUrlParams() {
      const queryParams = new URLSearchParams(window.location.search)
      const isPostLogin = queryParams.get(
        settings.app.queryTokens.postLoginToken,
      )

      const queryParamsWhitelist = []
      const allowedQueryParams = {}
      queryParams.forEach((value, key) => {
        if (queryParamsWhitelist.length && queryParamsWhitelist.includes(key))
          allowedQueryParams[key] = value
      })

      const routeData = {
        path: window.location.pathname,
        query: allowedQueryParams,
      }

      this.$router.replace(routeData)

      return {
        isPostLogin,
      }
    },
    redirectToAnnouncement(id) {
      if (
        this.$route.name !== 'AnnouncementsDetails' &&
        this.$route.name !== 'Feedback'
      ) {
        this.setPath({
          name: 'AnnouncementsDetails',
          params: { id },
        })
      }
    },
    async checkAnnouncements() {
      if (this.$route.name === 'Login') return

      const userSettings = await this.getUserSettings()
      if (!userSettings) return

      const { ReadAnnouncements } = userSettings

      if (this.isAuthorized) {
        currentDate.date = DateTime.utc().toFormat(options.date)
        currentDate.time = DateTime.utc().toFormat(options.time)

        const dateQueryString = Object.keys(currentDate)
          .map((key) => {
            return (
              encodeURIComponent(key) +
              '=' +
              encodeURIComponent(currentDate[key])
            )
          })
          .join('&')

        await this.getActiveAnnouncements(dateQueryString).then(
          (activeAnnouncements) => {
            if (activeAnnouncements.length == 0) return

            const activeAnnouncementsIds = activeAnnouncements.map(
              (el) => el.id,
            )

            if (!ReadAnnouncements) {
              return this.redirectToAnnouncement(activeAnnouncements[0].id)
            }

            const readAnnouncementsIds = ReadAnnouncements.map((el) => el.id)

            const unreadAnnouncementsIds = activeAnnouncementsIds.filter(
              (el) => !readAnnouncementsIds.includes(el),
            )

            if (unreadAnnouncementsIds.length > 0) {
              this.redirectToAnnouncement(unreadAnnouncementsIds[0])
            }
          },
        )
      }
    },
  },
  async created() {
    this.checkAnnouncements()
    this.getAppSettings()

    const { isPostLogin } = this.parseUrlParams()

    const bootData = {
      sessionType: isPostLogin || !!this.userData ? 'user' : 'anonymous',
    }

    await new Promise((resolve, reject) =>
      this.setAppData({ resolve, reject, bootData }),
    ).catch(async (error) => {
      console.error(error)
      if (window.localStorage.getItem('swagelokForceReloadOnError')) {
        window.localStorage.clear()
        await this.reLogin()
      } else {
        window.localStorage.clear()
        throw error
      }
    })

    window.localStorage.setItem('swagelokForceReloadOnError', true)

    eventBusService.$on('auth.notAuthorized', () => {
      if (this.userData.groups.length < 1) {
        this.setPath({
          name: 'Unauthorized',
        })

        return
      }
      this.reLogin()
    })

    eventBusService.$on(['inventory.updated', 'configurator.updated'], () => {
      this.$refs.scroll?.update()
    })

    eventBusService.$on('router.routeChanged', () => {
      this.checkAnnouncements()

      setTimeout(() => {
        if (this.$refs.scroll) {
          this.$refs.scroll.$el.scrollTop = 0
        }
        document.scrollingElement.scrollTop = 0
      }, 200)
    })

    if (this.userData && settings.services.gtm.isEnabled) {
      // vuex getters are not updated yet
      const isSsc = this.userData.groups?.includes(settings.user.groups.wsSsc)

      window.dataLayer = window.dataLayer || []
      const gtmUserObject = {
        setUserId: this.userData.authProviderId,
        setUserType: isSsc ? 'SSC' : 'Corporate',
      }
      if (isSsc) gtmUserObject.setUserWhoto = this.userData.ssc?.uid
      window.dataLayer.push({
        event: 'set_push_user_properties',
        ...gtmUserObject,
      })
    }

    this.isInitialized = true
  },
  beforeDestroy() {
    eventBusService.$off()
  },
  data() {
    return {
      isInitialized: false,
    }
  },
}
</script>
