<template>
  <Master>
    <div class="announcements">
      <div class="container">
        <div class="flex justify-between items-baseline mb-4">
          <PageHeading title="Add Announcement" />
        </div>
        <form @submit.prevent="submitAnnoucement">
          <input
            class="form-input-2"
            placeholder="Title"
            maxlength="100"
            @keyup="handleTitleChange"
          />

          <editor
            :init="{
              height: 312,
              menubar: false,
              plugins: ['lists', 'link', 'paste'],
              toolbar: 'bold numlist bullist link undo redo',
              placeholder: 'Body',
              content_style:
                'body {font-size: 14px;} .announcement_body::before {font-style: italic;} .announcement_body:focus-within::before{display: none;}',
              body_class: 'announcement_body',
            }"
            @input="handleBodyChange"
          />

          <div class="announcement-date grid gap-4 grid-cols-2 my-4">
            <div class="flex items-center justify-between">
              Start
              <input
                type="date"
                placeholder="yyyy-mm-dd"
                v-model="startDate"
                @change="handleDateTimeChange($event, 'startDate')"
                class="
                  w-[124px]
                  h-[40px]
                  border border-solid border-secondaryGray
                  px-2
                "
              />
            </div>
            <div class="flex items-center justify-between">
              <div>
                <input
                  type="time"
                  :value="startTime"
                  @change="handleDateTimeChange($event, 'startTime')"
                  class="
                    w-[124px]
                    h-[40px]
                    border border-solid border-secondaryGray
                    px-2
                  "
                />
                <span class="pl-4">ET</span>
              </div>
            </div>
            <div class="flex items-center justify-between">
              End
              <input
                type="date"
                placeholder="yyyy-mm-dd"
                :value="endDate"
                @change="handleDateTimeChange($event, 'endDate')"
                class="
                  w-[124px]
                  h-[40px]
                  border border-solid border-secondaryGray
                  px-2
                "
              />
            </div>
            <div class="flex items-center justify-between">
              <div>
                <input
                  type="time"
                  :value="endTime"
                  @change="handleDateTimeChange($event, 'endTime')"
                  class="
                    w-[124px]
                    h-[40px]
                    border border-solid border-secondaryGray
                    px-2
                  "
                />
                <span class="pl-4">ET</span>
              </div>
            </div>
          </div>

          <div class="text-center text-secondaryRed" v-if="isDateInvalid">
            The start date must be before the end date
          </div>

          <div class="announcement-form-actions flex gap-2.5 mt-10">
            <div class="flex-1">
              <button
                class="button-a border-solid border-2 border-brandGreen"
                :disabled="isFormInvalid"
              >
                SAVE
              </button>
            </div>
            <div class="flex-1">
              <button
                @click.prevent="setPath({ name: 'Announcements' })"
                class="button-b"
              >
                CANCEL
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Master>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import AuthenticatedGuardMixin from '@/mixins/AuthenticatedGuardMixin'
import Editor from '@tinymce/tinymce-vue'
import { DateTime } from 'luxon'

export default {
  name: 'AnnouncementsAdd',
  mixins: [PageMixin, LoaderMixin, AuthenticatedGuardMixin],
  components: {
    editor: Editor,
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('announcements', ['announcementData']),
  },
  methods: {
    ...mapActions('announcements', ['createAnnouncement']),
    ...mapMutations('announcements', {
      setAnnouncementData: 'SET_ANNOUNCEMENT_DATA',
    }),
    handleTitleChange(e) {
      this.title = e.target.value
    },
    handleBodyChange(editorContent) {
      this.body = editorContent
    },
    validateForm() {
      if (
        this.title.length < 8 ||
        this.body.length < 10 ||
        this.isDateInvalid === true
      ) {
        this.isFormInvalid = true
      } else {
        this.isFormInvalid = false
      }
    },
    handleDateTimeChange(e, params) {
      this[params] = e.target.value
      const startDate = this.handleSubmittedDate(this.startDate, this.startTime)
      const endDate = this.handleSubmittedDate(this.endDate, this.endTime)

      if (startDate > endDate) {
        this.isDateInvalid = true
        this.isFormInvalid = true
      } else {
        this.isDateInvalid = false
        this.isFormInvalid = false
      }
    },
    handleSubmittedDate(date, time) {
      const dateParts = date.split('-')
      const timeParts = time.split(':')

      return DateTime.fromObject(
        {
          year: dateParts[0],
          month: dateParts[1],
          day: dateParts[2],
          hour: timeParts[0],
          minute: timeParts[1],
        },
        { zone: 'America/New_York' },
      )
        .toUTC()
        .toISO()
    },
    submitAnnoucement() {
      const announcementFormData = {
        title: this.title,
        body: JSON.stringify(this.body),
        startDate: this.handleSubmittedDate(this.startDate, this.startTime),
        endDate: this.handleSubmittedDate(this.endDate, this.endTime),
      }

      if (announcementFormData.startDate >= announcementFormData.endDate) {
        this.isDateInvalid = true
        return
      }

      this.createAnnouncement(announcementFormData)
        .then((resp) => {
          if (resp && resp.id) {
            this.setPath({
              name: 'AnnouncementsDetails',
              params: {
                id: resp.id,
                editedAnnouncement: true,
              },
            })
          }
        })
        .catch((err) => console.error(err))
    },
  },
  watch: {
    title() {
      this.validateForm()
    },
    body() {
      this.validateForm()
    },
    announcementData() {
      if (this.announcementData.length > 0 && this.announcementData.isSent) {
        this.setPath({
          name: 'AnnouncementsDetails',
          params: {
            id: this.announcementData.id,
            newAnnouncement: true,
          },
        })
      }
    },
  },
  async created() {
    if (
      !this.userData.groups.some((group) =>
        ['wsAdmin', 'admin'].includes(group),
      )
    ) {
      this.setPath({
        name: 'Announcements',
      })
    }

    this.setAnnouncementData(null)

    const currentDate = new Date()

    const day =
      currentDate.getDate() < 10
        ? `0${currentDate.getDate()}`
        : currentDate.getDate()

    const month =
      currentDate.getMonth() + 1 < 10
        ? `0${currentDate.getMonth() + 1}`
        : currentDate.getMonth() + 1

    const year = currentDate.getFullYear()

    const hour =
      currentDate.getHours() < 10
        ? `0${currentDate.getHours()}`
        : currentDate.getHours()

    const minute =
      currentDate.getMinutes() < 10
        ? `0${currentDate.getMinutes()}`
        : currentDate.getMinutes()

    this.currentDate = `${year}-${month}-${day}`
    this.currentTime = `${hour}:${minute}`

    this.startDate = this.currentDate
    this.startTime = this.currentTime
    this.endDate = this.currentDate
    this.endTime = this.currentTime
  },
  data() {
    return {
      title: '',
      body: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      isFormInvalid: true,
      isDateInvalid: false,
      showHeaderMenu: true,
    }
  },
}
</script>
