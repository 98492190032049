<template>
  <div class="">
    <div class="flex justify-between py-2.5">
      <div class="font-bold text-headerColor w-[70%] flex">
        {{ header.cellA }}
        <button
          v-if="helpPage && !helpPageOnRight"
          @click="handleHelpClick"
          class="ml-2 w-[20px]"
        >
          <QuestionCircleSolid class="text-brandLightBlue" />
        </button>
      </div>
      <div
        :class="[
          'font-bold text-headerColor w-[30%] text-right flex items-center justify-end',
          withPlusSign ? 'pr-5' : '',
        ]"
      >
        {{ header.cellB }}

        <button
          v-if="helpPage && helpPageOnRight"
          @click="handleHelpClick"
          class="ml-2 w-[20px]"
        >
          <QuestionCircleSolid class="text-brandLightBlue" />
        </button>
      </div>
    </div>

    <hr class="-mx-2" />
    <transition-group name="transition-list" tag="div">
      <div
        class="transition-list-item"
        v-for="(item, idx) in orderedItems"
        @click="$emit('itemClicked', item)"
        :key="item[itemKey] + idx"
      >
        <div class="-mx-2 transition-colors hover:bg-gray-100 py-2">
          <div
            class="flex justify-between items-center py-2 px-2"
            :class="[
              itemHasCursor ? 'cursor-pointer' : null,
              item.isHome && options.homeNotClickable ? 'isHome' : null,
            ]"
          >
            <div class="min-w-0">
              <div class="flex">
                <div
                  class="text-headerColor mr-1 min-w-0"
                  :class="{
                    'font-bold': !options.cellARegularFont,
                    truncate: options.truncate,
                    'whitespace-pre-line': !options.truncate,
                    'brake-word': options.brakeWord,
                  }"
                >
                  {{ item[body.cellA.title] }}
                </div>
                <slot name="cellAIcon" v-if="item[body.cellA.hasIcon]"></slot>
              </div>
              <div class="truncate text-headerColor">
                {{ item[body.cellA.subtitle] }}
              </div>
            </div>
            <div
              v-if="withPlusSign"
              class="text-[22px] text-headerColor flex justify-between"
            >
              <span class="text-right">
                {{
                  item[body.cellB].includes('+')
                    ? item[body.cellB].slice(0, -1)
                    : item[body.cellB]
                }}
              </span>
              <span class="w-[13px]">
                {{ item[body.cellB].includes('+') ? '+' : '' }}
              </span>
            </div>
            <div
              v-if="!withPlusSign"
              class="text-[22px] text-headerColor text-right"
              :class="[
                options && options.trafficLights === 'displayComponents'
                  ? 'flex justify-between items-center'
                  : options && options.trafficLights === 'color'
                  ? 'flex justify-end items-end mr-5'
                  : null,
                helpPageOnRight ? 'pr-3' : null,
                item[body.cellB] && item[body.cellB].includes
                  ? !item[body.cellB].includes('+') && 'col-with-plus'
                  : '',
              ]"
              v-html="
                options && options.trafficLights === 'displayComponents'
                  ? (item['displayComponents'].every((el) => el.available > 0)
                      ? green
                      : item['displayComponents'].some((el) => el.available > 0)
                      ? yellow
                      : red) + item[body.cellB]
                  : options && options.trafficLights === 'color'
                  ? item[body.cellB] === 'green'
                    ? green
                    : item[body.cellB] === 'yellow'
                    ? yellow
                    : item[body.cellB] === 'red'
                    ? red
                    : unavailable
                  : item[body.cellB]
              "
            ></div>
          </div>
          <div
            v-if="footer && item[footer] && item[footer].length"
            class="px-2 font-medium text-sm"
          >
            <span>{{ item[footer] }}</span>
          </div>
          <div
            class="font-bold text-brandLightBlue text-xs flex gap-1 justify-end"
            v-if="options.showReadyNow && !item.isHome && item.readyNow"
          >
            <Star class="w-3" />
            Ready now
          </div>
        </div>

        <hr class="-mx-2" />
      </div>
    </transition-group>
  </div>
</template>
<script>
import QuestionCircleSolid from '@/assets/icons/question-circle-solid.svg'
import Star from '@/assets/icons/star.svg'
import { mapActions } from 'vuex'

export default {
  name: 'Table2',
  props: [
    'header',
    'itemKey',
    'body',
    'items',
    'itemHasCursor',
    'options',
    'helpPage',
    'helpPageOnRight',
    'orderBy',
    'footer',
    'routeMap',
    'withPlusSign',
  ],
  components: { QuestionCircleSolid, Star },
  methods: {
    ...mapActions('router', ['setPath']),
    handleHelpClick() {
      this.setPath(
        this.routeMap
          ? {
              name: this.helpPage,
              routeMap: this.routeMap,
            }
          : {
              name: this.helpPage,
            },
      )
    },
  },
  computed: {
    orderedItems() {
      if (this.orderBy) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.items.sort((a, b) =>
          a[this.orderBy].localeCompare(b[this.orderBy]),
        )
      }

      return this.items
    },
    green() {
      return `<span class='${this.trafficLight} bg-brandGreen'></span>`
    },
    yellow() {
      return `<span class='${this.trafficLight} bg-yellow-300'></span>`
    },
    red() {
      return `<span class='${this.trafficLight} bg-red-700'></span>`
    },
    unavailable() {
      return `<span class='icon-swg-circle-close flex items-center text-headerColor w-[20px] h-[20px]'></span>`
    },
  },
  data() {
    return {
      trafficLight: 'w-[20px] h-[20px] inline-block rounded-full',
    }
  },
}
</script>
<style scoped>
.isHome {
  cursor: initial;
}
</style>
