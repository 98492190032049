import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', ['isGuest', 'isAuthorized']),
  },
  created() {
    if (!this.isGuest) {
      this.setPath({ name: 'Home' })
    }
  },
}
