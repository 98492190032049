<template>
  <div class="flex flex-col">
    <div class="flex">
      <div class="font-bold text-headerColor truncate mr-1">
        {{ sscData.sscId }}
      </div>
      <div
        class="
          icon-swg-home
          text-brandYellow text-xl
          leading-none
          top-0.5
          relative
        "
      />
    </div>
    <div class="truncate text-headerColor">{{ sscData.sscName }}</div>
  </div>
</template>

<script>
import SvgHome from '@/assets/icons/home-solid.svg'

export default {
  name: 'ResultsHomeSsc',
  props: ['sscData'],
  components: {
    SvgHome,
  },
}
</script>
