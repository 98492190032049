<template>
  <Master>
    <div class="ssc-to-ssc-sourcing-splash-screen">
      <div class="container">
        <div class="flex justify-between items-baseline mb-4">
          <PageHeading title="SSC to SSC Cross-Sourcing Policies" />
        </div>
        <div v-show="pageLoaded">
          <p class="mb-4 leading-snug">
            Welcome to the SSC to SSC Cross-Sourcing Application. Users of the
            application must comply with all Distributor Policies, Process
            Documents, and applicable laws.
            <span v-if="!userAcceptedPolicies"
              >In particular, by checking the boxes set forth below, I affirm
              that I have read, understood, and will comply with the SSC to SSC
              Cross-Sourcing and Communication Policy and the Export Controls
              Policy.</span
            >
          </p>
          <form @submit.prevent="submitSSCAgreement" id="accept-ssc-sourcing">
            <div v-if="!userAcceptedPolicies">
              <label class="flex items-start mt-3 mb-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  v-model="acceptCSCP"
                /><span class="ml-3 text-textColor"
                  >I accept Swagelok's
                  <a
                    href="https://swagelok.sharepoint.com/:b:/s/sscresoucecenter/ESb0fJD-RdJFl_Y1SVTTTtUB7XH_DjVFa2sBVeB9_WvJvQ?e=HFrngO"
                    target="_blank"
                    class="link-a-inline font-bold"
                    >SSC to SSC Cross-Sourcing and Communication Policy</a
                  ></span
                >
              </label>
              <label class="flex items-start mt-4 mb-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  v-model="acceptECP"
                /><span class="ml-3 text-textColor"
                  >I accept Swagelok's
                  <a
                    href="https://swagelok.sharepoint.com/:b:/s/sscresoucecenter/EXFpAHQuERVFvIcuTaUfr8kBMm5-pvmDLW7j-o-zheLicA?e=vmV3H2"
                    target="_blank"
                    class="link-a-inline font-bold"
                    >Export Controls Policy</a
                  ></span
                >
              </label>
            </div>
            <button
              class="button-a mt-6"
              type="submit"
              :disabled="!acceptCSCP || !acceptECP"
            >
              <span v-if="!userAcceptedPolicies">ACCEPT AND </span>CONTINUE
            </button>
          </form>
        </div>
        <loader v-if="isLoading" />
      </div>
    </div>
  </Master>
</template>

<script>
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'SscToSscSourcingSplashScreen',
  mixins: [PageMixin, LoaderMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  methods: {
    ...mapActions('settings', [
      'setSSC2SSCPoliciesReadDate',
      'getSSC2SSCPoliciesReadDate',
    ]),
    submitSSCAgreement() {
      if (!this.userAcceptedPolicies) {
        this.isLoading = true
        this.setSSC2SSCPoliciesReadDate()
          .then((resp) => {
            if (resp.statusCode === 200) {
              this.userAcceptedPolicies = true
              this.setPath({ name: 'SscToSscSourcing' })
            }
          })
          .finally(() => (this.isLoading = false))
      } else {
        this.setPath({ name: 'SscToSscSourcing' })
      }
    },
  },
  computed: {
    ...mapGetters('settings', ['getSSC2SSCPoliciesReadDateData']),
    ...mapState('settings', ['SSC2SSCPoliciesReadDateData']),
  },
  created() {
    this.getSSC2SSCPoliciesReadDate().then(() => {
      if (
        this.SSC2SSCPoliciesReadDateData.hasOwnProperty(
          'SSC2SSCPoliciesReadDate',
        )
      ) {
        this.userAcceptedPolicies = true
        this.acceptCSCP = true
        this.acceptECP = true
      }

      this.isLoading = false
      this.pageLoaded = true
    })
  },
  data() {
    return {
      showHeaderMenu: true,
      pageLoaded: false,
      userAcceptedPolicies: false,
      acceptCSCP: false,
      acceptECP: false,
      isLoading: true,
    }
  },
}
</script>
