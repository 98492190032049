import HomeSSC from '@/components/HomeSSC'
import { OrderStatus } from '@/components/inventory/OrderStatus'
import * as dayjs from 'dayjs'

export const OrderHeader = ({ children }) => (
  <h1 class="text-2xl text-headerColor font-bold flex items-center justify-between mb-6">
    <div class="flex items-center gap-2">{children}</div>

    <HomeSSC />
  </h1>
)

export const Order = ({ props, children }) => {
  const { order, whoTo } = props
  const submittedAt = dayjs(order.RequestedDateTime).format('MM/DD/YYYY')

  return (
    <div>
      <div class="text-2xl text-headerColor font-bold mb-4 flex justify-between items-center">
        <span id="inv-requesting-ssc">{whoTo}</span>

        {order.Status === 'Approved' ? (
          <div class="text-sm">
            <OrderStatus
              status={order.Status}
              requestedDate={order.RequestedDateTime}
            />
          </div>
        ) : null}
      </div>

      <hr class="mb-2" />

      <div class="flex justify-between items-center text-sm font-bold text-headerColor">
        <div>Part number</div>
        <div>Qty</div>
      </div>

      <hr class="my-2" />

      <div class="flex justify-between items-center">
        <div id="inv-part-number" class="text-sm">{order.PartNumber}</div>
        <div id="inv-requested-quantity" class="text-[22px] text-headerColor">
          {order.Status === 'Approved'
            ? order.ApprovedQuantity
            : order.RequestedQuantity}
        </div>
      </div>

      <hr class="mt-2 mb-4" />

      {children}

      <div class="mb-4">
        <div class="text-sm font-bold text-headerColor mb-2">
          Request Submitted By
        </div>
        <div>
          <div class="text-sm">{order.RequestingUserName}</div>
        </div>
      </div>
      <div class="mb-4">
        <div class="text-sm font-bold text-headerColor mb-2">
          Date Submitted
        </div>
        <div>
          <div class="text-sm">{submittedAt}</div>
        </div>
      </div>

      {order.Status !== 'Approved' ? (
        <div class="mb-8">
          <div class="text-sm font-bold text-headerColor mb-2">
            Request Expires In
          </div>
          <div class="text-sm">
            <OrderStatus
              status={order.Status}
              requestedDate={order.RequestedDateTime}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}
