<template>
  <span class="block rounded-full w-[18px] h-[18px]" :class="brandColor"></span>
</template>

<script>
export default {
  name: 'TrafficLight',
  props: ['color'],
  computed: {
    brandColor() {
      if (this.color === 'green') {
        return 'bg-brandGreen'
      } else if (this.color === 'red') {
        return 'bg-redTrafficLight'
      } else {
        return 'bg-yellowTrafficLight'
      }
    },
  },
}
</script>
