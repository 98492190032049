<template>
  <Master>
    <div class="service-unavailable">
      <div class="container">
        <div class="flex justify-between items-baseline mb-4">
          <PageHeading title="Temporarily Unavailable" />
        </div>
        <div>
          The application is currently unavailable. If you believe this to be in
          error, please report the issue using the Help/Feedback link below.
        </div>
        <div class="mt-4">
          <div v-for="option in options" :key="option.title">
            <div class="-mx-2 hover:bg-gray-100" v-if="option.isVisible">
              <div
                class="
                  px-2
                  py-2
                  cursor-pointer
                  hover:bg-gray-100
                  transition-colors
                "
                @click="option.action"
              >
                <div class="link-a font-semibold" v-html="option.title"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import { mapActions } from 'vuex'
import PageMixin from '@/mixins/PageMixin'
import AuthenticatedGuardMixin from '@/mixins/AuthenticatedGuardMixin'
import LoaderMixin from '@/mixins/LoaderMixin'

export default {
  name: 'ServiceUnavailable',
  mixins: [PageMixin, LoaderMixin, AuthenticatedGuardMixin],
  props: ['retryAction'],
  computed: {
    options() {
      return [
        {
          title: 'Retry <span class="icon-swg-arrow-right"></span>',
          action: this.retryAction,
          isVisible: this.retryAction ? true : false,
        },
        {
          title: 'Help/Feedback <span class="icon-swg-arrow-right"></span>',
          action: () => {
            this.setPath({ name: 'Feedback' })
          },
          isVisible: true,
        },
        {
          title: 'Log Out <span class="icon-swg-arrow-right"></span>',
          action: this.onLogoutClick,
          isVisible: true,
        },
      ]
    },
  },
  methods: {
    ...mapActions('app', ['reLogin']),
    onLogoutClick() {
      this.withLoader(() => {
        return this.reLogin()
      })
    },
  },
  created() {
    if (this.retryAction === undefined) {
      this.setPath({ name: 'Home' })
    }
  },
  data() {
    return {
      showHeaderMenu: false,
    }
  },
}
</script>
