export default {
  methods: {
    getCapacityColor(capacity) {
      return capacity === 'Has Capacity'
        ? 'green'
        : capacity === 'Does Not Have Capacity'
        ? 'red'
        : 'unavailable'
    },
  },
}
