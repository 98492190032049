<template>
  <Master>
    <div class="feedback">
      <div class="container">
        <PageHeading title="Help/Feedback" class="mb-4" />

        <div v-if="feedbackData && feedbackData.isSent">
          <div class="font-bold text-headerColor">
            Your message has been sent!
          </div>
          <div class="">
            Thank you for your submission. A member of the project team will
            respond to you as soon as possible, generally within one business
            day.
          </div>
        </div>
        <div v-else-if="showErrorMessage">
          <div class="font-bold text-headerColor">Unexpected Error</div>
          <div class="">
            We're sorry, but an unexpected error occurred while attempting to
            send feedback.
          </div>
          <div class="mt-4">
            <div class="-mx-2 hover:bg-gray-100">
              <div
                class="
                  px-2
                  py-2
                  cursor-pointer
                  hover:bg-gray-100
                  transition-colors
                "
                @click="showErrorMessage = false"
              >
                <div
                  class="link-a font-semibold"
                  v-html="`Retry <span class='icon-swg-arrow-right'></span>`"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <ValidationObserver
          tag="form"
          ref="observer"
          class="relative"
          @submit.prevent="submitFeedback"
          v-slot="{ failed }"
          id="set-submit-feedback"
          v-else
        >
          <validation-provider
            tag="div"
            rules="required"
            name="Subject"
            v-slot="{ errors }"
          >
            <div class="w-full relative">
              <input
                class="form-input !pr-9"
                type="text"
                name="subject"
                :disabled="isLoading"
                v-model="formData.subject"
                placeholder="Add a subject"
                ref="subject"
                id="set-feedback-subject"
              />
              <SvgCancelCircle
                class="
                  absolute
                  right-4
                  top-3.5
                  w-6
                  h-6
                  fill-current
                  text-gray-300
                  hover:text-brandGray
                  cursor-pointer
                "
                :class="
                  isLoading || !formData.subject
                    ? 'hover:text-gray-300 cursor-default'
                    : ''
                "
                @click="
                  () => (isLoading || !formData.subject ? null : resetSubject())
                "
              />
            </div>
            <transition name="transition-fade">
              <div v-show="errors.length" class="absolute field-error">
                {{ errors[0] }}
              </div>
            </transition>
          </validation-provider>

          <validation-provider
            tag="div"
            rules="required"
            class="mt-5"
            name="Message"
            v-slot="{ errors }"
          >
            <div class="flex justify-between items-center">
              <textarea
                class="form-textarea h-[400px]"
                v-model="formData.message"
                :disabled="isLoading"
                type="text"
                placeholder="Request help, ask a question, or provide feedback"
                id="set-feedback-body"
              />
            </div>
            <transition name="transition-fade">
              <div v-show="errors.length" class="absolute field-error">
                {{ errors[0] }}
              </div>
            </transition>
          </validation-provider>

          <button
            class="button-a mt-8"
            type="submit"
            :disabled="isLoading || failed"
          >
            SUBMIT
          </button>
          <loader v-if="isLoading" />
        </ValidationObserver>
      </div>
    </div>
  </Master>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import SvgCancelCircle from '@/assets/icons/times-circle-solid.svg'
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import AuthenticatedGuardMixin from '@/mixins/AuthenticatedGuardMixin'

export default {
  name: 'Feedback',
  mixins: [PageMixin, LoaderMixin, AuthenticatedGuardMixin],
  components: {
    SvgCancelCircle,
  },
  computed: {
    ...mapState('feedback', ['feedbackData']),
  },
  methods: {
    ...mapActions('feedback', ['sendFeedback']),
    ...mapMutations('feedback', { setFeedbackData: 'SET_FEEDBACK_DATA' }),
    resetSubject() {
      this.formData.subject = ''
    },
    async submitFeedback() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return
      this.withLoader(() => {
        return this.sendFeedback(this.formData).catch((e) => {
          console.log(e)
          this.showErrorMessage = true
        })
      })
    },
  },
  async created() {
    this.setFeedbackData(null)
  },
  data() {
    return {
      showHeaderMenu: false,
      showErrorMessage: false,
      formData: {
        subject: '',
        message: '',
      },
    }
  },
}
</script>
