<template>
  <div
    id="inv-search-inventory-results"
    :data-inv-met-criteria-home="
      !!(sscData && sscData.entries.length > 0) && ''
    "
    :data-inv-truncated-results="
      !!(sscData && sscData.totalPartsFound > maxPartsPerSsc) && ''
    "
  >
    <div v-if="sscData && !isLoading">
      <div
        class="flex items-center -mt-3 mb-3"
        v-if="sscData.totalPartsFound > maxPartsPerSsc"
      >
        <SvgExclamationTriangle
          class="text-brandYellow w-[22px] h-[22px] fill-current"
        />
        <div class="flex flex-col ml-4">
          <div>Not all search results are being displayed.</div>
          <div>Refine your search for better results.</div>
        </div>
      </div>
      <hr class="-mx-2" />
      <ResultsHomeSsc :sscData="sscData" class="mt-2" />
      <hr class="-mx-2 mt-2" />
      <div class="">
        <table2
          :header="{
            cellA: 'Part Number',
            cellB: 'Available',
          }"
          :body="{
            cellA: {
              title: 'partNumber',
            },
            cellB: 'totalAvailable',
          }"
          :items="sscData.entries"
          :itemHasCursor="true"
          :options="{ cellARegularFont: true }"
          itemKey="partNumber"
          @itemClicked="(item) => showDetails(item)"
        >
        </table2>

        <div class="" v-if="showNoResult">
          <div class="mt-4">No parts found with minimum inventory.</div>
          <hr class="-mx-2 mt-4" />
        </div>

        <div
          v-if="!isSearchComplete"
          class="link-a mt-4 hover:text-brandGreen-darker font-semibold"
          @click="searchMoreHandler"
          id="inv-search-more-sscs"
        >
          Search More Locations <span class="icon-swg-arrow-right"></span>
        </div>
      </div>
    </div>
    <loader v-if="isLoading" />
  </div>
</template>

<script>
import SvgExclamationTriangle from '@/assets/icons/exclamation-triangle.svg'
import InventoryMixin from '@/mixins/InventoryMixin'
import { mapActions } from 'vuex'

export default {
  name: 'InventoryResultWildcardHome',
  mixins: [InventoryMixin],
  components: {
    SvgExclamationTriangle,
  },
  props: ['isLoading', 'minQty'],
  methods: {
    ...mapActions('router', ['setPath']),
    showDetails(entry) {
      this.setPath({
        name: 'InventorySscDetails',
        params: {
          partNumber: entry.partNumber,
          sscId: this.sscData.sscId,
          minQty: this.minQty,
        },
        routeMap: {
          goBackState: {
            name: 'Inventory',
          },
        },
      })
    },
    showHelpReserve() {
      this.setPath({
        name: 'InventoryHelpReserve',
        routeMap: {
          goBackState: {
            name: 'Inventory',
          },
        },
      })
    },
    getSscData() {
      return this.partsForHomeSsc || this.homeSscDummy
    },
    searchMoreHandler() {
      this.sscData = null
      this.$emit('searchMoreClicked')
    },
  },
  computed: {
    showNoResult() {
      return !this.isLoading && !this.sscData?.entries.length
    },
    isSearchComplete() {
      if (this.sscData.entries.length > 0) {
        this.sscData.entries.forEach((ssc) => {
          ssc.totalAvailable = ssc.totalAvailable.toLocaleString(
            navigator.language ?? 'default',
          )
          ssc.totalReserved = ssc.totalReserved.toLocaleString(
            navigator.language ?? 'default',
          )
        })
      }
      return this.inventoryData.isComplete
    },
  },
  created() {
    if (!this.isLoading) {
      this.sscData = this.getSscData()

      if (this.showNoResult && !this.isSearchComplete) {
        this.$emit('searchMoreClicked')
      }
    }
    this.$watch('isLoading', (nv) => {
      if (nv === false) {
        this.sscData = this.getSscData()

        if (this.showNoResult && !this.isSearchComplete) {
          this.$emit('searchMoreClicked')
        }
      }
    })
  },
  data() {
    return {
      sscData: null,
    }
  },
}
</script>
