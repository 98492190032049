<template>
  <Master>
    <div class="configurator-help">
      <div class="container">
        <ul
          class="mt-2 mb-4"
          v-for="capabilitityInfo in capabilities"
          :key="capabilitityInfo.CapCode"
        >
          <li class="mt-4 mb-6">
            <b class="text-headerColor">{{ capabilitityInfo.CapName }}</b>
            <p class="mt-2 text-strategy">
              {{ capabilitityInfo.CapDesc }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </Master>
</template>

<script>
import { mapState } from 'vuex'
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'

export default {
  name: 'SscToSscSourcingCapabilitiesHelp',
  mixins: [PageMixin, LoaderMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  props: ['sourcingCapability', 'sourcingfunction'],
  computed: {
    ...mapState('sourcing', ['capabilitiesInfo', 'capabilityData']),
    capabilities() {
      return this.capabilityData
        .find((el) => el.FunctionCode === this.sourcingfunction)
        ?.Capabilities?.sort((a, b) => a.CapName.localeCompare(b.CapName))
    },
  },
  data() {
    return {
      showHeaderMenu: true,
    }
  },
}
</script>
