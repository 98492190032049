<template>
  <Master>
    <div class="container" v-if="!settingLoading">
      <div class="flex justify-between items-baseline mb-6">
        <PageHeading :title="title" />
      </div>

      <ValidationObserver
        tag="form"
        ref="observer"
        @submit.prevent="save"
        v-slot="{ invalid }"
        class="grid"
        id="sourcing-config"
      >
        <Input
          label="Contact"
          placeholder="Enter SSC contact email"
          v-model="formData.IVAEmail"
          name="IVAEmail"
          type="text"
          :showClearButton="true"
        />

        <small v-if="emailError" class="text-secondaryRed -mt-4 mb-2">
          Please, add a valid email address
        </small>

        <Input
          label="Phone"
          placeholder="Enter SSC phone"
          v-model="formData.IVAPhone"
          name="IVAPhone"
          type="phone"
          :showClearButton="true"
        />

        <button
          class="button-a mt-6"
          type="submit"
          :disabled="isLoading || invalid"
        >
          SUBMIT
        </button>
      </ValidationObserver>
    </div>

    <div
      v-else
      class="
        relative
        min-h-full min-w-full
        flex
        justify-center
        items-center
        h-screen
      "
    >
      <loader />
    </div>
  </Master>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'

export default {
  name: 'AdminSSCContactInfo',
  mixins: [PageMixin, LoaderMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  computed: {
    ...mapGetters('inventory', ['homeSscDummy']),
    title() {
      return `${this.homeSscDummy.sscId} IVA Admin`
    },
  },
  methods: {
    ...mapActions('router', ['setPath']),
    ...mapActions('settings', ['getSSCSettings', 'updateSSCSettings']),
    isValidEmail(email) {
      return !!email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      )
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return

      if (!this.isValidEmail(this.formData.IVAEmail)) {
        this.emailError = true
        return
      }

      this.emailError = false

      this.isLoading = true
      try {
        this.updateSSCSettings({
          id: this.homeSscDummy.sscId,
          operations: [
            {
              op: 'set',
              path: '/IVAEmail',
              value: this.formData.IVAEmail,
            },
            {
              op: 'set',
              path: '/IVAPhone',
              value: this.formData.IVAPhone,
            },
          ],
        })

        this.setPath({
          name: 'Inventory',
          routeMap: { goBackState: { name: 'Home' } },
        })
      } catch {}

      this.isLoading = false
    },
  },
  async created() {
    try {
      const {
        resource: { IVAEmail, IVAPhone },
      } = await this.getSSCSettings(this.homeSscDummy.sscId)
      this.formData.IVAEmail = IVAEmail || ''
      this.formData.IVAPhone = IVAPhone || ''
      this.settingLoading = false
    } catch (e) {
      console.log(e)
    }
  },
  data() {
    return {
      settingLoading: true,
      emailError: false,
      formData: {
        IVAPhone: '',
        IVAEmail: '',
      },
    }
  },
}
</script>
