<template>
  <Master>
    <div class="container" v-if="userData">
      <UserCreds :userData="userData" :isSsc="isSsc" />
      <hr class="mt-4 -mx-2" />
      <div
        v-for="option in options.filter((el) => el.isVisible)"
        :key="option.title"
      >
        <div class="-mx-2 hover:bg-gray-100">
          <div
            class="px-2 py-3 cursor-pointer hover:bg-gray-100 transition-colors"
            @click="option.action"
          >
            <div
              class="link-main-menu"
              v-html="option.title"
              :id="option.id"
            ></div>
          </div>
        </div>
        <hr class="-mx-2" />
      </div>
      <loader v-if="isLoading" />
    </div>
  </Master>
</template>

<script>
import AuthenticatedGuardMixin from '@/mixins/AuthenticatedGuardMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import PageMixin from '@/mixins/PageMixin'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Menu',
  mixins: [PageMixin, LoaderMixin, AuthenticatedGuardMixin],
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('settings', ['isIVAOrderingFunctionalityEnabled']),
    ...mapGetters('user', [
      'isGuest',
      'isSsc',
      'isAuthorized',
      'isSwcon',
      'isInOrderingGroup',
      'isInApproverGroup',
      'isAdmin',
    ]),
    options() {
      return [
        {
          title: 'Main Menu',
          id: 'set-menu-home',
          action: () => {
            this.setPath({ name: 'Home' })
          },
          isVisible: true,
        },
        {
          title: 'Change SSC',
          id: 'set-menu-change-ssc',
          action: () => {
            this.setPath({ name: 'SscSelector' })
          },
          isVisible: !this.isSsc && this.isAuthorized && !this.isSwcon,
        },
        {
          title: 'Order Requests',
          id: 'set-menu-order-requests',
          action: () => {
            this.setPath({ name: 'OrderRequestsNotifications' })
          },
          isVisible:
            this.isAuthorized &&
            (((this.isInApproverGroup || this.isInOrderingGroup) &&
              this.isIVAOrderingFunctionalityEnabled) ||
              this.isAdmin),
        },
        {
          title: 'Request History',
          id: 'set-menu-order-history',
          action: () => {
            this.setPath({ name: 'OrderRequestsHistory' })
          },
          isVisible:
            this.isAuthorized &&
            (((this.isInApproverGroup || this.isInOrderingGroup) &&
              this.isIVAOrderingFunctionalityEnabled) ||
              this.isAdmin),
        },
        {
          title: 'Settings',
          id: 'set-menu-settings',
          action: () => {
            this.setPath({
              name: 'Settings',
              params: {
                action: 'menu',
              },
            })
          },
          isVisible: true,
        },
        {
          title: 'Announcements',
          id: 'set-menu-announcements',
          action: () => {
            this.setPath({
              name: 'Announcements',
              params: {
                action: 'menu',
              },
            })
          },
          isVisible: true,
        },
        {
          title: 'Help/Feedback',
          id: 'set-menu-help-feedback',
          action: () => {
            this.setPath({ name: 'Feedback' })
          },
          isVisible: true,
        },
        {
          title: 'About',
          id: 'set-menu-about',
          action: () => {
            this.setPath({ name: 'About' })
          },
          isVisible: true,
        },
        {
          title: 'Log Out',
          id: 'set-menu-logout',
          action: this.onLogoutClick,
          isVisible: true,
        },
      ]
    },
  },
  methods: {
    ...mapActions('app', ['reLogin']),
    onLogoutClick() {
      this.withLoader(() => {
        return this.reLogin()
      })
    },
  },
  data() {
    return {
      showHeaderMenu: false,
    }
  },
}
</script>
