<template>
  <Master>
    <loader v-if="!history || !history.outgoing" />

    <div v-if="history && history.outgoing" class="container">
      <PageHeading title="Request History" class="flex justify-between">
        <HomeSSC />
      </PageHeading>
      <hr class="my-2" />

      <InventoryOrderList
        v-if="isInOrderingGroup || isAdmin"
        :items="history.outgoing"
        type="outgoing"
      />
      <div class="mt-4 mb-8" v-if="isInOrderingGroup || isAdmin">
        <router-link
          class="link-a font-semibold"
          to="/order-requests-history/outgoing"
        >
          View Full Request History
        </router-link>
      </div>

      <InventoryOrderList
        v-if="isInApproverGroup || isAdmin"
        :items="history.incoming"
        type="incoming"
      />
      <div class="my-4" v-if="isInApproverGroup || isAdmin">
        <router-link
          class="link-a font-semibold"
          to="/order-requests-history/incoming"
        >
          View Full Request History
        </router-link>
      </div>
    </div>
  </Master>
</template>

<script>
import HomeSSC from '@/components/HomeSSC'
import { InventoryOrderList } from '@/components/inventory/InventoryOrderList'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import PageMixin from '@/mixins/PageMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderRequestsHistory',
  mixins: [PageMixin, AuthorizedGuardMixin],
  components: { HomeSSC, InventoryOrderList },
  computed: {
    ...mapGetters('user', [
      'isInOrderingGroup',
      'isInApproverGroup',
      'isAdmin',
    ]),
    history() {
      return this.$store.state.orders.shortHistory
    },
  },
  created() {
    this.$store.dispatch('getShortOrderHistory')
  },
}
</script>
