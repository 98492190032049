export default {
  methods: {
    withLoader(cb) {
      this.isLoading = true
      return cb().finally(() => {
        this.isLoading = false
      })
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
}
