import eventBusService from '@/services/event-bus'
import Vue from 'vue'
import VueRouter from 'vue-router'

import About from '@/views/About.vue'
import AdminSSCContactInfo from '@/views/AdminSSCContactInfo.vue'
import Announcements from '@/views/Announcements'
import AnnouncementsAdd from '@/views/AnnouncementsAdd'
import AnnouncementsDetails from '@/views/AnnouncementsDetails'
import AnnouncementsEdit from '@/views/AnnouncementsEdit'
import ApprovedRequest from '@/views/ApprovedRequest'
import ConfigurationDetails from '@/views/ConfigurationDetails.vue'
import Configurator from '@/views/Configurator.vue'
import ConfiguratorHelp from '@/views/ConfiguratorHelp.vue'
import ConsentDetails from '@/views/ConsentDetails.vue'
import FavoriteAdd from '@/views/FavoriteAdd'
import FavoriteList from '@/views/FavoriteList'
import Feedback from '@/views/Feedback.vue'
import Home from '@/views/Home.vue'
import IncomingRequest from '@/views/IncomingRequestV.vue'
import Inventory from '@/views/Inventory.vue'
import InventoryHelpMinQty from '@/views/InventoryHelpMinQty.vue'
import InventoryHelpReserve from '@/views/InventoryHelpReserve.vue'
import InventoryPartDetails from '@/views/InventoryPartDetails.vue'
import InventoryPoolingBuyDetail from '@/views/InventoryPoolingBuyDetail.vue'
import InventoryPoolingDetails from '@/views/InventoryPoolingDetails.vue'
import InventoryPoolingDetailsBranch from '@/views/InventoryPoolingDetailsBranch.vue'
import InventoryPoolingPartNumberHelp from '@/views/InventoryPoolingPartNumberHelp.vue'
import InventoryPoolingPartnerHelp from '@/views/InventoryPoolingPartnerHelp.vue'
import InventoryPoolingSellDetail from '@/views/InventoryPoolingSellDetail.vue'
import InventoryPoolingV2 from '@/views/InventoryPoolingV.vue'
import InventorySscDetails from '@/views/InventorySscDetails.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import Menu from '@/views/Menu.vue'
import OrderRequestsHistory from '@/views/OrderRequestsHistory.vue'
import OrderRequestsHistoryFull from '@/views/OrderRequestsHistoryFull.vue'
import OrderRequestsNotifications from '@/views/OrderRequestsNotifications.vue'
import OutgoingRequest from '@/views/OutgoingRequest.vue'
import RequestSummary from '@/views/RequestSummary.vue'
import RequestsSubmitted from '@/views/RequestsSubmitted.vue'
import ServiceUnavailable from '@/views/ServiceUnavailable.vue'
import Settings from '@/views/Settings'
import SettingsInfo from '@/views/SettingsInfo'
import SscSelector from '@/views/SscSelector.vue'
import SscToSscSourcing from '@/views/SscToSscSourcing.vue'
import SscToSscSourcingAdmin from '@/views/SscToSscSourcingAdmin.vue'
import SscToSscSourcingAdminDetails from '@/views/SscToSscSourcingAdminDetails.vue'
import SscToSscSourcingAdminUpdateCapabilities from '@/views/SscToSscSourcingAdminUpdateCapabilities.vue'
import SscToSscSourcingCapabilitiesHelp from '@/views/SscToSscSourcingCapabilitiesHelp.vue'
import SscToSscSourcingDetails from '@/views/SscToSscSourcingDetails.vue'
import SscToSscSourcingHelp from '@/views/SscToSscSourcingHelp.vue'
import SscToSscSourcingRequestMultiple from '@/views/SscToSscSourcingRequestMultiple.vue'
import SscToSscSourcingRequestMultipleSubmitted from '@/views/SscToSscSourcingRequestMultipleSubmitted.vue'
import SscToSscSourcingRequestSubmitted from '@/views/SscToSscSourcingRequestSubmitted.vue'
import SscToSscSourcingSplashScreen from '@/views/SscToSscSourcingSplashScreen.vue'
import Unauthorized from '@/views/Unauthorized.vue'

const suppressErrCb = (err) => {
  if (err.name != 'NavigationDuplicated') {
    console.error(err)
  }
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(suppressErrCb)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(suppressErrCb)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/inventory/help/reserve',
    name: 'InventoryHelpReserve',
    component: InventoryHelpReserve,
  },
  {
    path: '/inventory/help/minimum-quantity',
    name: 'InventoryHelpMinQty',
    component: InventoryHelpMinQty,
  },
  {
    path: '/inventory/:partNumber/:sscId/',
    name: 'InventorySscDetails',
    component: InventorySscDetails,
    props: true,
  },
  {
    path: '/inventory/:partNumber/',
    name: 'InventoryPartDetails',
    component: InventoryPartDetails,
    props: true,
  },
  {
    path: '/inventory-pooling',
    name: 'InventoryPooling',
    component: InventoryPoolingV2,
    props: false,
  },
  {
    path: '/inventory-pooling-v2',
    name: 'InventoryPoolingV2',
    component: InventoryPoolingV2,
    props: false,
  },
  {
    path: '/inventory-pooling/sell/:partNumber',
    name: 'InventoryPoolingSellDetail',
    component: InventoryPoolingSellDetail,
    props: false,
  },
  {
    path: '/inventory-pooling/buy/:partNumber',
    name: 'InventoryPoolingBuyDetail',
    component: InventoryPoolingBuyDetail,
    props: false,
  },
  {
    path: '/inventory-pooling/request-summary',
    name: 'RequestSummary',
    component: RequestSummary,
    props: false,
  },
  {
    path: '/inventory-pooling-details/:partNumber',
    name: 'InventoryPoolingDetails',
    component: InventoryPoolingDetails,
    props: true,
  },
  {
    path: '/inventory-pooling-details/:branchName',
    name: 'InventoryPoolingDetailsBranch',
    component: InventoryPoolingDetailsBranch,
    props: true,
  },
  {
    path: '/inventory-pooling-part-number-help',
    name: 'InventoryPoolingPartNumberHelp',
    component: InventoryPoolingPartNumberHelp,
    props: false,
  },
  {
    path: '/inventory-pooling-partner-help',
    name: 'InventoryPoolingPartnerHelp',
    component: InventoryPoolingPartnerHelp,
    props: false,
  },
  {
    path: '/order-requests',
    name: 'OrderRequestsNotifications',
    component: OrderRequestsNotifications,
    props: true,
  },
  {
    path: '/order-requests/incoming',
    name: 'IncomingRequest',
    component: IncomingRequest,
    props: true,
  },
  {
    path: '/order-requests/outgoing',
    name: 'OutgoingRequest',
    component: OutgoingRequest,
    props: true,
  },
  {
    path: '/order-requests/approved',
    name: 'ApprovedRequest',
    component: ApprovedRequest,
    props: true,
  },
  {
    path: '/order-requests-history/:type',
    name: 'OrderRequestsHistoryFull',
    component: OrderRequestsHistoryFull,
    props: true,
  },
  {
    path: '/order-requests-history',
    name: 'OrderRequestsHistory',
    component: OrderRequestsHistory,
    props: true,
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory,
    props: true,
  },
  {
    path: '/requests-submitted',
    name: 'RequestsSubmitted',
    component: RequestsSubmitted,
    props: false,
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu,
    props: true,
  },
  {
    path: '/menu/feedback',
    name: 'Feedback',
    component: Feedback,
  },
  {
    path: '/menu/user/ssc',
    name: 'SscSelector',
    component: SscSelector,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/settings-info',
    name: 'SettingsInfo',
    component: SettingsInfo,
  },
  {
    path: '/favorite-list',
    name: 'FavoriteList',
    component: FavoriteList,
  },
  {
    path: '/favorite-add',
    name: 'FavoriteAdd',
    component: FavoriteAdd,
  },
  {
    path: '/menu/announcements/:action',
    name: 'Announcements',
    component: Announcements,
    props: true,
  },
  {
    path: '/menu/announcements-add',
    name: 'AnnouncementsAdd',
    component: AnnouncementsAdd,
  },
  {
    path: '/menu/announcements-edit/:id',
    name: 'AnnouncementsEdit',
    component: AnnouncementsEdit,
    props: true,
  },
  {
    path: '/menu/announcements-details/:id',
    name: 'AnnouncementsDetails',
    component: AnnouncementsDetails,
    props: true,
  },
  {
    path: '/ssc-to-ssc-sourcing-home',
    name: 'SscToSscSourcingSplashScreen',
    component: SscToSscSourcingSplashScreen,
    props: false,
  },
  {
    path: '/ssc-to-ssc-sourcing',
    name: 'SscToSscSourcing',
    component: SscToSscSourcing,
    props: false,
  },
  {
    path: '/ssc-to-ssc-sourcing-help',
    name: 'SscToSscSourcingHelp',
    component: SscToSscSourcingHelp,
    props: false,
  },
  {
    path: '/ssc-to-ssc-sourcing/:sscId',
    name: 'SscToSscSourcingDetails',
    component: SscToSscSourcingDetails,
    props: true,
  },
  {
    path: '/ssc-to-ssc-sourcing-admin',
    name: 'SscToSscSourcingAdmin',
    component: SscToSscSourcingAdmin,
    props: false,
  },
  {
    path: '/ssc-to-ssc-sourcing-admin-update-capabilities',
    name: 'SscToSscSourcingAdminUpdateCapabilities',
    component: SscToSscSourcingAdminUpdateCapabilities,
    props: false,
  },
  {
    path: '/ssc-to-ssc-sourcing-admin-details',
    name: 'SscToSscSourcingAdminDetails',
    component: SscToSscSourcingAdminDetails,
    props: true,
  },
  {
    path: '/ssc-to-ssc-sourcing-capabilities-help',
    name: 'SscToSscSourcingCapabilitiesHelp',
    component: SscToSscSourcingCapabilitiesHelp,
    props: true,
  },
  {
    path: '/ssc-to-ssc-sourcing-request-submitted',
    name: 'SscToSscSourcingRequestSubmitted',
    component: SscToSscSourcingRequestSubmitted,
    props: true,
  },
  {
    path: '/ssc-to-ssc-sourcing-request-multiple',
    name: 'SscToSscSourcingRequestMultiple',
    component: SscToSscSourcingRequestMultiple,
    props: true,
  },
  {
    path: '/ssc-to-ssc-sourcing-request-multiple-submitted',
    name: 'SscToSscSourcingRequestMultipleSubmitted',
    component: SscToSscSourcingRequestMultipleSubmitted,
    props: true,
  },
  {
    path: '/consent-details',
    name: 'ConsentDetails',
    component: ConsentDetails,
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
  },
  {
    path: '/service-unavailable',
    name: 'ServiceUnavailable',
    component: ServiceUnavailable,
    props: true,
  },
  {
    path: '/menu/about',
    name: 'About',
    component: About,
  },
  {
    path: '/configurator',
    name: 'Configurator',
    component: Configurator,
  },
  {
    path: '/configurator/:variant',
    name: 'ConfigurationDetails',
    component: ConfigurationDetails,
    props: true,
  },
  {
    path: '/configurator/help',
    name: 'ConfiguratorHelp',
    component: ConfiguratorHelp,
  },
  {
    path: '/ssc-contact-info',
    name: 'AdminSSCContactInfo',
    component: AdminSSCContactInfo,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach(() => {
  eventBusService.$emit('router.routeChanged')
})

export default router
