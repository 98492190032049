<template>
  <Master>
    <div class="configurator-help">
      <div class="container">
        <PageHeading title="Request Submitted" />
        <p class="mt-4">
          Your request has been submitted via email<span v-if="email.length">
            to
            <p v-for="emailAddress in email" :key="emailAddress">
              <b>{{ emailAddress }}</b>
            </p></span
          >
        </p>

        <button
          @click.prevent="
            setPath({ name: 'SscToSscSourcing', params: { clearState: true } })
          "
          class="mt-6 font-bold text-brandGreen"
        >
          Return to SSC to SSC Cross-Sourcing Home
          <i class="icon-right-open-big ml-2"></i>
        </button>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'

export default {
  name: 'SscToSscSourcingRequestSubmitted',
  mixins: [PageMixin, LoaderMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  props: ['email'],
  data() {
    return {
      showHeaderMenu: true,
    }
  },
}
</script>
