<template>
  <div>
    <FormSelectWrapper>
      <select
        class="form-select"
        v-model="activeBranch"
        :disabled="!branches"
        @change="
          (e) => {
            this.changeBranch(e.target.value)
          }
        "
      >
        <template v-for="ssc in branches">
          <option
            :value="ssc.swagelokBranch"
            :key="ssc.swagelokBranch"
            :selected="activeBranch === ssc.swagelokBranch"
          >
            {{ ssc.swagelokBranch }}
            {{
              ssc.swagelokBranchName ? ` &mdash; ${ssc.swagelokBranchName}` : ''
            }}
          </option>
        </template>
      </select>
    </FormSelectWrapper>
  </div>
</template>
<script>
export default {
  name: 'BranchSelect',
  methods: {
    changeBranch(branch) {
      this.$store.dispatch('inventory/setActivePoolingBranch', branch)
      this.$emit('change', branch)
    },
  },
  async created() {
    this.activeBranch =
      this.branches.find(
        (i) =>
          this.$store.state.inventory.inventoryPoolingBranch ===
          i.swagelokBranch,
      )?.swagelokBranch ?? this.branches[0].swagelokBranch
    this.changeBranch(this.activeBranch)
  },
  data: () => ({
    activeBranch: '',
  }),
  props: ['branches'],
}
</script>
