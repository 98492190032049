<template>
  <div class="menu-open--account">
    <PageHeading :title="userData.fullName" />
    <h4 class="text-m text-headerColor text-[16px] mt-1">
      {{ userUid }}
    </h4>
    <h4
      class="text-m text-headerColor mt-1 -mb-2"
      v-if="!isSsc && userData.ssc"
    >
      {{ `${userData.ssc.uid} — ${userData.ssc.name}` }}
    </h4>
  </div>
</template>

<script>
export default {
  name: 'UserCreds',
  props: ['userData', 'isSsc'],
  computed: {
    userUid() {
      return this.userData.uid.toLowerCase()
    },
  },
}
</script>
