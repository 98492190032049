<template>
  <div class="">
    <div class="flex justify-between py-2.5">
      <div
        class="font-bold text-headerColor w-[60%]"
        :class="helpPage && 'flex items-center'"
      >
        {{ header.cellA }}
        <button v-if="helpPage" @click="handleHelpClick" class="ml-2 w-[20px]">
          <QuestionCircleSolid class="text-brandLightBlue" />
        </button>
      </div>
      <div class="font-bold text-headerColor w-[40%] text-right">
        {{ header.cellB }}
      </div>
    </div>
    <hr class="-mx-2" />
    <transition-group name="transition-list" tag="div">
      <div
        class="transition-list-item"
        v-for="(item, idx) in items"
        @click="$emit('itemClicked', item)"
        :key="item[itemKey] + idx"
      >
        <div class="-mx-2 transition-colors hover:bg-gray-100">
          <div
            class="flex justify-between items-center py-2 px-2"
            :class="itemHasCursor ? 'cursor-pointer' : null"
          >
            <div class="w-[60%]">
              <div class="flex">
                <div
                  class="text-headerColor mr-1"
                  :class="{
                    'font-bold': !options.cellARegularFont,
                    truncate: options.truncate,
                    'whitespace-pre-line': !options.truncate,
                  }"
                >
                  {{ item[body.cellA.title] }}
                </div>
                <slot name="cellAIcon" v-if="item[body.cellA.hasIcon]"></slot>
              </div>
              <div class="truncate text-headerColor">
                {{ item[body.cellA.subtitle] }}
              </div>
            </div>
            <!--
              Parameters:

              :trafficLightItem => Array of strings with the fields that shows traffic lights
                - The Traffic Light components in this table is rendered based on a method that calculates and
                  return the color based on the values provided by the cellB

              :hideValue => Array of strings with the fields that hides the value

              In case one of these are not provided, the code will follow the v-else (normal path)
             -->
            <div
              class="text-[22px] text-headerColor w-[40%] text-right"
              v-if="trafficLightItems && hideValue"
            >
              <div
                class="flex"
                :class="
                  trafficLightItems.includes(body.cellB) &&
                  !hideValue.includes(body.cellB)
                    ? 'justify-between items-center'
                    : 'justify-end items-center'
                "
              >
                <TrafficLight
                  v-if="trafficLightItems.includes(body.cellB)"
                  :color="item['trafficLight']"
                  :class="
                    trafficLightItems.includes(body.cellB) &&
                    !hideValue.includes(body.cellB)
                      ? 'ml-4'
                      : ''
                  "
                />
                <div v-if="!hideValue.includes(body.cellB)">
                  {{ item[body.cellB] }}
                </div>
              </div>
            </div>
            <div v-else class="text-[22px] text-headerColor w-[30%] text-right">
              {{ item[body.cellB] }}
            </div>
          </div>
        </div>
        <hr class="-mx-2" />
      </div>
    </transition-group>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import TrafficLight from '@/components/common/TrafficLight'
import QuestionCircleSolid from '@/assets/icons/question-circle-solid.svg'

export default {
  name: 'InventoryTable2Columns',
  components: { TrafficLight, QuestionCircleSolid },
  methods: {
    ...mapActions('router', ['setPath']),
    handleHelpClick() {
      this.setPath({
        name: this.helpPage,
      })
    },
  },
  props: [
    'header',
    'itemKey',
    'body',
    'items',
    'itemHasCursor',
    'options',
    'trafficLightItems',
    'hideValue',
    'helpPage',
  ],
}
</script>
