<template>
  <Master>
    <loader v-if="!notifications || notificationsLoading" />

    <div class="container" v-if="notifications">
      <PageHeading title="Order Requests" class="flex justify-between">
        <HomeSSC />
      </PageHeading>
      <hr class="my-2" />

      <InventoryOrderList
        v-if="isInOrderingGroup || isAdmin"
        @remove="
          ({ id, partNumber }) => {
            $store.dispatch('cleanNotification', {
              type: 'outgoing',
              partNumber,
              id,
            })
          }
        "
        :items="notifications.outgoing"
        type="outgoing"
        :canRemove="true"
      >
        <div class="italic text-brandLightBlue text-sm mb-2">
          Approvals expire in one business day
        </div>
      </InventoryOrderList>

      <InventoryOrderList
        v-if="isInApproverGroup || isAdmin"
        @remove="
          ({ id, partNumber }) => {
            $store.dispatch('cleanNotification', {
              type: 'incoming',
              partNumber,
              id,
            })
          }
        "
        :items="notifications.incoming ? notifications.incoming : []"
        type="incoming"
        :canRemove="true"
      />

      <div class="mt-[60px]">
        <router-link
          class="button-b uppercase block text-center"
          to="/order-requests-history"
        >
          VIEW REQUEST HISTORY
        </router-link>
      </div>
    </div>
  </Master>
</template>

<script>
import HomeSSC from '@/components/HomeSSC'
import { InventoryOrderList } from '@/components/inventory/InventoryOrderList'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import PageMixin from '@/mixins/PageMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderRequestsNotifications',
  mixins: [PageMixin, AuthorizedGuardMixin],
  components: { HomeSSC, InventoryOrderList },
  computed: {
    ...mapGetters('user', [
      'isInOrderingGroup',
      'isInApproverGroup',
      'isAdmin',
    ]),
    notifications() {
      return this.$store.state.orders.notifications
    },
    notificationsLoading() {
      return this.$store.state.orders.notificationsLoading
    },
  },
  methods: {
    cleanNotification: ({ id, type, partNumber }) => {},
  },
  created() {
    this.$store.dispatch('getNotifications')
  },
  data: {
    foo: 'bar',
  },
}
</script>
