<template>
  <Master>
    <loader v-if="loading" />
    <div class="inventory-pooling">
      <div class="container" v-if="!loading">
        <div class="flex justify-between items-baseline mb-4">
          <PageHeading :title="partNumber" />
        </div>
        <div class="mb-6">{{ item.description }}</div>

        <div>
          <h1 class="text-2xl text-headerColor font-bold mb-4">
            Buying Locations
          </h1>
          <hr />
          <div class="grid grid-cols-12 py-2">
            <div class="col-span-6 text-headerColor font-bold">Branch</div>
            <div class="col-span-2 text-headerColor font-bold">Need</div>
            <div class="font-bold text-headerColor col-span-4 justify-self-end">
              Willing to sell
            </div>
          </div>
          <hr />

          <div>
            <div
              class="border-b border-gray-500 border-opacity-30"
              v-for="request in item.requests"
              :key="request.id"
            >
              <div class="grid grid-cols-12 py-3">
                <div class="col-span-6 pt-3">
                  {{ request.RequestingSSCBranch }}-{{ request.RequestingSSC }}
                </div>
                <div class="col-span-2 text-[22px] text-headerColor">
                  {{ request.RequestedQuantity }}
                </div>
                <div class="col-span-4 justify-self-end">
                  <div class="grid grid-flow-col auto-cols-max gap-4 mt-2 mb-4">
                    <RadioBox
                      label="Yes"
                      value="Yes"
                      :inputName="`willingToSell-${request.id}`"
                      v-model="request.Sell"
                      @change="updateSellStatus(request, 'Yes')"
                    />
                    <RadioBox
                      label="No"
                      value="No"
                      :inputName="`willingToSell-${request.id}`"
                      v-model="request.Sell"
                      @change="updateSellStatus(request, 'No')"
                    />
                  </div>
                  <div v-if="request.Sell === 'Yes'" class="flex justify-end">
                    <div class="!w-[77px]">
                      <div class="mb-3">How many?</div>
                      <input
                        @change="() => checkSellingAmount(request)"
                        :max="request.RequestedQuantity"
                        type="number"
                        :name="`sellingAmount-${request.id}`"
                        class="form-input !w-[77px]"
                        autocomplete="off"
                        v-model="request.ApprovedQuantity"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            @click="
              () => {
                confirmSellingRequest(item)
                goPageBack()
              }
            "
            class="button-a mt-8"
          >
            SAVE SELL STATUS
          </button>

          <h1 class="text-2xl text-headerColor font-bold mt-10 mb-4">
            Selling Locations
          </h1>
          <hr />
          <div class="grid grid-cols-12 py-2">
            <div class="col-span-6 text-headerColor font-bold">Location</div>
            <div class="col-span-3 text-headerColor font-bold justify-self-end">
              Available
            </div>
            <div class="font-bold text-headerColor col-span-3 justify-self-end">
              Reserved
            </div>
          </div>
          <hr />

          <div class="grid grid-cols-12 py-3">
            <div class="col-span-6 pt-2">
              {{ item.swagelokBranch }}-{{ item.whoto }}
            </div>
            <div
              class="col-span-3 text-[22px] justify-self-end text-headerColor"
            >
              {{ item.available }}
            </div>
            <div
              class="col-span-3 text-[22px] justify-self-end text-headerColor"
            >
              {{ item.reserved }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import RadioBox from '@/components/common/RadioBox'
import InventoryPoolingBuyTable from '@/components/inventory/InventoryPoolingBuyTable'
import InventoryPoolingSellTable from '@/components/inventory/InventoryPoolingSellTable'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import InventoryMixin from '@/mixins/InventoryMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import { mapActions } from 'vuex'

export default {
  name: 'InventoryPoolingSellDetail',
  mixins: [
    PageMixin,
    LoaderMixin,
    SscSelectorGuardMixin,
    InventoryMixin,
    AuthorizedGuardMixin,
  ],
  components: {
    RadioBox,
    InventoryPoolingSellTable,
    InventoryPoolingBuyTable,
  },
  methods: {
    ...mapActions('inventory', ['confirmSellingRequest']),
    ...mapActions('router', ['goPageBack']),
    async updateSellStatus(item, status) {
      item.Sell = status
      this.$forceUpdate()
    },

    checkSellingAmount(request) {
      if (parseInt(request.ApprovedQuantity) > request.RequestedQuantity) {
        request.ApprovedQuantity = request.RequestedQuantity
      }

      const totalApprovedQuantity = this.item.requests.reduce(
        (acc, request) => acc + parseInt(request.ApprovedQuantity),
        0,
      )

      if (totalApprovedQuantity > this.limit) {
        const diff = totalApprovedQuantity - this.limit
        request.ApprovedQuantity = parseInt(request.ApprovedQuantity) - diff
      }

      this.$forceUpdate()
    },
  },
  computed: {},
  async created() {
    if (Object.keys(this.$route.params).length === 1) {
      this.goPageBack()
    }

    try {
      const { partNumber, selectedBranch, selectedFullNameBranch, item } =
        this.$route.params

      this.selectedBranch = selectedBranch
      this.selectedFullNameBranch = selectedFullNameBranch
      this.item = JSON.parse(JSON.stringify(item))
      this.partNumber = partNumber
      this.limit = this.item.available + this.item.reserved

      this.item.requests.forEach((request) => {
        request.sellingAmount =
          item.available >= request.RequestedQuantity
            ? request.RequestedQuantity
            : item.available
      })
    } catch (error) {
      console.log('error', error)
    }
  },
  data() {
    return {
      limit: 0,
      loading: false,
      selectedBranch: null,
      selectedFullNameBranch: null,
      item: null,
      partNumber: null,
    }
  },
}
</script>
