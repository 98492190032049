<template>
  <Master>
    <div class="ssc-to-ssc-sourcing">
      <div class="container">
        <div class="flex justify-between mb-4 items-center">
          <PageHeading title="SSC to SSC Cross-Sourcing" />
          <CogIcon
            v-if="
              this.userData.groups.some((group) =>
                ['wsSscAdmin', 'admin'].includes(group),
              )
            "
            class="
              w-[24px]
              h-[24px]
              font-icon
              text-brandBlue text-[30px]
              hover:text-brandBlue-dark
              cursor-pointer
            "
            @click="
              setPath({
                name: 'SscToSscSourcingAdmin',
                routeMap: { goBackState: { name: 'SscToSscSourcing' } },
              })
            "
          />
        </div>
        <ValidationObserver
          tag="form"
          ref="observer"
          @submit.prevent="doSearch"
          v-slot="{ invalid }"
          class="grid"
          id="sss-search"
        >
          <validation-provider
            tag="div"
            rules="required"
            name="sourcingfunction"
          >
            <FormSelectWrapper>
              <select
                id="sss-function"
                class="form-select"
                :disabled="isLoading"
                @change="getCapabilities($event)"
              >
                <option value="" disabled selected>Select Function</option>
                <option
                  v-for="item in capabilityData"
                  :key="item.FunctionCode"
                  :value="item.FunctionCode"
                  :selected="currentSelectedFunction == item.FunctionCode"
                >
                  {{ item.FunctionName }}
                </option>
              </select>
            </FormSelectWrapper>
          </validation-provider>
          <validation-provider
            tag="div"
            rules="required"
            name="sourcingCapability"
          >
            <div v-if="functionLoaded && capabilities.length">
              <label
                class="flex items-start mt-4 justify-between"
                v-for="(capability, index) in orderedCapabilities"
                :key="capability.CapCode"
              >
                <div>
                  <input
                    type="checkbox"
                    v-model="formData.sourcingCapability"
                    class="form-checkbox"
                    :id="capability.CapCode"
                    :value="capability.CapCode"
                    @change="handleSelectCapability"
                  /><span class="ml-3 text-textColor">{{
                    capability.CapName
                  }}</span>
                </div>
                <div v-if="index === 0">
                  <button
                    v-if="functionLoaded"
                    @click.prevent="handleCapabilitiesHelpClick"
                    class="w-[20px] float-right"
                  >
                    <QuestionCircleSolid class="text-brandLightBlue" />
                  </button>
                </div>
              </label>
            </div>
          </validation-provider>
          <button
            class="button-a mt-6"
            type="submit"
            :disabled="isLoading || invalid"
          >
            SEARCH
          </button>
        </ValidationObserver>
        <transition name="transition-fade">
          <div
            ref="results"
            class="relative mt-7"
            :class="isLoading ? 'min-h-[11rem]' : ''"
          >
            <loader v-if="isLoading" />
            <div
              id="sss-search-results"
              :data-sss-has-capacity-count="sssHasCapacityCount"
              :data-sss-no-capacity-count="sssNoCapacityCount"
              v-if="sscSourcingResults.length"
            >
              <hr class="-mx-2 mt-2" />
              <table2
                :header="{
                  cellA: 'Location',
                  cellB: 'Capacity',
                }"
                :body="{
                  cellA: {
                    title: 'whoto',
                    subtitle: 'name',
                    hasIcon: 'isHome',
                  },
                  cellB: 'color',
                }"
                :items="sscSourcingResults"
                :itemHasCursor="true"
                :options="{
                  cellARegularFont: false,
                  truncate: false,
                  lineBreak: true,
                  trafficLights: 'color',
                  homeNotClickable: true,
                }"
                itemKey="whoto"
                :helpPage="'SscToSscSourcingHelp'"
                :routeMap="{ goBackState: { name: 'SscToSscSourcing' } }"
                :helpPageOnRight="true"
                @itemClicked="(item) => showDetails(item)"
              >
                <template v-slot:cellAIcon>
                  <div
                    class="
                      icon-swg-home
                      text-brandYellow text-xl
                      leading-none
                      top-0.5
                      relative
                    "
                  />
                </template>
              </table2>
              <button
                class="mt-6 font-bold text-brandGreen"
                v-if="sscSourcingResults.length > 2"
                @click="initiateRequestToMultipleSSCs"
              >
                Initiate a Request to Multiple SSCs
                <i class="icon-right-open-big ml-2"></i>
              </button>
            </div>
            <div v-if="noResults">
              <div class="mt-4">
                No SSC Sourcing configuration match the search criteria.
              </div>
              <hr class="-mx-2 mt-4" />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </Master>
</template>

<script>
import CogIcon from '@/assets/icons/cog.svg'
import QuestionCircleSolid from '@/assets/icons/question-circle-solid.svg'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import PageMixin from '@/mixins/PageMixin'
import SourcingCapacityMixin from '@/mixins/SourcingCapacityMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import eventBusService from '@/services/event-bus'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'SscToSscSourcing',
  mixins: [
    PageMixin,
    LoaderMixin,
    SscSelectorGuardMixin,
    AuthorizedGuardMixin,
    SourcingCapacityMixin,
  ],
  components: { QuestionCircleSolid, CogIcon },
  methods: {
    ...mapActions('sourcing', [
      'setCapabilities',
      'setSourcingData',
      'sourcingData',
      'setCurrentSelectedFunction',
      'setCurrentSelectedCapabilities',
      'setSscSourcingResults',
      'clearSscSourcingResults',
    ]),
    async doSearch() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return

      if (this.noResults === true) {
        this.noResults = false
      }

      await this.withLoader(() => {
        return this.setSourcingData({ ...this.formData })
      })

      if (!this.getSourcingData?.length) {
        this.noResults = true
      }

      const sourcing = await this.getSourcingData.map((el) => {
        if (!el) return

        const { id, SSCSourcing, sscData, color, isHome } = el
        const capacity = SSCSourcing.every(
          (el) => el.Capacity === 'Has Capacity',
        )
          ? 'Has Capacity'
          : 'Does Not Have Capacity'

        return {
          whoto: id,
          name: sscData.name,
          isHome,
          capacity,
          color,
          sscData,
        }
      })

      this.sssHasCapacityCount =
        sourcing?.filter((i) => i.color === 'green' && i.isHome === false)
          .length || 0

      this.sssNoCapacityCount =
        sourcing?.filter((i) => i.color === 'red' && i.isHome === false)
          .length || 0

      this.setSscSourcingResults(sourcing)
    },
    handleCapabilitiesHelpClick() {
      const { sourcingCapability, sourcingfunction } = this.formData

      this.setPath({
        name: 'SscToSscSourcingCapabilitiesHelp',
        params: { sourcingCapability, sourcingfunction },
      })
    },
    showDetails(item) {
      const { whoto, sscData, isHome } = item

      if (isHome) return

      const SSCSourcing = this.getSourcingData
        .find((el) => el.id === whoto)
        ?.SSCSourcing.filter((el) =>
          this.currentSelectedCapabilities.includes(el.Capability),
        )

      this.setPath({
        name: 'SscToSscSourcingDetails',
        params: {
          sscId: whoto,
          isHome,
          sscData,
          SSCSourcing,
        },
      })
    },
    initiateRequestToMultipleSSCs() {
      const SSCsWithCapacity = this.sscSourcingResults.filter(
        (ssc) => ssc.color === 'green' && !ssc.isHome,
      )
      this.setPath({
        name: 'SscToSscSourcingRequestMultiple',
        params: {
          selectedCapabilities: {
            sourcingfunction: this.formData.sourcingfunction,
            sourcingCapability: this.formData.sourcingCapability,
          },
          SSCsWithCapacity,
        },
      })
    },
    getCapabilities(event) {
      const functionCode = event.target.value
      const capabilities =
        this.capabilityData.find((el) => el.FunctionCode === functionCode)
          ?.Capabilities ?? null

      if (!capabilities) return

      this.formData.sourcingCapability = []
      this.setCurrentSelectedCapabilities(null)

      this.capabilities = capabilities
      this.functionLoaded = true

      this.formData.sourcingfunction = functionCode
      this.setCurrentSelectedFunction(functionCode)

      this.clearSscSourcingResults()
    },
    handleSelectCapability() {
      this.clearSscSourcingResults()
    },
  },
  computed: {
    ...mapGetters('inventory', ['homeSscDummy']),
    ...mapGetters('sourcing', [
      'getSourcingData',
      'currentSelectedFunction',
      'currentSelectedCapabilities',
      'sscSourcingResults',
    ]),
    ...mapState('sourcing', ['capabilityData']),
    sscData() {
      return this.homeSscDummy
    },
    orderedCapabilities() {
      return this.capabilities?.sort((a, b) =>
        a.CapName.localeCompare(b.CapName),
      )
    },
  },
  watch: {
    'formData.sourcingCapability'(sourcingCapability) {
      this.setCurrentSelectedCapabilities(sourcingCapability)
    },
  },
  updated() {
    eventBusService.$emit('sscSourcing.updated')
  },
  async created() {
    if (this.$route.params.clearState) {
      this.setCurrentSelectedFunction(null)
      this.setCurrentSelectedCapabilities(null)
      this.setSscSourcingResults([])
    }

    if (this.currentSelectedFunction) {
      this.formData.sourcingfunction = this.currentSelectedFunction

      if (this.capabilityData.length) {
        const capabilities =
          this.capabilityData.find(
            (el) => el.FunctionCode === this.currentSelectedFunction,
          )?.Capabilities ?? null

        this.capabilities = capabilities
        this.formData.sourcingCapability = this.currentSelectedCapabilities

        this.functionLoaded = true
      }
    }

    if (!this.capabilityData) {
      this.withLoader(() => {
        return this.setCapabilities()
      })
    }

    this.setRouteMap({
      Menu: {
        goBackState: { name: 'SscToSscSourcing' },
      },
    })
  },
  data() {
    return {
      sssHasCapacityCount: 0,
      sssNoCapacityCount: 0,
      showHeaderMenu: true,
      formData: {
        sourcingfunction: [],
        sourcingCapability: [],
      },
      noResults: false,
      functionLoaded: false,
      capabilities: [],
    }
  },
}
</script>
