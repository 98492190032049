<template>
  <Master>
    <div class="favorites-add-page">
      <div class="container">
        <div class="flex items-baseline mb-2 pb-2 ml-2">
          <PageHeading title="Add Favorite SSCs" />
        </div>

        <div class="favorites-add-content mx-2">
          <ValidationObserver tag="form" v-slot="{ invalid }" id="fav-search">
            <validation-provider
              tag="div"
              rules="required|min:3"
              name="SSC Number"
            >
              <div class="w-full relative flex mb-6">
                <input
                  class="
                    w-full
                    mr-2
                    max-w-[320px]
                    py-0
                    px-4
                    h-[36px]
                    rounded-none
                    border border-solid border-secondaryGray
                    favorite-ssc-input
                  "
                  placeholder="Enter SSC name or WHOTO code"
                  autocomplete="off"
                  @input="($event) => (sscValue = $event.target.value)"
                  v-model="sscValue"
                  ref="sscValue"
                  name="sscValue"
                  type="text"
                  @keyup="doSearch"
                />
                <span
                  class="
                    icon-swg-circle-close
                    flex
                    items-center
                    cursor-pointer
                    hover:text-gray-300
                    text-brandBlue text-2xl
                    ml-1
                  "
                  @click="sscValue = ''"
                ></span>
              </div>
              <div v-if="!invalid">
                <ul v-for="ssc in sscResults" :key="ssc.uid">
                  <li class="list-item py-2 border-t">
                    <div class="flex-auto w-80 text-headerColor">
                      <div class="font-semibold">{{ ssc.uid }}</div>
                      <div>{{ ssc.name }}</div>
                    </div>
                    <div class="flex-none w-20 text-right">
                      <span
                        v-if="isFavorite(ssc.uid)"
                        class="icon-swg-check text-brandGray text-2xl"
                      ></span>
                      <span
                        v-else
                        class="icon-swg-plus text-brandBlue text-2xl"
                        @click="addFavorite(ssc.uid)"
                      ></span>
                    </div>
                  </li>
                </ul>
              </div>
            </validation-provider>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import { mapActions } from 'vuex'

export default {
  name: 'FavoriteList',
  mixins: [PageMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  methods: {
    ...mapActions('settings', [
      'searchSSCs',
      'getFavoriteSSCsByUser',
      'handleUpdateFavoriteList',
    ]),
    isFavorite(ssc) {
      return this.favoriteList.some((item) => item.SSC === ssc)
    },
    async doSearch() {
      if (this.sscValue.length < 3) return
      return await this.searchSSCs().then((response) => {
        if (response.length > 0) {
          const filteredResults = response.filter((result) => {
            return (
              result.uid.toLowerCase().indexOf(this.sscValue.toLowerCase()) >
                -1 ||
              result.name.toLowerCase().indexOf(this.sscValue.toLowerCase()) >
                -1
            )
          })

          this.sscResults = [...filteredResults]
        }
      })
    },
    async addFavorite(ssc) {
      const getUserSettings = await this.getFavoriteSSCsByUser().then(
        (response) => response,
      )

      const currentFavorites = getUserSettings[0].FavoriteSSCs

      this.favoriteList.push({
        SSC: ssc,
        SortOrder:
          currentFavorites.length > 1
            ? Math.max(...currentFavorites.map((fav) => fav.SortOrder)) + 1
            : 1,
      })

      this.handleUpdateFavoriteList(this.favoriteList).then((response) => {
        if (!response) {
          console.error(response)
        }
      })
    },
  },
  async created() {
    await this.getFavoriteSSCsByUser().then((response) => {
      if (!response.length) return
      this.favoriteList = response[0].FavoriteSSCs
    })
  },
  data() {
    return {
      sscValue: '',
      sscResults: [],
      favoriteList: [],
      smth: true,
    }
  },
}
</script>
