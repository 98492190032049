var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Master',[(!_vm.notifications || _vm.notificationsLoading)?_c('loader'):_vm._e(),(_vm.notifications)?_c('div',{staticClass:"container"},[_c('PageHeading',{staticClass:"flex justify-between",attrs:{"title":"Order Requests"}},[_c('HomeSSC')],1),_c('hr',{staticClass:"my-2"}),(_vm.isInOrderingGroup || _vm.isAdmin)?_c('InventoryOrderList',{attrs:{"items":_vm.notifications.outgoing,"type":"outgoing","canRemove":true},on:{"remove":function (ref) {
          var id = ref.id;
          var partNumber = ref.partNumber;

          _vm.$store.dispatch('cleanNotification', {
            type: 'outgoing',
            partNumber: partNumber,
            id: id,
          })
        }}},[_c('div',{staticClass:"italic text-brandLightBlue text-sm mb-2"},[_vm._v(" Approvals expire in one business day ")])]):_vm._e(),(_vm.isInApproverGroup || _vm.isAdmin)?_c('InventoryOrderList',{attrs:{"items":_vm.notifications.incoming ? _vm.notifications.incoming : [],"type":"incoming","canRemove":true},on:{"remove":function (ref) {
          var id = ref.id;
          var partNumber = ref.partNumber;

          _vm.$store.dispatch('cleanNotification', {
            type: 'incoming',
            partNumber: partNumber,
            id: id,
          })
        }}}):_vm._e(),_c('div',{staticClass:"mt-[60px]"},[_c('router-link',{staticClass:"button-b uppercase block text-center",attrs:{"to":"/order-requests-history"}},[_vm._v(" VIEW REQUEST HISTORY ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }