<template>
  <Master>
    <div class="settings-page">
      <div class="container">
        <div class="flex items-baseline mb-4 ml-2">
          <PageHeading title="Settings" />
          <div
            @click.prevent="setPath({ name: 'SettingsInfo' })"
            class="
              flex
              items-center
              justify-center
              ml-2
              top-[-4px]
              relative
              cursor-pointer
              bg-brandLightBlue
              text-white
              rounded-full
              text-xs
              w-5
              h-5
            "
          >
            ?
          </div>
        </div>

        <div class="settings-content mx-2">
          <ul>
            <li
              @click.prevent="setPath({ name: 'FavoriteList' })"
              class="list-item border-t py-3"
            >
              <div class="flex-auto w-80">Favorite SSCs</div>
              <div class="flex-none w-20 text-right">
                <span class="icon-swg-arrow-right"></span>
              </div>
            </li>
            <li class="list-item py-3">
              <div class="flex-auto w-80 flex items-center">
                Search SSCs In Batches Of
              </div>
              <div class="flex-none w-20">
                <select
                  class="
                    border border-solid border-secondaryLightGray
                    w-full
                    text-center
                    cursor-pointer
                  "
                  @change="setBatchSize($event.target.value)"
                  v-show="showBatchSize"
                >
                  <option
                    v-for="value in batchSizeOptions"
                    :key="value"
                    :value="value"
                    :selected="batchSize == value"
                  >
                    {{ value }}
                  </option>
                </select>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import { mapActions } from 'vuex'

export default {
  name: 'Settings',
  mixins: [PageMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  methods: {
    ...mapActions('settings', ['handleUpdateBatchSize', 'getBatchSize']),
    setBatchSize(batchSize) {
      this.handleUpdateBatchSize(batchSize).then((response) => {
        if (response !== 200) {
          console.error(response)
        }
      })
    },
  },
  async created() {
    await this.getBatchSize().then((response) => {
      const { statusCode, resource, defaultBatchSize } = response

      switch (statusCode) {
        case 200:
          this.batchSize = resource.BatchSize
          break

        case 404:
          this.batchSize = defaultBatchSize
          break

        default:
          this.batchSize = defaultBatchSize
          break
      }

      this.showBatchSize = true
    })
  },
  data() {
    return {
      showBatchSize: false,
      batchSize: '10',
      batchSizeOptions: ['10', '25', '50', 'All'],
    }
  },
}
</script>
