<template>
  <Master>
    <div class="unauthorized">
      <div class="container">
        <div class="flex justify-between items-baseline mb-4">
          <PageHeading title="Access Denied" />
        </div>
        <div>
          <b>
            Your user account does not currently have access to this
            application.</b
          >
          <br /><br />

          <p><b>SSC Associates:</b></p>
          <p>
            If you wish to request access, please contact the Swagelok Security
            Management Administrator for your SSC so that they can grant you
            access.
          </p>

          <br />

          <p><b>Corporate Associates:</b></p>
          <p>
            If you wish to request access, please provide your name, job title
            and department in
            <button
              class="text-brandBlue underline"
              @click="handleFeedbackClick"
            >
              this link</button
            >.
          </p>
        </div>
        <div class="mt-4">
          <div v-for="option in options" :key="option.title">
            <div class="-mx-2 hover:bg-gray-100" v-if="option.isVisible">
              <div
                class="
                  px-2
                  py-2
                  cursor-pointer
                  hover:bg-gray-100
                  transition-colors
                "
                @click="option.action"
              >
                <div class="link-a font-semibold" v-html="option.title"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Master>
</template>

<script>
import { mapActions } from 'vuex'
import PageMixin from '@/mixins/PageMixin'
import AuthenticatedGuardMixin from '@/mixins/AuthenticatedGuardMixin'
import LoaderMixin from '@/mixins/LoaderMixin'

export default {
  name: 'Unauthorized',
  mixins: [PageMixin, LoaderMixin, AuthenticatedGuardMixin],
  computed: {
    options() {
      return [
        {
          title: `Help/Feedback <span class='icon-swg-arrow-right'></span>`,
          action: () => {
            this.setPath({ name: 'Feedback' })
          },
          isVisible: false,
        },
        {
          title: `Log Out <span class='icon-swg-arrow-right'></span>`,
          action: this.onLogoutClick,
          isVisible: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions('app', ['reLogin']),
    ...mapActions('router', ['goPageBack', 'setPath']),
    handleFeedbackClick() {
      return this.setPath({ name: 'Feedback' })
    },
    onLogoutClick() {
      this.withLoader(() => {
        return this.reLogin()
      })
    },
  },
  created() {
    if (this.isAuthorized) {
      this.setPath({ name: 'Home' })
    }
  },
  data() {
    return {
      showHeaderMenu: true,
    }
  },
}
</script>
