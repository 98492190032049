<template>
  <div
    id="inv-search-inventory-results"
    :data-inv-met-criteria-home="
      !!(
        partData &&
        partData.some((part) => part.sscList.some((entry) => entry.isHome))
      ) && ''
    "
    :data-inv-met-criteria-peer="!!(partData && partData.length) && ''"
    :data-inv-truncated-results="
      !!(
        partData &&
        partData.some((part) =>
          part.sscList.some((entry) => entry.totalPartsFound > maxPartsPerSsc),
        )
      ) && ''
    "
    :data-inv-searched-full-network="isSearchComplete && !isLoading && ''"
  >
    <div v-if="partData && !isLoading">
      <div
        class="flex items-center -mt-3 mb-3"
        v-if="
          partData.some((part) =>
            part.sscList.some(
              (entry) => entry.totalPartsFound > maxPartsPerSsc,
            ),
          )
        "
      >
        <SvgExclamationTriangle
          class="text-brandYellow w-[22px] h-[22px] fill-current"
        />
        <div class="flex flex-col ml-4">
          <div>Not all search results are being displayed.</div>
          <div>Refine your search for better results.</div>
        </div>
      </div>
      <hr class="-mx-2" />
      <div class="">
        <table2
          :header="{
            cellA: 'Part Number',
            cellB: 'Nearest SSC',
          }"
          :body="{
            cellA: {
              title: 'partNumber',
            },
            cellB: 'nearestSscId',
          }"
          :items="partData"
          :itemHasCursor="true"
          :options="{ cellARegularFont: true, truncate: true }"
          itemKey="partNumber"
          @itemClicked="(item) => showDetails(item)"
        >
        </table2>

        <div class="" v-if="showNoResult && isSearchComplete">
          <div class="mt-4">No parts found with minimum inventory.</div>
          <hr class="-mx-2 mt-4" />
        </div>

        <div
          v-if="!isSearchComplete && !isLoading"
          class="link-a mt-4 hover:text-brandGreen-darker font-semibold"
          @click="$emit('searchMoreClicked')"
          id="inv-search-more-sscs"
        >
          {{ 'Search More Locations' }}
          <span class="icon-swg-arrow-right"></span>
        </div>
        <div
          class="flex items-center mt-4"
          v-else-if="isSearchComplete && !isLoading"
        >
          <SvgExclamationTriangle
            class="text-brandYellow w-[22px] h-[22px] fill-current"
          />
          <div class="flex justify-center items-center ml-4">
            The full network has been searched.
          </div>
        </div>
      </div>
    </div>
    <loader v-if="isLoading" />
  </div>
</template>

<script>
import SvgExclamationTriangle from '@/assets/icons/exclamation-triangle.svg'
import InventoryMixin from '@/mixins/InventoryMixin'
import { mapActions } from 'vuex'

export default {
  name: 'InventoryResultWildcard',
  mixins: [InventoryMixin],
  components: { SvgExclamationTriangle },
  props: ['isLoading', 'minQty'],
  methods: {
    ...mapActions('router', ['setPath']),
    showDetails(ssc) {
      this.setPath({
        name: 'InventoryPartDetails',
        params: {
          partNumber: ssc.partNumber,
          minQty: this.minQty,
        },
        routeMap: {
          goBackState: {
            name: 'Inventory',
          },
        },
      })
    },
    showHelpReserve() {
      this.setPath({
        name: 'InventoryHelpReserve',
        routeMap: {
          goBackState: {
            name: 'Inventory',
          },
        },
      })
    },
    getPartData() {
      const batches = {}

      this.partsWithSsc.forEach((partEntry) => {
        if (!batches[partEntry.batchId]) batches[partEntry.batchId] = []
        batches[partEntry.batchId].push(partEntry)
      })

      return Object.values(batches)
        .reduce((prev, cur) => {
          return [
            ...prev,
            ...cur.sort((a, b) => a.partNumber.localeCompare(b.partNumber)),
          ]
        }, [])
        .filter((el) => {
          return (
            el.isHome ||
            el.sscList.some(
              (item) =>
                item.ivaOptOutFlag === 'N' ||
                item.ivaOptOutFlag === undefined ||
                item.ivaOptOutFlag === '',
            )
          )
        })
    },
  },
  computed: {
    showNoResult() {
      return !this.isLoading && !this.partData?.length
    },
    isSearchComplete() {
      if (this.sscData) {
        this.sscData.forEach((ssc) => {
          ssc.totalAvailable = ssc.totalAvailable.toLocaleString(
            navigator.language ?? 'default',
          )
          ssc.totalReserved = ssc.totalReserved.toLocaleString(
            navigator.language ?? 'default',
          )
        })
      }
      return this.inventoryData.isComplete
    },
  },
  created() {
    if (!this.isLoading) {
      this.partData = this.getPartData()

      if (this.showNoResult && !this.isSearchComplete) {
        this.$emit('searchMoreClicked')
      }
    }
    this.$watch('isLoading', (nv) => {
      if (nv === false) {
        this.partData = this.getPartData()

        if (this.showNoResult && !this.isSearchComplete) {
          this.$emit('searchMoreClicked')
        }
      }
    })
  },
  data() {
    return {
      partData: null,
    }
  },
}
</script>
