var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Master',[_c('div',{staticClass:"container"},[_c('OrderHeader',[_c('ArrowLeft',{staticClass:"w-5"}),_vm._v(" Outgoing Request ")],1),_c('Order',{attrs:{"order":_vm.item,"whoTo":_vm.item.SellingSSC}}),(_vm.item.Status === 'Requested')?_c('div',[_c('button',{staticClass:"link-red",on:{"click":function () {
            _vm.$store.dispatch('updateOrder', {
              type: _vm.$attrs.type,
              id: _vm.$attrs.id,
              order: Object.assign({}, _vm.item, {Status: 'Canceled'}),
            })
            _vm.item.Status = 'Canceled'
          }}},[_vm._v(" Cancel Order Request ")])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }