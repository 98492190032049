<template>
  <Master>
    <div class="configurator-help">
      <div class="container">
        <PageHeading title="Initiate a Request" />
        <p class="mt-4 mb-2">
          Submitting the form below will send an email to the designated contact
          designated at each selected SSC responsible for managing this
          capability.
        </p>

        <p class="my-2"><b>Capabilities Requested:</b></p>

        <p
          v-for="capability in selectedCapabilities.sourcingCapability"
          :key="capability"
        >
          {{ getCapability(selectedCapabilities.sourcingfunction, capability) }}
        </p>

        <ValidationObserver
          tag="form"
          ref="observer"
          class="relative mt-3"
          @submit.prevent="submitRequest"
          v-slot="{ failed }"
          id="sss-request"
        >
          <p class="mt-4"><b>To:</b></p>
          <div
            v-for="recipient in SSCsWithCapacity"
            :key="recipient.sscData.uid"
          >
            <label class="block items-start mt-4">
              <input
                type="checkbox"
                class="form-checkbox mt-1"
                v-model="selectedRecipients"
                :id="recipient.sscData.uid"
                :value="recipient.sscData"
              />
              <span class="ml-3 text-textColor"
                >{{ recipient.sscData.uid }} - {{ recipient.name }}</span
              >
            </label>
          </div>

          <validation-provider
            tag="div"
            class="mt-5"
            name="Message"
            v-slot="{ errors }"
          >
            <div class="justify-between items-center">
              <p class="mb-3"><b>Message:</b></p>
              <textarea
                class="form-textarea placeholder-italic h-[400px]"
                v-model="formData.message"
                type="text"
                id="set-request-message"
              />
            </div>
            <transition name="transition-fade">
              <div v-show="errors.length" class="absolute field-error">
                {{ errors[0] }}
              </div>
            </transition>
          </validation-provider>
          <button
            class="button-a mt-8"
            type="submit"
            :disabled="isLoading || failed || selectedRecipients.length < 1"
          >
            SUBMIT
          </button>
        </ValidationObserver>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SscToSscSourcingRequestMultiple',
  mixins: [PageMixin, LoaderMixin, SscSelectorGuardMixin, AuthorizedGuardMixin],
  props: ['selectedCapabilities', 'SSCsWithCapacity'],
  methods: {
    ...mapActions('settings', ['sendEmail']),
    async submitRequest() {
      const selectedRecipientsSSCs = this.selectedRecipients.map((el) => el.uid)

      const recipients = this.filteredSourcingData
        .map((item) => {
          const { id, SSCSourcing } = item

          return {
            id,
            sourcing: SSCSourcing.filter((el) =>
              this.selectedCapabilities.sourcingCapability.includes(
                el.Capability,
              ),
            ),
          }
        })
        .filter((el) => selectedRecipientsSSCs.includes(el.id))

      const { message } = this.formData

      const { uid: loggedUserEmail, fullName } = this.userData
      const requestingSSC = this.userData.ssc.uid
      const fromName = 'Swagelok Webmaster'
      const fromEmail = 'webmaster@swagelok.com'

      const requestedCapabilities = this.selectedCapabilitiesNames
        .map((el) => {
          return `${el}<br />`
        })
        .join('')

      const sendMail = await Promise.all(
        recipients.map(async (recipient) => {
          const { id, sourcing } = recipient

          return await this.sendEmail({
            fromName,
            fromEmail,
            to: sourcing.map((el) => el.Contact).join(','),
            replyTo: loggedUserEmail,
            cc: loggedUserEmail,
            subject: `${
              process.env.VUE_APP_ENV === 'prod' ? '' : 'TEST: '
            }SSC-to-SSC Cross-Sourcing Request: ${
              this.selectedCapabilitiesNames.length > 1
                ? 'Multiple Capabilities'
                : this.selectedCapabilitiesNames[0]
            }`,
            text: message,
            html:
              this.selectedCapabilitiesNames.length > 1
                ? `<div>
                    <p>${requestingSSC}-to-${id} Cross-Sourcing Request</p>
                    <p style="margin-bottom: 0;">
                      <b>Capabilities:</b><br />${requestedCapabilities}
                    </p>
                    <p style="margin-bottom: 0;">
                      <b>Requested By:</b> ${fullName}
                    </p>
                    <p style="margin-bottom: 0;">
                      <b>Replies to this email message will go directly to</b> ${loggedUserEmail}
                    </p>
                  </div>
                  <br />
                  <p style="margin-bottom: 0;">
                    <b>Message:</b>
                    ${message}
                  </p>`
                : `<p>${requestingSSC}-to-${id} Cross-Sourcing Request</p>
                    <p style="margin-bottom: 0;">
                      <b>Capabilities:</b><br />${requestedCapabilities}
                    </p>
                    <p style="margin-bottom: 0;">
                      <b>Requested By:</b> ${fullName}
                    </p>
                    <p style="margin-bottom: 0;">
                      <b>Replies to this email message will go directly to</b> ${loggedUserEmail}
                    </p>
                    <br />
                   <p>${message}</p>`,
          })
        }),
      )

      if (sendMail) {
        this.setPath({
          name: 'SscToSscSourcingRequestMultipleSubmitted',
          params: { selectedRecipients: this.selectedRecipients },
        })
      } else {
        this.setPath({
          name: 'ServiceUnavailable',
          params: {
            retryAction: false,
          },
        })
      }
    },
    getCapability(functionCode, CapCode) {
      return this.capabilityData
        .find((func) => func.FunctionCode === functionCode)
        ?.Capabilities.find((cap) => cap.CapCode === CapCode)?.CapName
    },
  },
  computed: {
    ...mapState('sourcing', ['capabilityData', 'sourcingData']),
  },
  created() {
    const sscList = this.SSCsWithCapacity.map((el) => el.whoto)

    this.filteredSourcingData = this.sourcingData.filter((el) =>
      sscList.includes(el.id),
    )

    this.selectedCapabilitiesNames =
      this.selectedCapabilities.sourcingCapability.map((capability) =>
        this.getCapability(
          this.selectedCapabilities.sourcingfunction,
          capability,
        ),
      )
  },
  data() {
    return {
      showHeaderMenu: true,
      selectedRecipients: [],
      selectedCapabilitiesNames: [],
      formData: {
        message: '',
      },
    }
  },
}
</script>
