var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"flex justify-between py-2.5"},[_c('div',{staticClass:"font-bold text-headerColor w-[36%]"},[_vm._v(" "+_vm._s(_vm.header.cellA)+" ")]),_c('div',{staticClass:"font-bold text-headerColor w-[34%] text-right"},[_vm._v(" "+_vm._s(_vm.header.cellB)+" ")]),_c('div',{staticClass:"font-bold text-headerColor w-[25%] text-right"},[_vm._v(" "+_vm._s(_vm.header.cellC)+" ")]),_c('div',{staticClass:"font-bold text-headerColor w-[8%] text-right"},[_vm._v(" "+_vm._s(_vm.header.cellD)+" ")])]),_c('hr',{staticClass:"-mx-2"}),_c('transition-group',{attrs:{"name":"transition-list","tag":"div"}},_vm._l((_vm.items),function(item,idx){return _c('div',{key:item[_vm.itemKey] + idx,staticClass:"transition-list-item",on:{"click":function($event){return _vm.$emit('itemClicked', item)}}},[_c('div',{staticClass:"-mx-2 transition-colors hover:bg-gray-100"},[_c('div',{staticClass:"flex justify-between items-center py-2 px-2",class:_vm.itemHasCursor ? 'cursor-pointer' : null},[_c('div',{staticClass:"w-[36%]"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"text-headerColor truncate mr-1",class:_vm.options && _vm.options.cellARegularFont ? null : 'font-bold',domProps:{"innerHTML":_vm._s(
                  _vm.options && _vm.options.lineBreak
                    ? item[_vm.body.cellA.title].replaceAll(', ', '<br />')
                    : item[_vm.body.cellA.title]
                )}}),(item[_vm.body.cellA.hasIcon])?_vm._t("cellAIcon"):_vm._e()],2),_c('div',{staticClass:"truncate text-headerColor"},[_vm._v(" "+_vm._s(item[_vm.body.cellA.subtitle])+" ")])]),_c('div',{staticClass:"text-[22px] text-headerColor w-[34%] text-right"},[_vm._v(" "+_vm._s(item[_vm.body.cellB])+" ")]),_c('div',{staticClass:"\n              text-[22px] text-headerColor\n              w-[25%]\n              text-right\n              pl-[1.3rem]\n            ",class:_vm.options && _vm.options.trafficLights
                ? 'flex justify-between items-center'
                : null,domProps:{"innerHTML":_vm._s(
              _vm.options && _vm.options.trafficLights
                ? (item['displayComponents'].every(function (el) { return el.available > 0; })
                    ? _vm.green
                    : item['displayComponents'].some(function (el) { return el.available > 0; })
                    ? _vm.yellow
                    : _vm.red) + item[_vm.body.cellC]
                : item[_vm.body.cellC]
            )}}),_c('div',{staticClass:"\n              text-[16px]\n              text-headerColor\n              w-[8%]\n              text-right\n              mt-[4px]\n            "},[_vm._v(" "+_vm._s(item.inventoryUoMValue)+" ")])])]),_c('hr',{staticClass:"-mx-2"})])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }