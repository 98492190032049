<template>
  <Master>
    <div class="inventory">
      <div class="container">
        <InventoryHeader :showOrderHistory="true" />

        <div class="flex justify-between items-center mb-4">
          <PageHeading title="Inventory Visibility" />

          <CogIcon
            v-if="
              this.userData.groups.some((group) =>
                ['wsSscAdmin', 'admin'].includes(group),
              )
            "
            class="
              w-[24px]
              h-[24px]
              font-icon
              text-brandBlue text-[30px]
              hover:text-brandBlue-dark
              cursor-pointer
            "
            @click="
              setPath({
                name: 'AdminSSCContactInfo',
                routeMap: { goBackState: { name: 'Inventory' } },
              })
            "
          />
        </div>

        <ValidationObserver
          tag="form"
          ref="observer"
          @submit.prevent="doSearch"
          v-slot="{ invalid }"
          id="inv-search-inventory"
        >
          <validation-provider
            tag="div"
            rules="required|min:3"
            name="Part Number"
          >
            <div class="w-full relative flex">
              <input
                class="form-input placeholder-italic mr-2"
                id="inv-part-number"
                type="text"
                name="partNumber"
                :disabled="isLoading"
                placeholder="Swagelok Part Number"
                ref="partNumber"
                :value="searchQuery.partNumber"
                @input="
                  searchQuery.partNumber = $event.target.value
                    .trim()
                    .toUpperCase()
                "
              />
              <span
                class="icon-swg-circle-close flex items-center cursor-pointer"
                :class="
                  isLoading || !searchQuery.partNumber
                    ? 'hover:text-gray-300 cursor-default'
                    : ''
                "
                @click="
                  () =>
                    isLoading || !searchQuery.partNumber
                      ? null
                      : resetPartNumber()
                "
              ></span>
            </div>
          </validation-provider>

          <transition name="transition-fade">
            <div v-if="showAdvancedSearchParams">
              <div class="grid grid-flow-col justify-between mt-3">
                <RadioBox
                  label="Equals"
                  value="Equals"
                  inputName="inv-operator"
                  v-model="searchQuery.operator"
                />
                <RadioBox
                  label="Starts With"
                  value="Starts"
                  inputName="inv-operator"
                  v-model="searchQuery.operator"
                />
                <RadioBox
                  label="Contains"
                  value="Contains"
                  inputName="inv-operator"
                  v-model="searchQuery.operator"
                />
                <RadioBox
                  label="Ends With"
                  value="Ends"
                  inputName="inv-operator"
                  v-model="searchQuery.operator"
                />
              </div>

              <validation-provider
                tag="div"
                rules="required"
                class="mt-3"
                name="Minimum Quantity"
                v-slot="{ errors }"
              >
                <div class="flex justify-between items-center">
                  <div class="flex items-center mt-2">
                    <div class="">Minimum Quantity</div>
                    <SvgQuestionCircle
                      class="
                        text-brandLightBlue
                        hover:text-blue-700
                        w-5
                        h-5
                        fill-current
                        cursor-pointer
                        ml-2
                      "
                      @click="showHelpMinQty"
                    />
                  </div>
                  <input
                    class="form-input !px-3 !py-3 !w-16 text-right"
                    v-model="searchQuery.minQty"
                    v-mask="'####'"
                    :disabled="isLoading"
                    type="number"
                    id="inv-minimum-quantity"
                  />
                </div>
                <transition name="transition-fade">
                  <div
                    v-show="errors.length"
                    class="-mt-3 absolute field-error"
                  >
                    {{ errors[0] }}
                  </div>
                </transition>
              </validation-provider>

              <div class="w-full flex justify-between items-end mt-3">
                <div class="">Include All Sites with Inventory</div>
                <toggle-button
                  v-model="searchQuery.includeBelowMin"
                  :labels="false"
                  :width="58"
                  :height="32"
                  :color="{ checked: '#66b645', unchecked: '#D1D5DB' }"
                  :disabled="isLoading"
                  name="inv-include-below-minimum"
                />
              </div>
            </div>
          </transition>

          <button
            class="button-a mt-8"
            type="submit"
            :disabled="isLoading || invalid"
            ref="searchButton"
          >
            SEARCH
          </button>
        </ValidationObserver>

        <transition name="transition-fade">
          <div class="relative mt-7" :class="isLoading ? 'min-h-[11rem]' : ''">
            <loader v-if="isLoading && !inventoryData" />
            <InventoryResultEquals
              v-else-if="
                storedQuery &&
                storedQuery.operator === 'Equals' &&
                inventoryData
              "
              :minQty="searchQuery.minQty"
              :partNumber="storedQuery.partNumber"
              :isLoading="isLoading"
              @searchMoreClicked="doSearchMore"
            />
            <InventoryResultWildcardHome
              v-else-if="
                storedQuery &&
                storedQuery.operator !== 'Equals' &&
                inventoryData &&
                inventoryData.sscCollectionIndex === 1
              "
              :minQty="searchQuery.minQty"
              :isLoading="isLoading"
              @searchMoreClicked="doSearchMore"
            />
            <InventoryResultWildcard
              v-else-if="
                storedQuery &&
                storedQuery.operator !== 'Equals' &&
                inventoryData &&
                inventoryData.sscCollectionIndex > 1
              "
              :minQty="searchQuery.minQty"
              :isLoading="isLoading"
              @searchMoreClicked="doSearchMore"
            />
          </div>
        </transition>
      </div>
    </div>
  </Master>
</template>

<script>
import CogIcon from '@/assets/icons/cog.svg'
import SvgQuestionCircle from '@/assets/icons/question-circle-solid.svg'
import RadioBox from '@/components/common/RadioBox'
import InventoryHeader from '@/components/inventory/InventoryHeader'
import InventoryResultEquals from '@/components/inventory/InventoryResultEquals'
import InventoryResultWildcard from '@/components/inventory/InventoryResultWildcard'
import InventoryResultWildcardHome from '@/components/inventory/InventoryResultWildcardHome'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import InventoryMixin from '@/mixins/InventoryMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import eventBusService from '@/services/event-bus'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'Inventory',
  mixins: [
    PageMixin,
    LoaderMixin,
    SscSelectorGuardMixin,
    InventoryMixin,
    AuthorizedGuardMixin,
  ],
  components: {
    SvgQuestionCircle,
    RadioBox,
    InventoryResultEquals,
    InventoryResultWildcardHome,
    InventoryResultWildcard,
    CogIcon,
    InventoryHeader,
  },
  watch: {
    'searchQuery.minQty': {
      handler(nv) {
        if (parseInt(nv) === 0) {
          this.searchQuery.minQty = 1
        }
      },
    },
  },
  methods: {
    ...mapState('user', ['userData']),
    ...mapActions('inventory', ['setInventoryData', 'setSearchQueryParams']),
    ...mapMutations('inventory', { setInventoryDataMut: 'SET_INVENTORY_DATA' }),
    ...mapMutations('inventory', { setSearchQueryMut: 'SET_SEARCH_QUERY' }),
    ...mapMutations('layout', {
      setShowAdvancedSearchParams: 'SET_SHOW_ADVANCED_SEARCH_PARAMS',
    }),
    resetPartNumber() {
      this.searchQuery.partNumber = ''
      if (this.inventoryData) {
        this.setInventoryDataMut(null)
        this.$refs.observer.reset()
      } else {
        this.$refs.partNumber.focus()
      }
    },
    showHelpMinQty() {
      this.setPath({
        name: 'InventoryHelpMinQty',
      })
    },
    async doSearch() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return
      // force to uppercase due to input/form event race condition
      this.withLoader(() => {
        this.setInventoryDataMut(null)
        const { partNumber, minQty, includeBelowMin, operator } =
          this.searchQuery
        this.setSearchQueryParams({
          partNumber,
          minQty,
          includeBelowMin,
          operator,
        })
        return this.setInventoryDataWithFallback()
      })
    },
    doSearchMore() {
      this.withLoader(() => {
        return this.setInventoryDataWithFallback()
      })
    },
    setInventoryDataWithFallback() {
      return this.setInventoryData().catch((e) => {
        console.log(e)
        this.setPath({
          name: 'ServiceUnavailable',
          params: {
            retryAction: () => {
              this.setPath({ name: 'Inventory' })
            },
          },
        })
      })
    },
    doSearchClick() {
      this.$refs.searchButton.click()
    },
  },

  computed: {
    ...mapGetters('user', ['isGuest']),
    ...mapGetters('inventory', ['storedQuery']),
    ...mapState('inventory', ['inventoryData']),
    ...mapState('layout', ['showAdvancedSearchParams']),
  },
  updated() {
    eventBusService.$emit('inventory.updated')
  },
  async created() {
    this.searchQuery = this.storedQuery
      ? { ...this.storedQuery }
      : this.searchQuery
    this.$watch('searchQuery.includeBelowMin', () => {
      if (this.inventoryData) {
        this.withLoader(() => {
          return this.setSearchQueryParams({
            ...this.storedQuery,
            includeBelowMin: this.searchQuery.includeBelowMin,
          })
        })
      }
    })
    this.setRouteMap({
      Menu: {
        goBackState: { name: 'Inventory' },
      },
    })
  },
  data() {
    return {
      searchQuery: {
        partNumber: '',
        minQty: 1,
        includeBelowMin: false,
        operator: 'Equals',
      },
    }
  },
}
</script>
