const transformUserEntry = (entry) => {
  return {
    fullName: entry.name,
    uid: entry.email,
    ssc: entry.ssc ? transformSscEntry(entry.ssc) : null,
    groups: [...entry.groups],
    authProviderId: entry.authProviderId,
  }
}

const transformInventoryData = (entry, ssc, readyNow) => {
  return {
    branchUid: ssc.whoto + entry.swagelokBranch + entry.swagelokBranchName,
    branchId: entry.swagelokBranch,
    branchName: entry.swagelokBranchName,
    available: entry.available,
    readyNow,
    reserved: entry.reserved,
    schedule: !entry.schedule
      ? entry.schedule
      : Array.isArray(entry.schedule)
      ? entry.schedule
      : [entry.schedule],
  }
}

const transformInventoryEntry = (entry, isHome, batchId) => {
  return {
    BPCode: entry.BPCode,
    batchId: batchId,
    sscId: entry.whoto,
    sscName: entry.whotoName,
    phone: entry.phone,
    email: entry.email,
    isHome: isHome,
    totalPartsFound: entry.totalPartsFound,
    entries: entry.entries.map((el) => {
      const readyNow = el.ivaBuyItNowFlag === 'Y'
      return {
        partNumber: el.partNumber,
        partDescription: el.description,
        strategy: el.strategy,
        sellListPrice: el.sellListPrice,
        sellListPriceCurrency: el.sellListPriceCurrency,
        ivaOptOutFlag: el.ivaOptOutFlag,
        inventoryUoM: el.inventoryUoM,
        inventoryUoMValue: el.inventoryUoMValue,
        totalAvailable: el.inventory.reduce(
          (sum, cur) => cur.available + sum,
          0,
        ),
        totalReserved: el.inventory.reduce((sum, cur) => cur.reserved + sum, 0),
        get totalItems() {
          return this.totalAvailable + this.totalReserved
        },
        get hasItems() {
          return !!this.totalAvailable
        },
        readyNow,
        inventory: el.inventory.map((branch) =>
          transformInventoryData(branch, entry, readyNow),
        ),
      }
    }),
  }
}

const transformSscEntry = (entry) => {
  return {
    uid: entry.uid,
    name: entry.name,
    phone: entry.phone,
    email: entry.email,
  }
}

const transformConfiguratorAttributeEntry = (entry) => {
  return entry
}

const transformConfiguratorResultEntry = (entry, partsInventory) => {
  const variantSplit = entry.variant.split(' + ')
  const assembled = []

  variantSplit.forEach((variant) => {
    const part = partsInventory.find((el) => el.partNumber === variant)
    if (part) {
      assembled.push(part)
    } else {
      assembled.push({ partNumber: variant, sellListPrice: 'N/A' })
    }
  })

  const assembledPartsPriceSum = assembled.reduce((prev, curr) => {
    if (curr.sellListPrice === 0 || curr.sellListPrice === 'N/A') {
      return 'N/A'
    }

    return prev + curr.sellListPrice
  }, 0)

  let displayVariant = ''

  while (variantSplit.length) {
    displayVariant +=
      variantSplit.splice(0, 2).join(' ') + (variantSplit.length ? ', \n' : '')
  }

  const origin = entry.origin
  const connections = entry.connections
  const isSpc = connections.every((el) => el.item.isOrigin)

  const originPartInventory = partsInventory.find(
    (el) => el.partNumber === origin.partNumber,
  )
  const originAvailable = originPartInventory?.available || 0
  const originalListPrice =
    originPartInventory?.sellListPrice &&
    !isNaN(originPartInventory?.sellListPrice)
      ? new Intl.NumberFormat('default', {
          style: 'currency',
          currency: originPartInventory?.sellListPriceCurrency,
        }).format(originPartInventory?.sellListPrice)
      : 'N/A'
  const originItem = {
    partNumber: origin.partNumber,
    description: origin.description,
    available: originAvailable,
    attributes: [
      ['Shape', origin.shape],
      ['Body Material', origin.material],
      ...origin.connections.flatMap((con, idx) => {
        return [
          [`Connection ${idx + 1} Size`, con.size],
          [`Connection ${idx + 1} Type`, con.type],
        ]
      }),
      ['Quantity Available', originAvailable],
      ['List Price', originalListPrice],
    ],
  }
  const connectionItems = connections.map((con, idx) => {
    const partInventory = partsInventory.find(
      (el) => el.partNumber === con.item.partNumber,
    )
    const available = partInventory?.available || 0
    const listPrice =
      partInventory?.sellListPrice && !isNaN(partInventory?.sellListPrice)
        ? new Intl.NumberFormat('default', {
            style: 'currency',
            currency: partInventory?.sellListPriceCurrency,
          }).format(partInventory?.sellListPrice)
        : 'N/A'
    const item = {
      partNumber: con.item.partNumber,
      description: con.item.description,
      isOrigin: con.item.isOrigin,
      available,
      attributes: [
        ['Shape', con.item.shape],
        ['Body Material', con.item.material],
        ['End Size', con.size],
        ['End Type', con.type],
        ['Coupled Size', con.item.connectedBySize],
        ['Coupled Type', con.item.connectedByType],
        ['Quantity Available', available],
        ['List Price', listPrice],
      ],
    }
    if (!con.item.isOrigin && connections.length > 2) {
      item.attributes.unshift([
        'Connects To',
        `${origin.shape} Connection ${idx + 1}`,
      ])
    }
    return item
  })

  const displayComponents = isSpc
    ? [originItem]
    : connections.length > 2
    ? [originItem, ...connectionItems.filter((el) => !el.isOrigin)]
    : connectionItems

  return {
    ...entry,
    available: Math.min(
      ...displayComponents.map((el, idx, arr) =>
        Math.floor(
          el.available /
            arr.filter((el2) => el2.partNumber === el.partNumber).length,
        ),
      ),
    ),
    sellListPrice:
      originPartInventory && !isNaN(assembledPartsPriceSum)
        ? new Intl.NumberFormat('default', {
            style: 'currency',
            currency: originPartInventory.sellListPriceCurrency,
          }).format(assembledPartsPriceSum)
        : 'N/A',
    hasPipeFittings:
      entry.origin.isPipeFitting ||
      entry.connections.some((el) => el.item.isPipeFitting),
    displayVariant,
    displayComponents,
    isSpc,
  }
}

export {
  transformConfiguratorAttributeEntry,
  transformConfiguratorResultEntry,
  transformInventoryEntry,
  transformSscEntry,
  transformUserEntry
}

