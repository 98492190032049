<template>
  <Master>
    <div class="login">
      <div class="container">
        <div class="flex justify-between items-baseline mb-4">
          <PageHeading title="App Policies" />
        </div>
        <div>
          <div class="">
            <div class="link-a font-bold" @click="handleConsentDetailsClick">
              Click here
            </div>
            to see how Swagelok company uses, stores and shares your personal
            information. Read our
            <a
              class="text-brandGreen font-bold"
              href="https://www.swagelok.com/privacy-policy"
              target="_blank"
              rel="noopener"
              >Privacy Policy</a
            >
            for further details. The data and information contained in the app
            are believed to be accurate, but may contain inaccuracies and
            typographic errors. The app is provided on an "As Is" and "As
            Available" basis.
          </div>
          <div>
            <label class="inline-flex items-start mt-4">
              <input
                v-model="consentACheckbox"
                type="checkbox"
                class="form-checkbox mt-1"
              /><span class="ml-3 text-textColor"
                >I accept Swagelok's
                <a
                  class="link-a font-bold"
                  href="https://www.swagelok.com/privacy-policy"
                  target="_blank"
                  rel="noopener"
                  >Privacy Policy</a
                >
                and consent to Swagelok's use, storage, sharing, and transfer of
                my personal information. I understand that I can
                <div
                  class="link-a font-bold"
                  @click="handleConsentDetailsClick"
                >
                  opt-out
                </div>
                at any time.</span
              >
            </label>
            <label class="inline-flex items-start mt-4">
              <input
                v-model="consentBCheckbox"
                type="checkbox"
                class="form-checkbox mt-1"
              /><span class="ml-3 text-textColor"
                >I accept the Swagelok
                <a
                  class="link-a font-bold"
                  href="https://www.swagelok.com/Cookie-Notice-and-Disclosure"
                  target="_blank"
                  rel="noopener"
                  >Cookie Policy</a
                >.</span
              >
            </label>
          </div>
        </div>
        <form class="my-6" @submit.prevent="submitLogin" id="set-login">
          <button
            class="button-a"
            type="submit"
            :disabled="!consentACheckbox || !consentBCheckbox"
          >
            LOG IN
          </button>
        </form>
      </div>
    </div>
  </Master>
</template>

<script>
import { mapActions } from 'vuex'
import PageMixin from '@/mixins/PageMixin'
import GuestGuardMixin from '@/mixins/GuestGuardMixin'

export default {
  name: 'Login',
  mixins: [PageMixin, GuestGuardMixin],
  computed: {},
  methods: {
    ...mapActions('user', ['login', 'setUserData']),
    ...mapActions('router', ['setPath']),
    async submitLogin() {
      this.login('aad')
    },
    handleConsentDetailsClick() {
      this.setPath({ name: 'ConsentDetails' })
    },
  },
  data() {
    return {
      consentACheckbox: false,
      consentBCheckbox: false,
      showHeaderMenu: false,
    }
  },
}
</script>
