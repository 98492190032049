<template>
  <div class="">
    <hr class="" />
    <div v-if="filteredItems.length">
      <div class="flex justify-between py-2.5">
        <div class="font-bold text-headerColor w-[35%]">Part Number</div>
        <div class="font-bold text-headerColor w-[20%]">Location</div>
        <div class="font-bold text-headerColor w-[15%] text-right">Need</div>
        <div class="font-bold text-headerColor w-[15%] text-right">Avail</div>
        <div class="font-bold text-headerColor w-[15%] text-right">Sell</div>
      </div>
      <hr class="" />
      <transition-group name="transition-list" tag="div">
        <div
          class="transition-list-item"
          v-for="(item, index) in filteredItems"
          @click="$emit('itemClicked', item)"
          :key="item.partNumber"
        >
          <InventoryPoolingSellItem :item="item" />
          <hr class="" />
        </div>
      </transition-group>

      <div class="p-3 px-4 mt-6 bg-[#f0f0f0]">
        <span class="font-bold">Please confirm sell status below.</span>
        Parts will not be shown to buying SSCs until sell status is confirmed.
      </div>

      <button @click="confirmSellModal = true" class="button-a mt-8">
        CONFIRM SELL STATUS
      </button>
    </div>

    <div
      v-if="confirmSellModal"
      class="
        w-screen
        h-screen
        fixed
        top-0
        left-0
        z-40
        flex
        justify-center
        items-end
      "
    >
      <div
        class="
          w-full
          modal-shadow
          max-w-[500px]
          bg-white
          shadow-lg
          overflow-hidden
          rounded-md rounded-b-none
          p-4
          text-black
          flex flex-col
        "
      >
        <div v-if="confirmLoading">
          <div class="text-lg text-center my-3 text-headerColor font-bold">
            Confirming...
          </div>
        </div>
        <div v-if="confirmed">
          <div class="text-lg text-center my-3 text-headerColor font-bold">
            Choices confirmed
          </div>
        </div>
        <div v-if="!confirmLoading && !confirmed">
          <div class="text-lg text-center mb-3 text-headerColor font-bold">
            Are you ready to confirm your sell status for the parts above?
          </div>
          <div class="text-center mb-8 text-lg">
            After confirming, you cannot make changes to your sell status until
            the parts list refreshes tomorrow.
          </div>
          <button @click="() => confirm()" class="button-a mb-4">
            YES, CONFIRM MY CHOICES
          </button>
          <button @click="() => (confirmSellModal = false)" class="button-b">
            NO, I STILL NEED TO REVIEW
          </button>
        </div>
      </div>
    </div>

    <div v-if="!filteredItems.length">
      <div class="my-2 text-center italic">No new parts to review</div>
      <hr />
    </div>
  </div>
</template>
<script>
import InventoryPoolingSellItem from '@/components/inventory/InventoryPoolingSellItem.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'InventoryPoolingSellTable',
  methods: {
    ...mapActions('inventory', ['confirmSellingStatus']),
    ...mapActions('router', ['setPath']),
    handleHelpClick() {
      this.setPath({
        name: this.helpPage,
      })
    },
    async confirm() {
      this.confirmLoading = true
      try {
        await this.confirmSellingStatus({
          SellingBranch: this.activeBranch,
        })
        this.confirmLoading = false
        this.confirmed = true
        setTimeout(() => {
          this.confirmed = false
          this.confirmSellModal = false
        }, 1000)
      } catch (e) {
        this.setPath({
          name: 'ServiceUnavailable',
          params: {
            retryAction: () => {
              this.setPath({ name: 'InventoryPooling' })
            },
          },
        })
      }
    },
  },
  computed: {
    ...mapState('user', ['userData']),
    filteredItems() {
      return this.items.filter(
        (item) => item.swagelokBranch === this.activeBranch,
      )
    },
  },
  props: ['items', 'helpPage', 'activeBranch'],
  components: {
    InventoryPoolingSellItem,
  },
  data() {
    return {
      confirmSellModal: false,
      confirmLoading: false,
      confirmed: false,
    }
  },
}
</script>
