export default {
  app: {
    version: process.env.VUE_APP_VERSION,
    baseURL: process.env.VUE_APP_SWAGELOK_BASEURL,
    title: {
      defaultTitle: process.env.VUE_APP_TITLE,
      separator: ' | ',
    },
    queryTokens: {
      postLoginToken: 'post-login',
    },
  },
  user: {
    groups: {
      wsSsc: 'wsSsc',
      wsSwcon: 'wsSwcon',
      wsCorp: 'wsCorp',
      wsSscAdmin: 'wsSscAdmin',
      admin: 'admin',
      wsAdmin: 'wsAdmin',
      approver: 'approver',
      ordering: 'ordering',
    },
  },
  inventory: {
    maxPartsPerSsc:
      process.env.VUE_APP_INVENTORY_MAX_PARTS &&
      parseInt(process.env.VUE_APP_INVENTORY_MAX_PARTS),
  },
  services: {
    iva: {
      baseUrl: process.env.VUE_APP_SWAGELOK_API_BASEURL,
    },
    swagelokApi: {
      baseUrl: process.env.VUE_APP_SWAGELOK_API_BASEURL,
    },
    gtm: {
      isEnabled: process.env.VUE_GTM_ENABLED === 'true',
    },
  },
}
