var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"inv-search-inventory-results","data-inv-met-criteria-home":!!(
      _vm.partData &&
      _vm.partData.some(function (part) { return part.sscList.some(function (entry) { return entry.isHome; }); })
    ) && '',"data-inv-met-criteria-peer":!!(_vm.partData && _vm.partData.length) && '',"data-inv-truncated-results":!!(
      _vm.partData &&
      _vm.partData.some(function (part) { return part.sscList.some(function (entry) { return entry.totalPartsFound > _vm.maxPartsPerSsc; }); }
      )
    ) && '',"data-inv-searched-full-network":_vm.isSearchComplete && !_vm.isLoading && ''}},[(_vm.partData && !_vm.isLoading)?_c('div',[(
        _vm.partData.some(function (part) { return part.sscList.some(
            function (entry) { return entry.totalPartsFound > _vm.maxPartsPerSsc; }
          ); }
        )
      )?_c('div',{staticClass:"flex items-center -mt-3 mb-3"},[_c('SvgExclamationTriangle',{staticClass:"text-brandYellow w-[22px] h-[22px] fill-current"}),_vm._m(0)],1):_vm._e(),_c('hr',{staticClass:"-mx-2"}),_c('div',{},[_c('table2',{attrs:{"header":{
          cellA: 'Part Number',
          cellB: 'Nearest SSC',
        },"body":{
          cellA: {
            title: 'partNumber',
          },
          cellB: 'nearestSscId',
        },"items":_vm.partData,"itemHasCursor":true,"options":{ cellARegularFont: true, truncate: true },"itemKey":"partNumber"},on:{"itemClicked":function (item) { return _vm.showDetails(item); }}}),(_vm.showNoResult && _vm.isSearchComplete)?_c('div',{},[_c('div',{staticClass:"mt-4"},[_vm._v("No parts found with minimum inventory.")]),_c('hr',{staticClass:"-mx-2 mt-4"})]):_vm._e(),(!_vm.isSearchComplete && !_vm.isLoading)?_c('div',{staticClass:"link-a mt-4 hover:text-brandGreen-darker font-semibold",attrs:{"id":"inv-search-more-sscs"},on:{"click":function($event){return _vm.$emit('searchMoreClicked')}}},[_vm._v(" "+_vm._s('Search More Locations')+" "),_c('span',{staticClass:"icon-swg-arrow-right"})]):(_vm.isSearchComplete && !_vm.isLoading)?_c('div',{staticClass:"flex items-center mt-4"},[_c('SvgExclamationTriangle',{staticClass:"text-brandYellow w-[22px] h-[22px] fill-current"}),_c('div',{staticClass:"flex justify-center items-center ml-4"},[_vm._v(" The full network has been searched. ")])],1):_vm._e()],1)]):_vm._e(),(_vm.isLoading)?_c('loader'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col ml-4"},[_c('div',[_vm._v("Not all search results are being displayed.")]),_c('div',[_vm._v("Refine your search for better results.")])])}]

export { render, staticRenderFns }