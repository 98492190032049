import router from '@/router'

const initialState = {
  redirectAfterAuthTo: localStorage.getItem('redirectAfterAuthTo'),
  history: [],
  routeMap: {
    Home: {
      goBackState: null,
    },
    Login: {
      goBackState: null,
    },
    Unauthorized: {
      goBackState: null,
    },
    ServiceUnavailable: {
      goBackState: null,
    },
    InventoryHelpReserve: {
      goBackState: { name: 'Inventory' },
    },
    InventoryHelpMinQty: {
      goBackState: { name: 'Inventory' },
    },
    InventorySscDetails: {
      goBackState: { name: 'Inventory' },
    },
    InventoryPartDetails: {
      goBackState: { name: 'Inventory' },
    },
    InventoryPooling: {
      goBackState: { name: 'InventoryPooling' },
    },
    InventoryPoolingDetails: {
      goBackState: { name: 'InventoryPooling' },
    },
    InventoryPoolingDetailsBranch: {
      goBackState: { name: 'InventoryPooling' },
    },
    InventoryPoolingPartNumberHelp: {
      goBackState: { name: 'InventoryPooling' },
    },
    InventoryPoolingPartnerHelp: {
      goBackState: { name: 'InventoryPooling' },
    },
    RequestsSubmitted: {
      goBackState: { name: 'RequestsSubmitted' },
    },
    OrderRequestsNotifications: {
      goBackState: { name: 'Inventory' },
      redirectAfterAuthTo: true,
    },
    ApprovedRequest: {
      goBackState: { name: 'OrderRequestsNotifications' },
    },
    OrderRequestsHistoryFull: {
      goBackState: { name: 'order-requests-history' },
    },
    OrderRequestsHistory: {
      goBackState: { name: 'OrderRequestsNotifications' },
    },
    OrderRequestsHistoryFull: {
      goBackState: { name: 'OrderRequestsHistory' },
    },
    Inventory: {
      goBackState: { name: 'Home' },
    },
    Menu: {
      goBackState: { name: 'Home' },
    },
    Feedback: {
      goBackState: { name: 'Menu' },
    },
    SscSelector: {
      goBackState: { name: 'Menu' },
    },
    Settings: {
      goBackState: { name: 'Menu' },
    },
    SettingsInfo: {
      goBackState: { name: 'Settings' },
    },
    FavoriteList: {
      goBackState: { name: 'Settings' },
    },
    FavoriteAdd: {
      goBackState: { name: 'FavoriteList' },
    },
    Announcements: {
      goBackState: { name: 'Menu' },
    },
    AnnouncementsAdd: {
      goBackState: { name: 'Announcements' },
    },
    AnnouncementsEdit: {
      goBackState: {
        name: 'AnnouncementsDetails',
      },
    },
    AnnouncementsDetails: {
      goBackState: { name: 'Announcements' },
    },
    SscToSscSourcingSplashScreen: {
      goBackState: { name: 'Home' },
    },
    SscToSscSourcing: {
      goBackState: { name: 'Home' },
    },
    SscToSscSourcingHelp: {
      goBackState: { name: 'SscToSscSourcing' },
    },
    SscToSscSourcingDetails: {
      goBackState: { name: 'SscToSscSourcing' },
    },
    SscToSscSourcingAdmin: {
      goBackState: { name: 'SscToSscSourcing' },
    },
    SscToSscSourcingAdminUpdateCapabilities: {
      goBackState: { name: 'SscToSscSourcingAdmin' },
    },
    SscToSscSourcingAdminDetails: {
      goBackState: { name: 'SscToSscSourcingAdmin' },
    },
    SscToSscSourcingCapabilitiesHelp: {
      goBackState: { name: 'SscToSscSourcing' },
    },
    SscToSscSourcingRequestSubmitted: {
      goBackState: { name: 'SscToSscSourcing' },
    },
    SscToSscSourcingRequestMultiple: {
      goBackState: { name: 'SscToSscSourcing' },
    },
    SscToSscSourcingRequestMultipleSubmitted: {
      goBackState: { name: 'SscToSscSourcing' },
    },
    ConsentDetails: {
      goBackState: { name: 'Login' },
    },
    About: {
      goBackState: { name: 'Menu' },
    },
    Configurator: {
      goBackState: { name: 'Home' },
    },
    ConfigurationDetails: {
      goBackState: { name: 'Configurator' },
    },
    ConfiguratorHelp: {
      goBackState: { name: 'Configurator' },
    },
    AdminSSCContactInfo: {
      goBackState: { name: 'Inventory' },
    },
    InventoryPoolingV2: {
      goBackState: { name: 'Home' },
    },
    InventoryPoolingSellDetail: {
      goBackState: { name: 'InventoryPooling' },
    },
    InventoryPoolingBuyDetail: {
      goBackState: { name: 'InventoryPooling' },
    },
    RequestSummary: {
      goBackState: { name: 'InventoryPooling' },
    },
  },
}

const mutations = {
  SET_GO_BACK(state, pushOptions) {
    state.goBackState = pushOptions
  },
  SET_HISTORY(state, pushOptions) {
    state.history.push(pushOptions)
  },
  SET_ROUTE_MAP(state, mapSlice) {
    state.routeMap = { ...state.routeMap, ...mapSlice }
  },
  SET_REDIRECT_AFTER_AUTH(state, to) {
    state.redirectAuthAuth = to
  },
}

const actions = {
  async goPageBack({ state, commit }, options) {
    commit('layout/SET_PAGE_TRANSITION', options?.transition || 'fade', {
      root: true,
    })
    commit('SET_HISTORY', state.goBackState)
    return router.push(state.goBackState)
  },
  async setPath({ commit, dispatch, state }, pushOptions) {
    commit('layout/SET_PAGE_TRANSITION', pushOptions?.transition || 'fade', {
      root: true,
    })
    commit('SET_HISTORY', pushOptions)
    if (pushOptions.routeMap) {
      dispatch('setRouteMap', { [pushOptions.name]: pushOptions.routeMap })
    }

    if (
      pushOptions.currentRouteName &&
      state.routeMap[pushOptions.currentRouteName].redirectAfterAuthTo
    ) {
      localStorage.setItem('redirectAfterAuthTo', pushOptions.currentRouteName)
      commit('SET_REDIRECT_AFTER_AUTH', pushOptions.currentRouteName)
    }
    return router.push(pushOptions)
  },
  setRouteMap({ commit }, routeMap) {
    commit('SET_ROUTE_MAP', routeMap)
  },
}

const getters = {
  hasHistory: (state) => !!state.history.length,
  getRouteMap: (state) => (page) => state.routeMap[page],
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
  namespaced: true,
}
