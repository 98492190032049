<template>
  <Master>
    <loader v-if="$store.state.inventory.inventoryStockLoading" />

    <div
      v-if="!$store.state.inventory.inventoryStockLoading && item"
      class="container"
    >
      <OrderHeader>
        <ArrowRight class="w-5" />
        Incoming Request
      </OrderHeader>

      <Order :order="item" :whoTo="item.RequestingSSC">
        <div v-if="item.Status === 'Requested'">
          <div v-if="item.RequestMessage" class="mb-4">
            <div class="text-xl font-bold text-headerColor mb-1">Message</div>
            <div
              class="text-sm text-headerColor whitespace-pre-line"
              v-html="item.RequestMessage"
            ></div>
          </div>

          <div class="text-xl font-bold text-headerColor mb-1">Local Stock</div>

          <hr />

          <table3
            :header="{
              cellA: 'Branch',
              cellB: 'Available',
              cellC: 'Reserved',
            }"
            :body="{
              cellA: { title: 'branch' },
              cellB: 'available',
              cellC: 'reserved',
            }"
            :options="{
              cellARegularFont: true,
              textSize: 'text-[16px]',
              // cellCSize: 'w-[30%]',
            }"
            :items="localStock"
            :itemHasCursor="false"
            itemKey="swagelokBranch"
          />

          <div class="text-sm font-bold text-headerColor mt-2 mb-2">
            Optional
          </div>
          <div class="text-sm text-headerColor mb-4">
            Add additional information for your request below.
          </div>

          <textarea
            class="form-textarea h-[100px] rounded-none resize-none mb-3"
            v-model="message"
            type="text"
            placeholder="I'm approving/denying because..."
          />

          <div class="text-sm text-headerColor mb-4">
            How many are you approving?
          </div>
          <div class="mb-5">
            <input
              type="number"
              class="form-input w-[80px]"
              v-model="approvedQuantity"
              id="inv-approved-quantity"
            />
          </div>

          <div class="flex gap-2 mb-4">
            <button
              @click="
                () => {
                  update('Approved')
                }
              "
              class="button-a"
              id="inv-approve-request"
            >
              APPROVE REQUEST
            </button>
            <button
              @click="
                () => {
                  update('Denied')
                }
              "
              class="button-b"
              id="inv-deny-request"
            >
              DENY REQUEST
            </button>
          </div>
        </div>
      </Order>

      <div v-if="item.Status === 'Requested'">
        <div class="text-xl font-bold text-headerColor mb-1">
          Corporate Stock
        </div>

        <hr />

        <table2
          :header="{ cellA: 'Branch', cellB: 'Available' }"
          :body="{
            cellA: { title: 'location' },
            cellB: 'available',
          }"
          :options="{ cellARegularFont: true, truncate: true }"
          :items="stock ? stock.corporateStock : []"
          :itemHasCursor="false"
          itemKey="location"
        />

        <div class="text-xl font-bold text-headerColor mt-6 mb-1">
          Future Corporate Stock
        </div>

        <div v-if="stock && stock.futureCorporateStock.length === 0">
          <hr />
          <div class="mt-4 mb-4">No future stock scheduled at this time</div>
          <hr />
        </div>

        <table3
          v-if="stock && stock.futureCorporateStock.length"
          :header="{ cellA: 'Branch', cellB: 'Quantity', cellC: 'Date' }"
          :body="{
            cellA: { title: 'branchId' },
            cellB: 'quantity',
            cellC: 'date',
          }"
          :options="{
            textSize: 'text-[16px]',
            cellCSize: 'w-[30%]',
          }"
          :items="stock ? stock.futureCorporateStock : []"
          :itemHasCursor="false"
          itemKey="branchId"
        />
      </div>
    </div>
  </Master>
</template>

<script>
import ArrowRight from '@/assets/icons/arrow-right.svg'
import { Order, OrderHeader } from '@/components/inventory/Order'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import InventoryMixin from '@/mixins/InventoryMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import PageMixin from '@/mixins/PageMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import settings from '@/settings'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { mapActions, mapState } from 'vuex'
dayjs.extend(utc)

export default {
  name: 'IncomingRequest',
  mixins: [
    PageMixin,
    LoaderMixin,
    SscSelectorGuardMixin,
    InventoryMixin,
    AuthorizedGuardMixin,
  ],
  components: {
    Order,
    OrderHeader,
    ArrowRight,
  },
  computed: {
    ...mapState('settings', ['appSettings']),
    stock() {
      return this.$store.state.inventory.corporateStock[this.partNumber]
    },
    localStock() {
      return this.$store.state.inventory.inventoryStock[
        this.$attrs.request.PartNumber
      ]?.inventory?.entries[0]?.inventory.map((i) => ({
        ...i,
        branch: `${i.swagelokBranch} - ${i.swagelokBranchName}`,
      }))
    },
  },
  methods: {
    ...mapActions('settings', ['sendEmail']),
    async update(Status) {
      this.item.Status = Status

      if (Status === 'Approved') {
        this.item.ApprovedQuantity = this.approvedQuantity
        this.item.ApprovedDateTime = dayjs().utc().format()
      }

      this.sendNotificationEmail(Status)

      await this.$store.dispatch('updateOrder', {
        type: this.$attrs.type,
        id: this.$attrs.id,
        order: {
          ...this.item,
        },
      })
    },
    async sendNotificationEmail(status) {
      const { uid: loggedUserEmail, fullName, ssc } = this.userData

      const html = `
            <div>
              ${
                status === 'Approved'
                  ? this.getApprovedEmail()
                  : this.getDeniedEmail()
              }

              <p style="margin-bottom: 4px;">
                <b>Replies to this email message will go directly to</b> ${loggedUserEmail.toLocaleLowerCase()}
              </p>
              <p style="margin-bottom: 0;">
                <a style="color: dodgerblue;text-decoration: underline;" href="${
                  settings.app.baseURL
                }/order-requests">View Your Order Requests</a>
              </p>
            </div>
          `

      const fromName = this.appSettings.EmailFromLoggedInUser
        ? fullName
        : 'Swagelok Webmaster'
      const fromEmail = this.appSettings.EmailFromLoggedInUser
        ? loggedUserEmail
        : 'webmaster@swagelok.com'

      const subject = `Order request from ${
        this.item.SellingSSC
      } ${status.toLocaleLowerCase()}`

      await this.sendEmail({
        fromName,
        fromEmail,
        to: this.item.RequestingUserEmail,
        replyTo: loggedUserEmail,
        cc: loggedUserEmail,
        subject: `${
          process.env.VUE_APP_ENV === 'prod' ? '' : 'TEST: '
        }${subject}`,
        text: this.requestMessage,
        html,
      })
    },
    getApprovedEmail() {
      return `
      <p>Your SSC order request has been approved by ${this.userData.ssc.name} (${this.item.SellingSSC}).</p>

      <p style="margin-bottom: 8px;">
        <strong>Request Information</strong>
      </p>

      <p>Part Number: ${this.partNumber}</p>
      <p>Requested Qty: ${this.item.RequestedQuantity}</p>
      <p style="margin-bottom: 8px;">Approved Qty: ${this.approvedQuantity}</p>

      <p style="margin-bottom: 8px;">Reason for Approval: ${this.message}</p>`
    },
    getDeniedEmail() {
      return `
      <p>Your SSC order request has been denied by ${this.userData.ssc.name} (${this.item.SellingSSC}).</p>

      <p style="margin-bottom: 8px;">
        <strong>Request Information</strong>
      </p>

      <p>Part Number: ${this.partNumber}</p>
      <p>Requested Qty: ${this.item.RequestedQuantity}</p>


      <p style="margin-bottom: 8px;">Reason for Denial: ${this.message}</p>`
    },
  },
  async created() {
    if (!this.$attrs.request || !this.$attrs.type) {
      this.$store.dispatch('router/setPath', {
        name: 'OrderRequestsNotifications',
      })

      return
    }

    this.item = this.$attrs.request
    this.partNumber = this.$attrs.request.PartNumber

    this.$store.dispatch(
      'inventory/getCorporateStock',
      this.$attrs.request.PartNumber,
    )

    this.$store.dispatch('inventory/getInventoryStock', {
      partNumber: this.$attrs.request.PartNumber,
      sscId: this.$attrs.request.SellingSSC,
      requestingSSCWhoto: this.$attrs.request.RequestingSSC,
      maxParts: parseInt(this.$attrs.request.RequestedQuantity),
    })

    this.approvedQuantity = parseInt(this.$attrs.request.RequestedQuantity)
  },
  data() {
    return {
      approvedQuantity: 1,
      message: '',
      partNumber: null,
      item: null,
    }
  },
}
</script>
