<template>
  <Master>
    <div class="configurator-help">
      <div ref="container" class="container">
        <PageHeading title="Set Multiple Capabilities" />
        <ValidationObserver
          tag="form"
          ref="observer"
          class="relative mt-4"
          @submit.prevent="submitForm"
          v-slot="{ failed }"
          id="set-submit-multiple-capabilities"
        >
          <div class="toggle-area flex items-center mt-5">
            <toggle-button
              :labels="false"
              :width="58"
              :height="32"
              v-model="formData.contactStatus"
              :color="{ checked: '#66b645', unchecked: '#D1D5DB' }"
              name="inv-include-below-minimum"
            />
            <PageHeading title="Contact" class="ml-3" />
          </div>
          <div v-if="formData.contactStatus" class="w-full relative mt-4">
            <input
              class="form-input placeholder-secondaryGrayer"
              type="text"
              name="email"
              ref="email"
              id="email"
              v-model="formData.contact"
            />
            <small v-if="contactError" class="text-secondaryRed">
              Please, add a valid email address
            </small>
          </div>
          <div class="toggle-area flex items-center justify-between mt-6">
            <div class="toggle-area flex items-center">
              <toggle-button
                :labels="false"
                :width="58"
                :height="32"
                v-model="formData.capacityStatus"
                :color="{ checked: '#66b645', unchecked: '#D1D5DB' }"
                name="inv-include-below-minimum"
              />
              <PageHeading title="Capacity" class="ml-3" />
            </div>

            <button
              v-if="formData.capacityStatus"
              type="button"
              @click="
                () => {
                  setPath({
                    name: 'SscToSscSourcingHelp',
                    routeMap: {
                      goBackState: {
                        name: 'SscToSscSourcingAdminUpdateCapabilities',
                      },
                    },
                  })
                }
              "
              class="w-[20px] mr-[61px]"
            >
              <QuestionCircleSolid class="text-brandLightBlue" />
            </button>
          </div>
          <div v-if="formData.capacityStatus">
            <transition name="transition-fade">
              <div class="flex justify-between mt-4 w-5/6">
                <RadioBox
                  label="Willing to accept inquiries"
                  value="Has Capacity"
                  inputName="capacity-operator"
                  v-model="formData.capacity"
                />
                <TrafficLight color="green" />
              </div>
            </transition>
            <transition name="transition-fade">
              <div class="flex justify-between mt-4 w-5/6">
                <RadioBox
                  label="Unable to accept inquries at this time"
                  value="Does Not Have Capacity"
                  inputName="capacity-operator"
                  v-model="formData.capacity"
                />
                <TrafficLight color="red" />
              </div>
            </transition>
            <transition name="transition-fade">
              <div class="flex justify-between mt-4 w-5/6">
                <RadioBox
                  label="Capability not offered"
                  value="Capability not offered"
                  inputName="capacity-operator"
                  v-model="formData.capacity"
                />
                <i
                  class="
                    block
                    icon-swg-circle-close
                    text-headerColor
                    w-[16px]
                    h-[16px]
                  "
                ></i>
              </div>
            </transition>
          </div>
          <PageHeading title="Capabilities" class="mt-6" />
          <div
            class="custom-solution-capabilities mt-4"
            v-for="functions in capabilityData"
            :key="functions.FunctionCode"
          >
            <p class="font-bold">{{ functions.FunctionName }}</p>
            <label
              class="block items-start mt-2"
              v-for="capability in functions.Capabilities"
              :key="capability.CapCode"
            >
              <input
                type="checkbox"
                :id="capability.CapCode"
                :value="{
                  functionName: functions.FunctionName,
                  functionCode: functions.FunctionCode,
                  capCode: capability.CapCode,
                  capName: capability.CapName,
                }"
                class="form-checkbox mt-1 mr-3"
                v-model="formData.capabilities"
              />
              <span>{{ capability.CapName }}</span>
            </label>
          </div>
          <button
            class="button-a mt-8"
            type="submit"
            :disabled="
              isLoading ||
              failed ||
              (!formData.contactStatus && !formData.capacityStatus)
            "
          >
            SUBMIT
          </button>
        </ValidationObserver>
      </div>
    </div>
  </Master>
</template>

<script>
import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import SscSelectorGuardMixin from '@/mixins/SscSelectorGuardMixin'
import QuestionCircleSolid from '@/assets/icons/question-circle-solid.svg'
import AuthorizedGuardMixin from '@/mixins/AuthorizedGuardMixin'
import AdminSSCGuardMixin from '@/mixins/AdminSSCGuardMixin'
import RadioBox from '@/components/common/RadioBox'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SscToSscSourcingAdminUpdateCapabilities',
  mixins: [
    PageMixin,
    LoaderMixin,
    SscSelectorGuardMixin,
    AuthorizedGuardMixin,
    AdminSSCGuardMixin,
  ],
  components: { RadioBox, QuestionCircleSolid },
  computed: {
    ...mapState('sourcing', ['SSCSourcingData', 'capabilityData']),
  },
  methods: {
    ...mapActions('sourcing', ['updateSSCSourcingData']),
    isValidEmail(email) {
      return !!email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      )
    },
    submitForm() {
      if (
        this.formData.contactStatus &&
        !this.isValidEmail(this.formData.contact)
      ) {
        this.$refs.container.scrollIntoView({
          block: 'start',
          scrollBehavior: 'smooth',
        })

        this.contactError = true
        return
      }

      this.contactError = false

      const defaultCap = 'Capability not offered'

      const { contact, capacity, capabilities, contactStatus, capacityStatus } =
        this.formData

      const selectedCapCodes = capabilities.map((el) => el.capCode)

      const updatedSSCSourcingData = capabilities
        .map((capability) => {
          const { capCode, functionCode, capCode: updatedCapCode } = capability

          const idx = this.SSCSourcingData.findIndex(
            (el) => el.Capability === capCode,
          )

          if (idx > -1) {
            const sameContact = this.SSCSourcingData.find(
              (el) => el.Capability === capCode,
            )?.Contact

            const sameCapacity = this.SSCSourcingData.find(
              (el) => el.Capability === capCode,
            )?.Capacity

            return {
              Function: functionCode,
              Capability: updatedCapCode,
              Capacity: capacityStatus ? capacity : sameCapacity,
              Contact: contactStatus ? contact : sameContact,
              DateUpdated: new Date().toISOString(),
            }
          }
        })
        .filter((el) => el)

      const notUpdatedSSCSourcingData = this.SSCSourcingData.filter(
        (el) => !selectedCapCodes.includes(el.Capability),
      )

      const newSSCSourcingData = capabilities
        .filter(
          (el) =>
            !this.SSCSourcingData.some((ele) => ele.Capability === el.capCode),
        )
        .map((el) => {
          const { functionCode, capCode: newCapCode } = el

          return {
            Function: functionCode,
            Capability: newCapCode,
            Capacity: capacityStatus ? capacity : defaultCap,
            Contact: contactStatus ? contact : this.userData?.uid,
            DateUpdated: new Date().toISOString(),
          }
        })

      const concatenatedSSCSourcingData = [
        ...notUpdatedSSCSourcingData,
        ...updatedSSCSourcingData,
        ...newSSCSourcingData,
      ]

      this.updateSSCSourcingData(concatenatedSSCSourcingData)
        .then((resp) => {
          const { status } = resp

          if (status === 200) {
            this.setPath({
              name: 'SscToSscSourcingAdmin',
            })
          }
        })
        .catch((err) => console.log('err', err))
    },
  },
  created() {
    if (!this.SSCSourcingData && !this.capabilityData) {
      this.setPath({
        name: 'SscToSscSourcing',
      })
    }
  },
  data() {
    return {
      showHeaderMenu: true,
      checked: false,
      formData: {
        contact: '',
        capacity: null,
        capabilities: [],
        contactStatus: false,
        capacityStatus: false,
      },
      contactError: false,
    }
  },
}
</script>
