import SwagelokApiService from '@/services/swagelok-api'

const initialState = {
  capabilityData: null,
  SSCSourcingData: null,
  sourcingData: null,
  capabilitiesInfo: null,
  currentSelectedFunction: null,
  currentSelectedCapabilities: null,
  sscSourcingResults: [],
}
const mutations = {
  SET_SSC_SOURCING_DATA(state, data) {
    state.SSCSourcingData = data
  },

  SET_SSC_SOURCING_CAPABILITIES_INFO_DATA(state, data) {
    state.capabilitiesInfo = data
  },

  SET_CURRENT_SELECTED_FUNCTION(state, data) {
    state.currentSelectedFunction = data
  },

  SET_CURRENT_SELECTED_CAPABILITIES(state, data) {
    state.currentSelectedCapabilities = data
  },

  SET_SSC_SOURCING_RESULTS(state, data) {
    state.sscSourcingResults = data
  },

  SET_CAPABILITIES_DATA(state, data) {
    state.capabilityData = data
  },

  SET_CAPABILITY_DATA(state, data) {
    state.capabilitiesInfo = data
  },

  SET_SOURCING_DATA(state, data) {
    state.sourcingData = data
  },
}

const actions = {
  async clearSSCCapacities({ commit }) {
    commit('SET_SSC_SOURCING_DATA', null)
  },

  async searchSSCs({ commit }) {
    const SSCSourcing = await SwagelokApiService.SSCSourcing.searchBySSC()

    commit('SET_SSC_SOURCING_DATA', SSCSourcing)
  },

  async updateSSCSourcingData({ commit, state }, data) {
    const updatedData = await SwagelokApiService.SSCSourcing.update(data)
    const { SSCSourcing } = updatedData.resource

    commit('SET_SSC_SOURCING_DATA', SSCSourcing)
    return updatedData
  },

  async getCapabilitiesInfo({ commit }) {
    const capabilitiesInfo =
      await SwagelokApiService.SSCSourcing.capabilitiesInfo()

    commit('SET_SSC_SOURCING_CAPABILITIES_INFO_DATA', capabilitiesInfo)
  },

  setCurrentSelectedFunction({ commit }, functionCode) {
    commit('SET_CURRENT_SELECTED_FUNCTION', functionCode)
  },

  setCurrentSelectedCapabilities({ commit }, capabilities) {
    commit('SET_CURRENT_SELECTED_CAPABILITIES', capabilities)
  },

  setSscSourcingResults({ commit }, sscSourcingResults) {
    commit('SET_SSC_SOURCING_RESULTS', sscSourcingResults)
  },

  clearSscSourcingResults({ commit }) {
    commit('SET_SSC_SOURCING_RESULTS', [])
  },

  async setCapabilities({ commit }) {
    const capabilities = await SwagelokApiService.SSCSourcing.capabilities()
    commit('SET_CAPABILITIES_DATA', capabilities)
  },

  async updateCapability(
    { commit },
    { idx, Contact, Capacity, Note, FunctionCode, CapCode },
  ) {
    const updatedCapability = await SwagelokApiService.SSCSourcing.send({
      idx,
      Contact,
      Capacity,
      Note,
      FunctionCode,
      CapCode,
    })

    const { resource } = updatedCapability

    commit('SET_SSC_SOURCING_DATA', resource.SSCSourcing)

    return updatedCapability
  },

  async setSourcingData({ commit }, { sourcingCapability, sourcingfunction }) {
    if (!sourcingCapability && !sourcingfunction) {
      commit('SET_SOURCING_DATA', null)
      return
    }

    const sourcingData = await SwagelokApiService.SSCSourcing.getResults({
      sourcingCapability,
      sourcingfunction,
    })

    commit('SET_SOURCING_DATA', sourcingData)
  },
}

const getters = {
  getSourcingData: (state) => {
    return state.sourcingData
  },
  currentSelectedFunction: (state) => {
    return state.currentSelectedFunction
  },
  currentSelectedCapabilities: (state) => {
    return state.currentSelectedCapabilities
  },
  sscSourcingResults: (state) => {
    return state.sscSourcingResults
  },
}

export default {
  state: initialState,
  mutations,
  getters,
  actions,
  namespaced: true,
}
